import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import EmployeeProgram from "./employee-program";
import {useDispatch, useSelector} from "react-redux";
import {
    setWorkingProgram,
    setWorkingProgramNeedsSave,
    setWorkingProgramOnEmployee
} from "../../../../../_reducers/DataPanel/Payroll/payrollSlice";
import {toast} from "react-toastify";
import {addTab, removeTab, updateActiveMainTab} from "../../../../../_reducers/TabsSlice";
import {applyMetaballomenaApologistika, applyRounding} from "./constants";
import {classicStyleBelowNavbar} from "../../Statics";

const EmployeeWorkingProgram = () => {
    const dispatch = useDispatch();
    const workingProgram = useSelector((state) => state.PAYROLL.workingProgram);
    const workingProgramExtraData = useSelector((state) => state.PAYROLL.workingProgramExtraData);
    const workingProgramOnEmployee = useSelector((state) => state.PAYROLL.workingProgramOnEmployee);
    const legacyWorkingProgram = useSelector((state) => state.PAYROLL.legacyWorkingProgram);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);

    const displayHourFrom = 0;
    const displayHourTo = 24;
    const dragCoefficient = 0.025;

    const [legacyEvents, setLegacyEvents] = useState([]); // The events before any editing
    const [events, setEvents] = useState([]);

    useEffect(() => {
        if (workingProgramOnEmployee) {
            const cloneEvents = structuredClone(workingProgramOnEmployee.dailyCalendar);
            let cnt = 0;
            for (let ev of cloneEvents) {
                ev.id = cnt;
                cnt++;
            }
            setEvents(cloneEvents);

            if (legacyWorkingProgram) {
                const findMatch = legacyWorkingProgram.employees.find((el) => el.employeeId === workingProgramOnEmployee.employeeId);
                if (findMatch) {
                    const cEvents = structuredClone(findMatch.dailyCalendar);
                    setLegacyEvents(cEvents);
                }
            }
        }
    }, [workingProgramOnEmployee])

    // resizeType = bottom or top
    const onEventResize = (eventId, diff, resizeType) => {
        let cancelEvent = false;
        const cloneEvents = structuredClone(events);
        const findEvent = cloneEvents.find((el) => el.id === eventId);

        const inMinutes = diff * dragCoefficient;
        if (findEvent) {
            if (resizeType === "bottom") {
                findEvent.hourTo = Math.min(findEvent.hourTo + inMinutes, displayHourTo);
                const hook = hookOverworkOvertime(findEvent);
                for (let key in hook) findEvent[key] = hook[key];

                const findOverlap = cloneEvents.find((el) => el.day === findEvent.day && el.id !== findEvent.id && el.hourFrom <= findEvent.hourTo && findEvent.hourTo <= el.hourTo);
                if (findOverlap) cancelEvent = true;
            } else if (resizeType === "top") {
                findEvent.hourFrom = Math.max(findEvent.hourFrom + inMinutes, displayHourFrom);
                const hook = hookOverworkOvertime(findEvent);
                for (let key in hook) findEvent[key] = hook[key];

                const findOverlap = cloneEvents.find((el) => el.day === findEvent.day && el.id !== findEvent.id && el.hourTo <= findEvent.hourFrom && findEvent.hourFrom <= el.hourFrom);
                if (findOverlap) cancelEvent = true;
            }
        }
        if (!cancelEvent) setEvents(cloneEvents);
    }

    const onEventDrag = (eventId, diff) => {
        const cloneEvents = structuredClone(events);
        let findEvent = cloneEvents.find((el) => el.id === eventId);

        const inMinutes = diff * dragCoefficient;
        if (findEvent) {
            if (findEvent.hourTo + inMinutes <= displayHourTo && findEvent.hourFrom + inMinutes >= displayHourFrom) {
                findEvent.hourFrom = Math.max(findEvent.hourFrom + inMinutes, displayHourFrom);
                findEvent.hourTo = Math.min(findEvent.hourTo + inMinutes, displayHourTo);
            }
        }
        setEvents(cloneEvents);
    }

    const hookOverworkOvertime = (newEvent) => {
        if (newEvent.workType === "normal") {
            newEvent.overwork = 0;
            newEvent.overtime = 0;
            // Scan for overwork and overtime
            let workingDaysPerWeek = workingProgramExtraData.workingDaysPerWeek[workingProgramOnEmployee.employeeId];
            if (workingDaysPerWeek === "6") {
                let diff = +Number(newEvent.hourTo - newEvent.hourFrom).toFixed(2);
                if (diff > 6.4) {
                    diff = +Number(diff - 6.4).toFixed(2);
                    const overwork = Math.min(diff, 1.6); // Max 1.6 hour overwork
                    newEvent.overwork = overwork;
                    if (diff > 1.6) { // Overtime
                        newEvent.overtime = +Number(diff - overwork).toFixed(2);
                    }
                }
            } else {
                let diff = +Number(newEvent.hourTo - newEvent.hourFrom).toFixed(2);
                if (diff > 8) {
                    diff = +Number(diff - 8).toFixed(2);
                    const overwork = Math.min(diff, 1); // Max 1 hour overwork
                    newEvent.overwork = overwork;
                    if (diff > 1) { // Overtime
                        newEvent.overtime = +Number(diff - overwork).toFixed(2);
                    }
                }
            }
        }
        return newEvent;
    }

    const canHappen = (ev, type) => {
        if (type === "onEventAdd") {
            for (let event of events.filter((el) => el.day === ev.day)) {
                if (!((event.hourFrom >= ev.hourFrom && event.hourFrom >= ev.hourTo) || (event.hourTo <= ev.hourFrom && event.hourTo <= ev.hourTo))) {
                    toast.error("Το ωράριο δεν είναι αποθηκεύτηκε διότι συμπίπτει με ήδη υπάρχον.", {autoClose: 6000});
                    return false;
                }
            }
        } else if (type === "onEventsAdd" && ev?.length > 0) {
            for (let event of events.filter((el) => el.day === ev[0].day)) {
                for (let e of ev) {
                    if (!((event.hourFrom >= e.hourFrom && event.hourFrom >= e.hourTo) || (event.hourTo <= e.hourFrom && event.hourTo <= e.hourTo))) {
                        toast.error("Το ωράριο δεν είναι αποθηκεύτηκε διότι συμπίπτει με ήδη υπάρχον.", {autoClose: 6000});
                        return false;
                    }
                }
            }
        } else if (type === "onEventEdit") {
            for (let event of events.filter((el) => el.day === ev.day && el.id !== ev.id)) {
                if (!((event.hourFrom >= ev.hourFrom && event.hourFrom >= ev.hourTo) || (event.hourTo <= ev.hourFrom && event.hourTo <= ev.hourTo))) {
                    toast.error("Το ωράριο δεν είναι αποθηκεύτηκε διότι συμπίπτει με ήδη υπάρχον.", {autoClose: 6000});
                    return false;
                }
            }
        }
        return true;
    }

    const onEventAdd = (newEvent) => {
        const cloneEvents = structuredClone(events);
        newEvent = hookOverworkOvertime(newEvent);
        if (canHappen(newEvent, "onEventAdd")) {
            cloneEvents.push(newEvent);
            setEvents(cloneEvents);
        }
    }

    const onEventsAdd = (newEvents) => {
        let cloneEvents = structuredClone(events);
        if (canHappen(newEvents, "onEventsAdd")) {
            cloneEvents = [...cloneEvents, ...newEvents];
            setEvents(cloneEvents);
        }
    }

    const onEventDelete = (id) => {
        let cloneEvents = structuredClone(events);
        const findMatching = cloneEvents.find((el) => el.id === id);
        if (findMatching) {
            cloneEvents = cloneEvents.filter((el) => el.id !== id);
            setEvents(cloneEvents);
        }
    }

    const onEventEdit = (ev) => {
        let cloneEvents = structuredClone(events);
        ev = hookOverworkOvertime(ev);
        if (canHappen(ev, "onEventEdit")) {
            let idx = cloneEvents.findIndex((el) => el.id === ev.id);
            cloneEvents[idx] = ev;
            setEvents(cloneEvents);
        }
    }

    const handleCloseEmployeeProgram = () => {
        dispatch(removeTab("working-program-employee"));
        const findWorkingProgram = TABS_DATA.findIndex((item) => "working-program" === item);
        if (findWorkingProgram === -1) {
            dispatch(addTab("working-program"));
            dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
            dispatch(updateActiveMainTab(findWorkingProgram));
        }
    }

    const onSave = () => {
        const cloneWorkingProgram = structuredClone(workingProgram);
        const findEmployee = cloneWorkingProgram?.employees?.find((el) => el.employeeId === workingProgramOnEmployee.employeeId);
        if (findEmployee) {
            let cloneEvents = structuredClone(events);
            cloneEvents = applyMetaballomenaApologistika(workingProgram.dateFrom, workingProgram.dateTo, legacyEvents, cloneEvents);
            findEmployee.dailyCalendar = cloneEvents.sort((a, b) => a.day < b.day ? -1 : 1);
            dispatch(setWorkingProgram(cloneWorkingProgram));
            dispatch(setWorkingProgramOnEmployee(null));
            dispatch(setWorkingProgramNeedsSave(true));
            handleCloseEmployeeProgram();
        } else {
            toast.error("Σφάλμα κατά την αποθήκευση.");
        }
    }

    const onNotSave = () => {
        dispatch(setWorkingProgramOnEmployee(null));
        handleCloseEmployeeProgram();
    }

    const onApplyRounding = (eventId) => {
        let cloneEvents = structuredClone(events);
        let findMatching = cloneEvents.find((el) => el.id === eventId);
        if (findMatching) {
            findMatching.hourTo = applyRounding(findMatching.hourTo, 15);
            findMatching.hourFrom = applyRounding(findMatching.hourFrom, 15);
            const hook = hookOverworkOvertime(findMatching);
            for (let key in hook) findMatching[key] = hook[key];

            setEvents(cloneEvents);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={6}>
                    <Button size={"sm"} onClick={() => onNotSave()}>{"<<"} Πίσω στο πρόγραμμα (χωρίς αποθήκευση)</Button><br/>
                </Col>
                <Col md={6} className={"d-flex justify-content-end"}>
                    <Button size={"sm"} onClick={() => onSave()}>Αποθήκευση & πίσω στο πρόγραμμα</Button>
                </Col>
            </Row>
            {workingProgramOnEmployee && (
                <Row>
                    <Col md={12}>
                        <EmployeeProgram
                            dateFrom={workingProgram.dateFrom}
                            dateTo={workingProgram.dateTo}
                            events={events}
                            displayHoursFrom={displayHourFrom}
                            displayHoursTo={displayHourTo}

                            onEventResize={onEventResize}
                            onEventDrag={onEventDrag}
                            onEventAdd={onEventAdd}
                            onEventsAdd={onEventsAdd}
                            onEventEdit={onEventEdit}
                            onEventDelete={onEventDelete}
                            onApplyRounding={onApplyRounding}
                        />
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default EmployeeWorkingProgram
