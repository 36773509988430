import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import axios from "axios";
import {Badge, Col, Row} from "react-bootstrap";
import DateBox from "../../../primer/DateBox";
import {getISOStringDateToDate, isEmpty} from "../../../../_helpers/commonFunctions";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import {setMasterLogTableRequestData, setMasterLogTableResponseData} from "../../../../_reducers/settingsSlice";
import Dropdown from "../../../primer/Dropdown";
import {toast} from "react-toastify";
import {classicStyleBelowNavbar} from "../Statics";
import {getLogTypeOptions} from "../../../../_data/logTypes";
import Input from "../../../common/Input";

const MasterLogSearch = () => {
    const [t, i18n] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.SETTINGS.masterLogTableRequestData);
    const responseData = useSelector((state) => state.SETTINGS.masterLogTableResponseData);
    const [pageChanged, setPageChanged] = useState(false);

    const [usersList, setUsersList] = useState([]);
    const [loading, setLoading] = useState(false);

    const userDropdownColumns = [
        {
            label: "Username",
            name: "userName",
        },
        {
            label: "Όνομα",
            name: "firstName",
        },
        {
            label: "Επώνυμο",
            name: "lastName",
        },
    ];
    const tableColumns = [
        {
            name: "Ημ/νία",
            field: "created_at",
            width: "5%",
        },
        {
            name: "Ώρα",
            field: "created_at",
            width: "4%",
        },
        {
            name: "Χρήστης",
            field: "user",
            width: "10%",
        },
        {
            name: "Τύπος Εγγραφής",
            field: "type",
            width: "13%",
        },
        {
            name: "Αντικείμενο Εγγραφής",
            field: "item",
            width: "13%",
        },
        {
            name: "Εγγραφή",
            width: "45%",
        },
        {
            name: "Λοιπά Στοιχεία",
            field: "extraNotes",
            width: "10%",
        }
    ];
    const logTypeOptions = getLogTypeOptions().map((el) => ({label: t(`Logs.${el.label}`), value: el.value}))

    useEffect(() => {
       loadUsersListData(null, setUsersList);
    }, []);

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchLogs(true);
        }
    }, [pageChanged])

    const loadUsersListData = (name, setData) => {
        axios.post(process.env.REACT_APP_API_URL2 + `/user/list-users`, {name: name, company: company.id}, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    setData(res.data.data);
                } else {
                    setData([]);
                }
            } else {
                setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const onChangeSearch = (keyword, setData) => {
        if (String(keyword).length >= 2) {
            loadUsersListData(keyword, setData);
        }
    }

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setMasterLogTableRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}))
            } else {
                dispatch(setMasterLogTableRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "advancedCombobox") {
            if (e.row.userName === "System task") dispatch(setMasterLogTableRequestData({...requestData, [name] : e.row.userName}));
            else dispatch(setMasterLogTableRequestData({...requestData, [name] : `${e.row.firstName} ${e.row.lastName} (${e.row.userName})`}));
        } else if (type === "textInput") {
            dispatch(setMasterLogTableRequestData({...requestData, [name] : e.target.value}));
        } else if (type === "dropdown") {
            dispatch(setMasterLogTableRequestData({...requestData, [name] : e}));
        }
    }

    const toFirstPage = () => {
        dispatch(setMasterLogTableRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const toNextPage = () => {
        dispatch(setMasterLogTableRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setMasterLogTableRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const toLastPage = () => {
        dispatch(setMasterLogTableRequestData({...requestData, page: requestData.totalPages}));
        setPageChanged(true);
    }

    const fetchLogs = (fromPagination = false) => {
        setLoading(true);
        let params = {
            ...requestData,
            company: company.id,
        }
        if (!fromPagination) params.page = 1;

        axios.get(`${process.env.REACT_APP_API_URL2}/company/get-logs`, {
            headers: { "Content-Type": "application/json" },
            params: params
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (!fromPagination) {
                    if (res.data.data.length === 0) {
                        toast.info("Δεν βρέθηκαν αποτελέσματα.");
                    } else {
                        toast.success("Τα δεδομένα ανανεώθηκαν", {autoClose: 1000});
                    }
                }
                if (!fromPagination) dispatch(setMasterLogTableRequestData({...requestData, page: 1}));
                dispatch(setMasterLogTableResponseData({data: res.data.data, totalPages: res.data.totalPages}));
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={2}>
                    <DateBox
                        name="dateFrom"
                        selected={isEmpty(requestData.dateFrom) ? moment().toDate() : moment(requestData.dateFrom, "DD/MM/YYYY").toDate()}
                        label={t('General.dateFrom')}
                        onChange={(e) => handleOnChange(e, "datebox", "dateFrom")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="dateTo"
                        selected={isEmpty(requestData.dateTo) ? moment().toDate() : moment(requestData.dateTo, "DD/MM/YYYY").toDate()}
                        label={t('General.dateTo')}
                        onChange={(e) => handleOnChange(e, "datebox", "dateTo")}
                    />
                </Col>
                <Col md={5}>
                    {/*<Input*/}
                    {/*    name="user"*/}
                    {/*    label={t("General.user")}*/}
                    {/*    onChange={(e) => handleOnChange(e, "textInput", "user")}*/}
                    {/*    placeholder={t("General.name")}*/}
                    {/*    required={false}*/}
                    {/*/>*/}
                    <AdvancedCombobox
                        name="user"
                        className="ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={requestData.user}
                        label={t("General.user")}
                        data={usersList}
                        onChange={(e) => handleOnChange(e, "advancedCombobox", "user")}
                        resetFilter={() => dispatch(setMasterLogTableRequestData({...requestData, user: ""}))}
                        onChangeSearch={onChangeSearch}
                        excludeColumns={[]}
                        columns={userDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t("General.name")}
                        required={false}
                        showInsertAddButton={false}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                </Col>
                <Col>
                    <Dropdown
                        name={"logTypes"}
                        label={t("General.logTypes")}
                        key={Math.random()}
                        options={logTypeOptions}
                        defaultValue={requestData.logTypes}
                        multiSelect={true}
                        onChange={(e) => handleOnChange(e, "dropdown", "logTypes")}
                    />
                </Col>
            </Row>
            <Row className={"mb-3"}>
                <Col md={12} >
                    <Button onClick={() => fetchLogs()} className="btn btn-primary float-right" disabled={loading}>
                        {t('General.applyFilter')}
                    </Button>
                </Col>
            </Row>
            <div>
                {responseData.totalPages > 0 && (
                    <div className="d-flex justify-content-end mb-2">
                        <Button
                            size={"sm"}
                            className={"mr-1"}
                            onClick={() => toFirstPage()}
                            disabled={requestData.page === 1 || loading}
                        >
                            {t('SalesBalanceSheet.first')}
                        </Button>
                        <Button
                            size={"sm"}
                            className={"mr-1"}
                            onClick={() => toPreviousPage()}
                            disabled={requestData.page === 1 || loading}
                        >
                            {t('SalesBalanceSheet.previous')}
                        </Button>
                        <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                               bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {responseData.totalPages}</Badge>
                        <Button
                            size={"sm"}
                            className={"mr-1"}
                            onClick={() => toNextPage()}
                            disabled={requestData.page >= responseData.totalPages || loading}
                        >
                            {t('SalesBalanceSheet.next')}
                        </Button>
                        <Button
                            size={"sm"}
                            className={"mr-1"}
                            onClick={() => toLastPage()}
                            disabled={requestData.page <= responseData.totalPages || loading}
                        >
                            {t('SalesBalanceSheet.last')}
                        </Button>
                    </div>
                )}
                <table style={{width: "100%", tableLayout: "fixed", textAlign: "center"}} className={"logTable mb-3"}>
                    <colgroup>
                        {tableColumns.map((col, idx) => (
                            <col key={"colInGroup" + idx} style={{width: col.width}}></col>
                        ))}
                    </colgroup>
                    <thead>
                    <tr>
                        {tableColumns.map((col, idx) => (
                            <th key={"logTableHead" + idx}>
                                {col["name"]}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {responseData.data.map((row, rIdx) => (
                        <tr key={"logTableRow" + rIdx} className="border-top">
                            {tableColumns.map((col, cIdx) => {
                                let value;

                                if (col.name === "Ημ/νία") {
                                    value = getISOStringDateToDate(row[col.field]);
                                } else if (col.name === "Ώρα") {
                                    value = new Date(row[col.field]).toLocaleTimeString('en-GB');
                                } else if (col.name === "Τύπος Εγγραφής") {
                                    value = t(`Logs.${row[col.field]}`);

                                } else if (col.name === "Εγγραφή") {
                                    col["field"] = row.fieldsChanged ? "fieldsChanged" : "item";

                                    if (col.field === "fieldsChanged") {
                                        value = [];
                                        let changes = JSON.parse(row[col.field]);
                                        for (let changeProp in changes) {
                                            if (changes.hasOwnProperty(changeProp)) {
                                                let oldValue = changes[changeProp]["oldValue"];
                                                let newValue = changes[changeProp]["newValue"];
                                                if (typeof oldValue === "object") {
                                                    oldValue = JSON.stringify(oldValue).replaceAll(/[,{}]/g, " ");
                                                }
                                                if (typeof newValue === "object") {
                                                    newValue = JSON.stringify(newValue).replaceAll(/[,{}]/g, " ");
                                                }
                                                if (isEmpty(oldValue)) {
                                                    value.push(
                                                        <React.Fragment>
                                                            <div style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent: "space-evenly"
                                                            }}>
                                                                <div style={{width: "30%", textAlign: "right", overflow: "hidden"}}>
                                                                    <strong>(+){t(`Logs.${changeProp}`) === `Logs.${changeProp}` ? changeProp : t(`Logs.${changeProp}`)}:</strong>
                                                                </div>
                                                                <div style={{width: "60%", textAlign: "left", overflow: "hidden"}}>{newValue}</div>
                                                            </div>
                                                        </React.Fragment>);
                                                } else if (isEmpty(newValue)) {
                                                    value.push(<React.Fragment>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "space-evenly"
                                                        }}>
                                                            <div style={{width: "30%", textAlign: "right", overflow: "hidden"}}>
                                                                <strong>(-){t(`Logs.${changeProp}`) === `Logs.${changeProp}` ? changeProp : t(`Logs.${changeProp}`)}:</strong>
                                                            </div>
                                                            <div style={{width: "60%", textAlign: "left", overflow: "hidden"}}>{oldValue}</div>
                                                        </div>
                                                    </React.Fragment>);
                                                } else {
                                                    if (oldValue === "false" && newValue === "true") {
                                                        oldValue = t("Logs.no");
                                                        newValue = t("Logs.yes");
                                                    } else if (oldValue === "true" && newValue === "false") {
                                                        oldValue = t("Logs.yes");
                                                        newValue = t("Logs.no");
                                                    }
                                                    value.push(<React.Fragment>
                                                            <div style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent: "space-evenly"
                                                            }}>
                                                                <div style={{width: "30%", textAlign: "right", overflow: "hidden"}}>
                                                                    <strong>{t(`Logs.${changeProp}`) === `Logs.${changeProp}` ? changeProp : t(`Logs.${changeProp}`)}:</strong>
                                                                </div>
                                                                <div style={{width: "60%", textAlign: "left", overflow: "hidden"}}>{oldValue} &nbsp;
                                                                    <strong>--></strong>&nbsp; {newValue}</div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            }
                                        }
                                    } else {
                                        value = <strong>{row[col.field]}</strong>;
                                    }
                                } else {
                                    value = row[col.field];
                                }
                                return <td key={`logTable${rIdx}C${cIdx}`} style={{padding: "10px 0px"}}>
                                    {value}
                                </td>
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MasterLogSearch