import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import EmployeeProgram from "./employee-program";
import {useDispatch, useSelector} from "react-redux";
import {
    setWorkingProgram,
    setWorkingProgramNeedsSave,
    setWorkingProgramOnEmployee
} from "../../../../../_reducers/DataPanel/Payroll/payrollSlice";
import {toast} from "react-toastify";
import {addTab, removeTab, updateActiveMainTab} from "../../../../../_reducers/TabsSlice";
import {applyRounding} from "./constants";
import {classicStyleBelowNavbar} from "../../Statics";

const EmployeeWorkingProgram = () => {
    const dispatch = useDispatch();
    const workingProgram = useSelector((state) => state.PAYROLL.workingProgram);
    const workingProgramOnEmployee = useSelector((state) => state.PAYROLL.workingProgramOnEmployee);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);

    const displayHourFrom = 0;
    const displayHourTo = 24;
    const dragCoefficient = 0.0232;

    const [events, setEvents] = useState([]);

    useEffect(() => {
        if (workingProgramOnEmployee) {
            const cloneEvents = structuredClone(workingProgramOnEmployee.dailyCalendar);
            let cnt = 0;
            for (let ev of cloneEvents) {
                ev.id = cnt;
                cnt++;
            }
            setEvents(cloneEvents);
        }
    }, [workingProgramOnEmployee])

    // resizeType = bottom or top
    const onEventResize = (eventId, diff, resizeType) => {
        const cloneEvents = structuredClone(events);
        const findEvent = cloneEvents.find((el) => el.id === eventId);

        const inMinutes = diff * dragCoefficient;
        if (findEvent) {
            if (resizeType === "bottom") {
                findEvent.hourTo = Math.min(findEvent.hourTo + inMinutes, displayHourTo);
            } else if (resizeType === "top") {
                findEvent.hourFrom = Math.max(findEvent.hourFrom + inMinutes, displayHourFrom);
            }
        }
        setEvents(cloneEvents);
    }

    const onEventDrag = (eventId, diff) => {
        const cloneEvents = structuredClone(events);
        const findEvent = cloneEvents.find((el) => el.id === eventId);

        const inMinutes = diff * dragCoefficient;
        if (findEvent) {
            if (findEvent.hourTo + inMinutes <= displayHourTo && findEvent.hourFrom + inMinutes >= displayHourFrom) {
                findEvent.hourFrom = Math.max(findEvent.hourFrom + inMinutes, displayHourFrom);
                findEvent.hourTo = Math.min(findEvent.hourTo + inMinutes, displayHourTo);
            }
        }
        setEvents(cloneEvents);
    }

    const onEventAdd = (newEvent) => {
        const cloneEvents = structuredClone(events);
        cloneEvents.push(newEvent);
        setEvents(cloneEvents);
    }

    const onEventsAdd = (newEvents) => {
        let cloneEvents = structuredClone(events);
        cloneEvents = [...cloneEvents, ...newEvents];
        setEvents(cloneEvents);
    }

    const onEventDelete = (id) => {
        let cloneEvents = structuredClone(events);
        cloneEvents = cloneEvents.filter((el) => el.id !== id);
        setEvents(cloneEvents);
    }

    const onEventEdit = (ev) => {
        let cloneEvents = structuredClone(events);
        let idx = cloneEvents.findIndex((el) => el.id === ev.id);
        cloneEvents[idx] = ev;
        setEvents(cloneEvents);
    }

    const handleCloseEmployeeProgram = () => {
        dispatch(removeTab("working-program-employee"));
        const findWorkingProgram = TABS_DATA.findIndex((item) => "working-program" === item);
        if (findWorkingProgram === -1) {
            dispatch(addTab("working-program"));
            dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
            dispatch(updateActiveMainTab(findWorkingProgram));
        }
    }

    const onSave = () => {
        const cloneWorkingProgram = structuredClone(workingProgram);
        const findEmployee = cloneWorkingProgram?.employees?.find((el) => el.employeeId === workingProgramOnEmployee.employeeId);
        if (findEmployee) {
            findEmployee.dailyCalendar = events.sort((a, b) => a.day < b.day ? -1 : 1);
            dispatch(setWorkingProgram(cloneWorkingProgram));
            dispatch(setWorkingProgramOnEmployee(null));
            dispatch(setWorkingProgramNeedsSave(true));
            handleCloseEmployeeProgram();
        } else {
            toast.error("Σφάλμα κατά την αποθήκευση.");
        }
    }

    const onNotSave = () => {
        dispatch(setWorkingProgramOnEmployee(null));
        handleCloseEmployeeProgram();
    }

    const onApplyRounding = (eventId) => {
        let cloneEvents = structuredClone(events);
        let findMatching = cloneEvents.find((el) => el.id === eventId);
        if (findMatching) {
            findMatching.hourTo = applyRounding(findMatching.hourTo, 15);
            findMatching.hourFrom = applyRounding(findMatching.hourFrom, 15);
            setEvents(cloneEvents);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={6}>
                    <Button size={"sm"} onClick={() => onNotSave()}>{"<<"} Πίσω στο πρόγραμμα (χωρίς αποθήκευση)</Button><br/>
                </Col>
                <Col md={6} className={"d-flex justify-content-end"}>
                    <Button size={"sm"} onClick={() => onSave()}>Αποθήκευση & πίσω στο πρόγραμμα</Button>
                </Col>
            </Row>
            {workingProgramOnEmployee && (
                <Row>
                    <Col md={12}>
                        <EmployeeProgram
                            dateFrom={workingProgram.dateFrom}
                            dateTo={workingProgram.dateTo}
                            events={events}
                            displayHoursFrom={displayHourFrom}
                            displayHoursTo={displayHourTo}

                            onEventResize={onEventResize}
                            onEventDrag={onEventDrag}
                            onEventAdd={onEventAdd}
                            onEventsAdd={onEventsAdd}
                            onEventEdit={onEventEdit}
                            onEventDelete={onEventDelete}
                            onApplyRounding={onApplyRounding}
                        />
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default EmployeeWorkingProgram
