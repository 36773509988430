import {createSlice} from "@reduxjs/toolkit";

const newCashRegistersInitialValue = {
    name: '',
    date: new Date(),
    isSentToAade: 'inactive',
    serialNumber: '',
    notes: '',
    files: '',
    isActive: 'active',
    extra: '',
    accounting: "",
    bypassAccounting: "",
}

const newPOSInitialValue = {
    name: '',
    date: new Date(),
    isSentToAade: 'no',
    serialNumber: '',
    notes: '',
    files: '',
    isActive: 'active',
    extra: '',
    isETameiaki: 'inactive',
    tameiakiUsername: '',
    tameiakiPassword: '',
    tid: '',
    accounting: "",
    bypassAccounting: "",
}

const newBankAccountInitialValue = {
    name: '',
    date: new Date(),
    isSentToAade: 'no',
    iban: '',
    notes: '',
    files: '',
    bank: '',
    bankName: '',
    bankSwift: '',
    bankCountry: '',
    isActive: 'active',
    extra: '',
    accounting: "",
    bypassAccounting: "",
}

const initialCashSettings = {
    accounting: {
        accountingCashRegister: "",
        accountingPOS: "",
        accountingBankAccount: "",
    }
}

const initialState = {
    // Cash Registers
    newCashRegistersInitialValue: newCashRegistersInitialValue,
    newCashRegisters: newCashRegistersInitialValue,
    editCashRegisters: newCashRegistersInitialValue,
    cashRegisters: [],

    // POS
    newPOSInitialValue: newPOSInitialValue,
    newPOS: newPOSInitialValue,
    editPOS: newPOSInitialValue,
    posList: [],

    // Bank Account
    newBankAccountInitialValue: newBankAccountInitialValue,
    newBankAccount: newBankAccountInitialValue,
    editBankAccount: newBankAccountInitialValue,
    bankAccounts: [],

    // Search Data
    searchCashRegisters: [],
    searchPOS: [],
    searchBankAccounts: [],

    // Accounting Settings
    cashSettings: initialCashSettings
};

export const CashSlice = createSlice({
    name: "cash",
    initialState,
    reducers: {

        // Cash Registers
        setNewCashRegisters: (state, {payload}) => {
            state.newCashRegisters = payload;
        },
        setEditCashRegisters: (state, {payload}) => {
            state.editCashRegisters = payload;
        },

        // POS
        setNewPOS: (state, {payload}) => {
            state.newPOS = payload;
        },
        setEditPOS: (state, {payload}) => {
            state.editPOS = payload;
        },
        resetNewPOS: (state, {payload}) => {
            state.newPOS = newPOSInitialValue;
        },

        // Bank Account
        setNewBankAccount: (state, {payload}) => {
            state.newBankAccount = payload;
        },
        setEditBankAccount: (state, {payload}) => {
            state.editBankAccount = payload;
        },

        // Search Data
        setSearchCashRegisters: (state, {payload}) => {
            state.searchCashRegisters = payload;
            state.cashRegisters = payload;
        },
        setSearchPOS: (state, {payload}) => {
            state.searchPOS = payload;
            state.posList = payload;
        },
        setSearchBankAccounts: (state, {payload}) => {
            state.searchBankAccounts = payload;
            state.bankAccounts = payload;
        },

        // Accounting Settings
        setCashSettings: (state, {payload}) => {
            state.cashSettings = payload;
        },
        setCashAccountingSettings: (state, {payload}) => {
            state.cashSettings.accounting = payload;
        }
    },
});

export const {
    setNewCashRegisters,
    setEditCashRegisters,
    setNewPOS,
    setEditPOS,
    setNewBankAccount,
    setEditBankAccount,
    setSearchCashRegisters,
    setSearchPOS,
    setSearchBankAccounts,
    resetNewPOS,
    setCashSettings,
    setCashAccountingSettings
} = CashSlice.actions;