import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Col, Row} from "react-bootstrap";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {useTranslation} from "react-i18next";
import {setNewWorkMovement} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import {toast} from "react-toastify";
import DateBox from "../../../primer/DateBox";
import moment from "moment";
import Dropdown from "../../../primer/Dropdown";
import {
    colorMapping,
    decimalToHourMinutesFormat, delayReasonOptions,
    getWorkType, movementTypeOptions
} from "./Components/constants";
import HourPicker from "../../../primer/HourPicker";
import TextArea from "../../../common/TextArea";
import {setSaveNewWorkMovement} from "../../../../_reducers/activitySlice";

const NewWorkMovement = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const workMovement = useSelector((state) => state.PAYROLL.newWorkMovement);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);

    const [installationOptions, setInstallationOptions] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [loading, setLoading] = useState(false);

    const employeeDropdownColumns = [
        {
            label: t('General.firstName'),
            name: "firstName",
        },
        {
            label: t('General.lastName'),
            name: "lastName",
        },
        {
            label: t('General.vatNumber'),
            name: "vatNumber",
        },
    ]

    useEffect(() => {
        loadEmployeeList("empty", setEmployeeList);

        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
    }, [])

    const loadEmployeeList = (keyword, setData) => {
        let reqData = {
            company: company.id,
            subAccountType: "employee",
            keyword: keyword
        }
        axios.get(process.env.REACT_APP_API_URL2 + `/accounting/get-subAccount-by-word`, {
            params: reqData
        }).then(res => {
            if (res.data && res.data.status === '200') {
                if (res.data.data) {
                    if (setData) setData(res.data.data);
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleOnChange = (e, type, name) => {
        if (!ACTIVITY.saveNewWorkMovement) dispatch(setSaveNewWorkMovement(true));

        if (type === "acdd") {
            if (name === "employeeName") {
                dispatch(setNewWorkMovement({
                    ...workMovement,
                    employeeName: `${e.row.firstName} ${e.row.lastName} ${e.row.vatNumber}`,
                    employeeId: e.row._id,
                    time: moment().format("HH:mm"),
                    employeeProgramData: {},
                    installationMasterId: "",
                    movementType: "arrive",
                    delayReason: "",
                    notes: "",
                }))
            }
        } else if (type === "datebox") {
            if (name === "date") {
                let changeDate = moment(e).isValid() ? e : moment().toDate();
                axios.get(`${process.env.REACT_APP_API_URL2}/payroll/employee-program-data`, {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        date: moment(changeDate).format("DD/MM/YYYY"),
                        company: company.id,
                        employeeId: workMovement.employeeId,
                    }
                }).then((res) => {
                    if (res.data.status === "200") {
                        dispatch(setNewWorkMovement({
                            ...workMovement,
                            date: changeDate,
                            employeeProgramData: res.data.dailyCalendar,
                            installationMasterId: res.data.dailyCalendar[0]?.installationMasterId,
                        }));
                    } else {
                        console.log(res.data.message);
                        toast.error(res.data.message, {autoClose: 10000});
                    }
                }).catch((error) => {
                    console.log(error);
                    toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                })
            }
        } else if (type === "hour-picker") {
            dispatch(setNewWorkMovement({...workMovement, [name]: e}));
        } else if (type === "textarea") {
            dispatch(setNewWorkMovement({...workMovement, [name]: e.target.value}));
        } else if (type === "dropdown") {
            dispatch(setNewWorkMovement({...workMovement, [name]: e.value}));
        }
    }

    const dailyProgramSelected = workMovement.employeeProgramData?.length > 0;

    const isWorkMovementDelayed = () => {
        let diff = moment().diff(moment(workMovement.date).set({hour: Number(String(workMovement.time).split(":")[0]), minute: Number(String(workMovement.time).split(":")[1])}), "minute")
        diff = Math.abs(diff);

        return diff > 15;
    }

    return (
        <React.Fragment>
            <Row className={"mb-3"}>
                <Col md={5}>
                    <AdvancedCombobox
                        name={"employeeName"}
                        className={"w-100 ac-select-dropdown"}
                        searchableDropdownEnable={false}
                        defaultValue={workMovement.employeeName}
                        label={t("General.employee")}
                        data={employeeList}
                        onChange={(e) => handleOnChange(e, "acdd", "employeeName")}
                        key={workMovement.employeeId}
                        resetFilter={() => dispatch(setNewWorkMovement({
                            ...workMovement,
                            employeeName: "",
                            employeeId: ""
                        }))}
                        onChangeSearch={(keyword, setData) => loadEmployeeList(keyword, setData)}
                        excludeColumns={[]}
                        columns={employeeDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t("Placeholders.selectEmployee")}
                        required={true}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                    {dailyProgramSelected && (
                        <Dropdown
                            name={"installationMasterId"}
                            label={t("General.installation")}
                            options={installationOptions}
                            defaultValue={installationOptions.find((el) => el.value === workMovement.employeeProgramData[0]?.installationMasterId)}
                            key={Math.random()}
                            disabled={true}
                            className={"mt-2"}
                        />
                    )}
                </Col>
                <Col md={2}>
                    <DateBox
                        name={"date"}
                        label={"Ημερομηνία"}
                        selected={workMovement.date}
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(e) => handleOnChange(e, "datebox", "date")}
                    />
                </Col>
                {dailyProgramSelected && (
                    <Col md={5}>
                        {workMovement.employeeProgramData?.map((dc) => (
                            <div style={{
                                position: "relative",
                                display: "inline-block",
                                height: "100%",
                                width: `${(100 / workMovement.employeeProgramData.length) - 2}%`,
                                backgroundColor: colorMapping[dc.workType],
                                margin: "4px",
                                padding: "4px",
                                borderRadius: "8px",
                                textAlign: "center"
                            }}>
                                {getWorkType(dc)}<br/>
                                {dc?.allDay ? (
                                    <React.Fragment>
                                        Όλη μέρα<br/>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {decimalToHourMinutesFormat(dc.hourFrom)} - {decimalToHourMinutesFormat(dc.hourTo)}<br/>
                                    </React.Fragment>
                                )}
                                Εγκατάσταση: {dc.installationMasterId}
                            </div>
                        ))}
                    </Col>
                )}
            </Row>
            {dailyProgramSelected && (
                <React.Fragment>
                    <hr className={"mt-3 mb-2"}/>
                    <Row className={"mb-3"}>
                        <Col md={3}>
                            <Dropdown
                                name={"movementType"}
                                label={"Τύπος Κίνησης"}
                                options={movementTypeOptions}
                                key={Math.random()}
                                defaultValue={movementTypeOptions.find((el) => el.value === workMovement.movementType)}
                                onChange={(e) => handleOnChange(e, "dropdown", "movementType")}
                            />
                        </Col>
                        <Col md={2}>
                            <HourPicker
                                label={"Ώρα"}
                                name={"time"}
                                timeIntervals={3}
                                selected={moment(workMovement.time, "HH:mm").toDate()}
                                onChange={(e) => handleOnChange(e, "hour-picker", "time")}
                            />
                        </Col>
                        {isWorkMovementDelayed() && (
                            <Col md={3}>
                                <Dropdown
                                    name={"delayReason"}
                                    label={"Λόγος καθυστέρησης"}
                                    options={delayReasonOptions}
                                    key={Math.random()}
                                    defaultValue={delayReasonOptions.find((el) => el.value === workMovement.delayReason)}
                                    onChange={(e) => handleOnChange(e, "dropdown", "delayReason")}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextArea
                                name={"notes"}
                                label={t("General.notes")}
                                rows={4}
                                value={workMovement.notes}
                                onChange={(e) => handleOnChange(e, "textarea", "notes")}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default NewWorkMovement
