import React, {useEffect, useState} from 'react';
import {Accordion, Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import {toast} from "react-toastify";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {Popover} from "antd";
import {
    resetTempNewShift, setCompanyHasErgani,
    setPayrollSettings,
    setTempNewShift
} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import EditableTable from "../../../primer/Editable-Table";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {fetchPayrollSettings} from "../../../../_apis/api";
import "../../../primer/POPUP-COMPONENTS/modalWidths.scss";
import moment from "moment";
import {greekMonthOptions} from "./Components/constants";

export const PayrollSettings = () => {
    const dispatch = useDispatch();
    const tempNewShift = useSelector((state) => state.PAYROLL.tempNewShift);
    const payrollSettings = useSelector((state) => state.PAYROLL.payrollSettings);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const [installationOptions, setInstallationOptions] = useState([]);

    const [activationLoading, setActivationLoading] = useState(false);
    const [loadingSettings, setLoadingSettings] = useState(false);

    const defaultExProanData = {
        installationMasterId: "0",
        month: moment().format("MM"),
        year: moment().format("YYYY"),
        yesNoOption: "1",
        comments: "",
    }
    const [showExProanModal, setShowExProanModal] = useState(false);
    const [exProanData, setExProanData] = useState({...defaultExProanData});
    const [yearOptions, setYearOptions] = useState([]);
    const [loadingExProan, setLoadingExProan] = useState(false);

    const [showRetrieveEmployeesModal, setShowRetrieveEmployeesModal] = useState(false);
    const [retrievingEmployees, setRetrievingEmployees] = useState(false);
    const [retrievedEmployeesData, setRetrievedEmployeesData] = useState([]);
    const [savingRetrievedEmployees, setSavingRetrievedEmployees] = useState(false);

    const envOptions = [
        { label: "Δοκιμή", value: "test" },
        { label: "Παραγωγικό", value: "production" }
    ]
    const [erganiCredentials, setErganiCredentials] = useState({
        username: "",
        password: "",
        environment: "test",
    })
    const yesNoOptions = [
        { label: "Όχι", value: "0" },
        { label: "Ναι", value: "1" }
    ]

    const tableColumns = [
        {
            name: "Όνομα βάρδιας",
            field: "name",
            editable: true,
            width: "25%",
            required: true,
            inputType: {
                type: "input"
            }
        },
        {
            name: "Ώρα από",
            field: "hourFrom",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
        {
            name: "Ώρα μέχρι",
            field: "hourTo",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
        {
            name: "Σπαστό ωράριο από",
            field: "splitHourFrom",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
        {
            name: "Σπαστό ωράριο μέχρι",
            field: "splitHourTo",
            editable: true,
            width: "15%",
            inputType: {
                type: "hour-picker"
            }
        },
    ]

    useEffect(() => {
        fetchSettings();

        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);

            let tmp = [];
            for (let i = 2023; i < Number(moment().add(10, "years").format("YYYY")); i++) {
                tmp.push({ label: String(i), value: String(i) });
            }
            setYearOptions(tmp);
        }
    }, []);

    useEffect(() => {
        if (!showExProanModal) {
            setExProanData({...defaultExProanData});
        }
    }, [showExProanModal])

    useEffect(() => {
        if (!showRetrieveEmployeesModal) {
            setShowRetrieveEmployeesModal(false);
            setRetrievingEmployees(false);
            setRetrievedEmployeesData([]);
            setSavingRetrievedEmployees(false);
        }
    }, [showRetrieveEmployeesModal])

    const handleActivateErgani = () => {
        if (!isEmpty(erganiCredentials) && !isEmpty(erganiCredentials.password)) {
            setActivationLoading(true);
            axios.post(`${process.env.REACT_APP_API_URL2}/payroll/activate-company`, {
                ...erganiCredentials,
                company: company.id
            }, {
                headers: {'Content-Type': 'application/json'}
            }).then((res) => {
                setActivationLoading(false);
                if (res.data.status === "200") {
                    dispatch(setCompanyHasErgani(true));
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message, {autoClose: 8000});
                }
            }).catch((err) => {
                setActivationLoading(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Παρακαλώ συμπληρώστε όνομα και κωδικό χρήστη.");
        }
    }

    const fetchSettings = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/fetch-settings`, {
            headers: {'Content-Type': 'application/json'},
            params: {
                company: company.id,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                setErganiCredentials(res.data.erganiCredentials);
                if (res.data.payrollSettings) {
                    dispatch(setPayrollSettings({...res.data.payrollSettings}));
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την ανάκτηση στοιχείων.");
        })
    }

    const tableShiftsFunctions = {
        insert: (e, data) => {
            const cloneNewTempShift = structuredClone(tempNewShift);
            cloneNewTempShift[data.field] = data.value;

            if (!isEmpty(cloneNewTempShift.name) && (cloneNewTempShift.hourFrom !== "00:00" || cloneNewTempShift.hourTo !== "00:00")) { // Insert
                let cloneShifts = structuredClone(payrollSettings.shifts);
                cloneShifts.push(cloneNewTempShift);
                dispatch(setPayrollSettings({...payrollSettings, shifts: cloneShifts}));
                dispatch(resetTempNewShift());
            } else { // Pre-insert row update
                dispatch(setTempNewShift(cloneNewTempShift));
            }
        },
        update: (data, id, rowIndex) => {
            let cloneShifts = structuredClone(payrollSettings.shifts);
            cloneShifts[rowIndex] = {...cloneShifts[rowIndex], ...data};
            dispatch(setPayrollSettings({...payrollSettings, shifts: cloneShifts}));
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            let cloneShifts = structuredClone(payrollSettings.shifts);
            cloneShifts.splice(rowIndex, 1);
            dispatch(setPayrollSettings({...payrollSettings, shifts: cloneShifts}));
        }
    }

    const handleOnChange = (e, type, name) => {
        if (type === "cb") {
            dispatch(setPayrollSettings({...payrollSettings, [name] : e.target.checked}));
        }
    }

    const handleSavePayrollSettings = () => {
        setLoadingSettings(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/save-settings`, {
            ...payrollSettings,
            company: company.id,
        }).then((res) => {
            setLoadingSettings(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής αποθήκευση ρυθμίσεων!");
                dispatch(fetchPayrollSettings(company.id));
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoadingSettings(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleSaveExProan = () => {
        setLoadingExProan(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/post-exproan`, {
            ...exProanData,
            company: company.id,
        }).then((res) => {
            setLoadingExProan(false);
            if (res.data.status === "200") {
                toast.success(res.data.message);
                setShowExProanModal(false);
            } else {
                toast.error(res.data.message, {autoClose: 10000});
            }
        }).catch((err) => {
            setLoadingExProan(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleFetchErganiEmployees = () => {
        setRetrievingEmployees(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/get-employees-from-ergani`, {
            company: company.id,
        }, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            setRetrievingEmployees(false);
            if (res.data.status === "200") {
                if (res.data.data?.length === 0) {
                    toast.info("Δεν βρέθηκε κανένας εργαζόμενος.");
                    setRetrievedEmployeesData([]);
                } else {
                    toast.success(res.data.message);
                    setRetrievedEmployeesData(res.data.data);
                }
            } else {
                toast.error(res.data.message, {autoClose: 5000});
                setRetrievedEmployeesData([]);
            }
        }).catch((err) => {
            setRetrievingEmployees(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος. Παρακαλώ ξαναδοκιμάστε");
        })
    }

    const handleSaveRetrievedEmployees = () => {
        setSavingRetrievedEmployees(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/save-employees-from-ergani`, {
            data: retrievedEmployeesData,
            company: company.id
        }, {
            headers: {'Content-Type': 'application/json'},
        }).then((res) => {
            setSavingRetrievedEmployees(false);
            if (res.data.status === "200") {
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setSavingRetrievedEmployees(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος. Παρακαλώ ξαναδοκιμάστε");
        })
    }

    return (
        <React.Fragment>
            <Row className={"mb-2"}>
                <Col md={5}>
                    <div className={"text-muted mb-1"}><i>Στοιχεία Εργάνη</i></div>
                    <Popover placement={"right"} content={
                        <div>
                            Το όνομα χρήστη που χρησιμοποιείτε για να συνδεθείτε στο https://eservices.yeka.gr
                        </div>
                    }>
                        <>
                            <Input
                                className={"mb-2"}
                                name={"erganiUsername"}
                                label={"Όνομα Χρήστη"}
                                value={erganiCredentials.username}
                                autoComplete={"off"}
                                onChange={(e) => setErganiCredentials({...erganiCredentials, username: e.target.value})}
                            />
                        </>
                    </Popover>

                    <Popover placement={"right"} content={
                        <div>
                            Ο κωδικός που χρησιμοποιείτε για να συνδεθείτε στο https://eservices.yeka.gr
                        </div>
                    }>
                        <>
                            <Input
                                className={"mb-2"}
                                name={"erganiPassword"}
                                label={"Κωδικός Χρήστη"}
                                type={"password"}
                                value={erganiCredentials.password}
                                autoComplete={"off"}
                                onChange={(e) => setErganiCredentials({...erganiCredentials, password: e.target.value})}
                            />
                        </>
                    </Popover>
                    <Dropdown
                        name={"erganiEnvironment"}
                        options={envOptions}
                        label={"Περιβάλλον"}
                        defaultValue={envOptions.find((el) => el.value === erganiCredentials.environment)}
                        key={Math.random()}
                        onChange={(e) => setErganiCredentials({...erganiCredentials, environment: e.value})}
                    />
                    <div style={{textAlign: "center"}}>
                        <Button onClick={() => handleActivateErgani()} disabled={activationLoading}>
                            Ενεργοποίηση {activationLoading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                        </Button>
                    </div>
                    <div className={"mt-5"}>
                        <div className={"text-muted mb-1"}><i>Λοιπές Λειτουργίες Εργάνης</i></div>
                        <Button size={"sm"} onClick={() => setShowExProanModal(true)}>
                            Δημιουργία δήλωσης εξαίρεσης από την υποχρέωση προαναγγελίας
                        </Button>
                    </div>
                    <div className={"mt-2"}>
                        <Button size={"sm"} onClick={() => setShowRetrieveEmployeesModal(true)}>
                            Άντληση εργαζομένων από την Εργάνη
                        </Button>
                    </div>
                </Col>
                <Col md={7} style={{borderLeft: "1px solid gray"}}>
                    <div className={"text-muted mb-2"}><i>Βάρδιες</i></div>
                    <EditableTable
                        tableName={"Payroll Settings Shifts"}
                        key={Math.random()}
                        data={payrollSettings.shifts}
                        columns={tableColumns}
                        onUpdate={tableShiftsFunctions}
                        allowInsertRow={true}
                        allowActions={true}
                    />
                    <div className={"text-muted mb-2 mt-2"}><i>Αυτοματισμοί</i></div>
                    <Row>
                        <Col md={6}>
                            <BetterCheckBox
                                name={"autoSendEmployeeCard"}
                                text={"Να γίνεται αυτόματη αποστολή καρτών στην Εργάνη"}
                                checked={payrollSettings.autoSendEmployeeCard}
                                onChange={(e) => handleOnChange(e, "cb", "autoSendEmployeeCard")}
                            />
                        </Col>
                        <Col md={6}>
                            <BetterCheckBox
                                name={"autoSendOutOfTimeDays"}
                                text={"Να γίνεται αυτόματη αποστολή απολογιστικού στο τέλος του μήνα"}
                                checked={payrollSettings.autoSendOutOfTimeDays}
                                onChange={(e) => handleOnChange(e, "cb", "autoSendEmployeeCard")}
                            />
                        </Col>
                    </Row>
                    <Button size={"sm"} className={"float-right"} disabled={loadingSettings} onClick={() => handleSavePayrollSettings()}>
                        Αποθήκευση ρυθμίσεων {loadingSettings && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Col>
            </Row>

            <Modal show={showExProanModal} backdrop={"static"} onHide={() => setShowExProanModal(false)} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header closeButton>
                    <Modal.Title>Δημιουργία δήλωσης εξαίρεσης από την υποχρέωση προαναγγελίας</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Dropdown
                                name={"installationMasterId"}
                                label={"Εγκατάσταση"}
                                options={installationOptions}
                                key={Math.random()}
                                defaultValue={installationOptions.find((el) => el.value === exProanData.installationMasterId)}
                                onChange={(e) => setExProanData({...exProanData, installationMasterId: e.value})}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Dropdown
                                name={"yesNoOption"}
                                label={"Εξαίρεση Προαναγγελίας"}
                                options={yesNoOptions}
                                key={Math.random()}
                                defaultValue={yesNoOptions.find((el) => el.value === exProanData.yesNoOption)}
                                onChange={(e) => setExProanData({...exProanData, yesNoOption: e.value})}
                            />
                        </Col>
                        <Col md={4}>
                            <Dropdown
                                name={"month"}
                                label={"Μήνας"}
                                options={greekMonthOptions}
                                key={Math.random()}
                                defaultValue={greekMonthOptions.find((el) => el.value === exProanData.month)}
                                onChange={(e) => setExProanData({...exProanData, month: e.value})}
                            />
                        </Col>
                        <Col md={4}>
                            <Dropdown
                                name={"year"}
                                label={"Έτος"}
                                options={yearOptions}
                                key={Math.random()}
                                defaultValue={yearOptions.find((el) => el.value === exProanData.year)}
                                onChange={(e) => setExProanData({...exProanData, year: e.value})}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowExProanModal(false)}>
                        Ακύρωση
                    </Button>
                    <Button variant="primary" onClick={() => handleSaveExProan()} disabled={loadingExProan}>
                        Δημιουργία {loadingExProan && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRetrieveEmployeesModal} backdrop={"static"} dialogClassName={"modal42PercentWidth"}>
                <Modal.Header>
                    <Modal.Title>Άντληση εργαζομένων από την Εργάνη</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflowY: "auto", maxHeight: "65vh"}} className={"mr-1"}>
                        Η διαδικασία της άντλησης θα ξεκινήσει πατώντας το κουμπί "Άντληση εργαζομένων από την Εργάνη".
                        Ο χρόνος που θα πάρει η διαδικασία εξαρτάται από το πλήθος των εργαζομένων που έχετε. Αμέσως μετά την άντληση,
                        θα εμφανιστεί ένας πίνακας ο οποίος θα σας δείξει τα αποτελέσματα. Δεν θα τροποποιηθεί ούτε θα δημιουργηθεί κανένας εργαζόμενος.
                        Θα εμφανιστεί και κουμπί "Αποθήκευση" που πατώντας το, θα ενημερώσει κάθε εργαζόμενο στην περίπτωση που υπάρχει αλλιώς θα δημιουργηθεί.
                        <div style={{textAlign: "center"}} className={"mb-3 mt-2"}>
                            <Button size={"sm"} disabled={retrievingEmployees || savingRetrievedEmployees} onClick={() => handleFetchErganiEmployees()}>
                                Άντληση εργαζομένων από την Εργάνη
                                {retrievingEmployees && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                            </Button>
                        </div>
                        {retrievedEmployeesData?.length > 0 && (
                            <React.Fragment>
                                {retrievedEmployeesData.length === 1 ? (
                                    <div className={"mb-2"}>Βρέθηκε μόνο ένας εργαζόμενος</div>
                                ) : (
                                    <div className={"mb-2"}>Βρέθηκαν {retrievedEmployeesData.length} εργαζόμενοι</div>
                                )}
                                <Accordion>
                                    {retrievedEmployeesData.map((empData, idx) => (
                                        <Accordion.Item eventKey={String(idx)}>
                                            <Accordion.Header>{`${empData["Επώνυμο"]} ${empData["Όνομα"]} (ΑΦΜ: ${empData["ΑΦΜ"]})`}</Accordion.Header>
                                            <Accordion.Body>
                                                {(() => {
                                                    let compArray = [];
                                                    for (let key in empData) {
                                                        if (!isEmpty(empData[key])) {
                                                            compArray.push(<span><strong>{key}:</strong> {empData[key]}<br/></span>);
                                                        }
                                                    }
                                                    return compArray;
                                                })()}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                                <div style={{textAlign: "center"}} className={"mt-3"}>
                                    <Button size={"sm"} onClick={() => handleSaveRetrievedEmployees()} disabled={savingRetrievedEmployees || retrievingEmployees}>
                                        Αποθήκευση
                                        {savingRetrievedEmployees && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </Modal.Body>
                {!retrievingEmployees && (
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowRetrieveEmployeesModal(false)}>
                            Κλείσιμο
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </React.Fragment>
    )
}

export const PayrollSettingsLogs = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}