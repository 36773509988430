import React, {useRef} from "react";
import { browserName } from 'react-device-detect';

const BuyPrintA4English = React.forwardRef((props, ref) => {
    if(browserName !== "Safari"){
        require ('./scss/buy-print-a4.scss');
    } else {
        require ('./scss/buy-print-a4.scss');
    }
    let templateData = [];
    if (props.data) {
        templateData = props.data;
    }
    let showWaterMark = templateData.MYDATAENVIRONMENT === "test";
    const waterMarkStyle = {
        position: "absolute",
        transform: "translate(90px, -30%) rotate(-45deg)",
        left: "-30px",
        bottom: "50%",
        fontSize: "222px",
        margin: "0 0 -250px 0",
        color: "#cdcdcd",
        textTransform: "uppercase",
        opacity: "0.7",
    }

    const pageStyle = {
        width: "210mm",
        height: "297mm",
    }

    const skinBoldStyle = {
        color: "#555",
        fontWeight: "bold",
        lineHeight: "1.2",
    }

    const customerInformationStyle = {
        fontSize: "16px",
        textDecoration: "underline",
        color: "#555",
        fontWeight: "bold",
        textAlign: "center",
    }

    const informationCustomerIdStyle = {
        width: "23%",
        color: "#555",
        fontWeight: "bold",
    }
    const informationCustomerIdStyle2 = {
        width: "45%",
        color: "#555",
        fontWeight: "bold",
    }

    const invoiceBoxStyle = {
        fontSize: "16px",
        //fontFamily: "'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif",
        fontFamily: "Arial",
        color: "#555",
        width: "100%",
        height: "100%",
        position: "relative",
    }

    const totalContainerStyle = {
        border: "1px solid transparent",
        borderRadius: "14px",
        overflow: "visible",
        maxHeight: "280px"
        // marginBottom: "10px",
    }

    const totalContainerTotalsStyle = {
        border: "1px solid #ddd",
        boxShadow: "0 0 10px rgba(0, 0, 0, .15)",
        fontSize: "9px",
        backgroundColor: "#555",
    }

    const totalPaymentStyleTd = {
        textAlign: "right",
        marginRight: "20px",
        backgroundColor: "white",
    }

    const totalPaymentPStyle = {
        padding: "4px",
        margin: "4px",
        fontSize: "16px",
    }

    const totalPaymentStyle = {
        fontWeight: "700",
        fontSize: "16px",
    }

    const invoiceBoxTableTdStyle = {
        padding: "1px",
        border: "unset",
    }

    const logoContainerStyle = {
        fontSize: "30px",
        width: "40%",
        color: "#333",
        border: "none",
    }

    const totalTdBlock = {
        width: "263px",
        verticalAlign: "bottom",
    }

    const totalTdBlockTotalsTable = {
        width: "263px",
        borderSpacing: 0,
        borderCollapse: "collapse",
        border: 0,
    }

    const totalContainerHeadingStyle = {
        backgroundColor: "#555",
        color: "white",
        maxWidth: "130px"
    }

    const totalContainerHeadingPStyle = {
        padding: "1px",
        margin: "4px",
        fontSize: "16px",
    }

    const mainInfoTableTr = {
        // border: "1px solid #ddd",
        fontWeight: "700",
        textAlign: "center",
    }

    const productsStyle = {
        border: "1px solid black",
        textAlign: "center",
        fontSize: "16px",
    }

    const productsStyleTdStyle = {
        fontSize: "16px",
        textAlign: "center",
        maxWidth: "300px",
        overflowWrap: "break-word",
        overflow: "hidden",
        borderRight: "1px solid black",
        borderBottom: "1px solid black",
    }

    const mainInfoTableHeadingPStyle = {
        margin: "4px",
        fontSize: "16px",
        lineHeight: 1,
    }

    const productContainerStyle = {
        marginTop: "4px",
        border: "2px solid #555",
        borderRadius: "4px",
        overflow: "hidden",
        height: "550px",
        padding: "2px",
    }
    const productContainerStyleLastPage = {
        marginTop: "4px",
        border: "2px solid #555",
        borderRadius: "4px",
        overflow: "hidden",
        height: "550px",
        padding: "2px",
    }

    const productTableHeadingStyle = {
        backgroundColor: "#555",
        color: "white",
    }

    const headingStyle = {
        backgroundColor: "#555",
        color: "white",
        border: "1px solid #ddd",
        fontWeight: "700",
        textAlign: "center",
    }

    const headingTdStyle = {
        margin: "4px",
        borderTop: "unset",
        borderBottom: "unset",
        borderRight: "2px solid #fff",
        borderLeft: "2px solid #fff",
    }

    const mainInfoStyle = {
        border: "1px solid #555",
        borderRadius: "4px",
        overflow: "hidden",
        padding: "3px 3px 0 3px",
    }

    const footerContainerStyle = {
        position: "absolute !important",
        bottom: "0px",
        width: "100%",
        margin: "20px auto auto auto",
        paddingBottom: "10",
    }

    const headerTableStyle = {
        width: "100%",
        textAlign: "left",
    }

    const issuerContainerStyle = {
        textAlign: "center",
        marginTop: "6px",
        border: "none",
        lineHeight: 1.2,
        padding: "10px",
    }

    const IssuerName = {
        fontSize: "15px",
        fontWeight: "bold",
        color: "#555",
        lineHeight: 1.2,
    }

    const issuerSubjectField = {
        fontWeight: "normal",
        fontStyle: "italic",
        fontSize: "15px",
        color: "#555",
        lineHeight: 1.2,
    }

    const issuerAddressStyle = {
        fontSize: "13px",
        color: "#555",
        lineHeight: 1.2,
    }

    const issuerContainerPStyle = {
        margin: "0px",
        fontSize: "14px",
        lineHeight: 1.2,
    }

    const informationTableStyle = {
        marginTop: "2px",
        width: "100%",
    }

    const quantityHeadTd = {
        fontSize: "16px",
        textAlign: "center",
        borderRight: "1px solid #ddd",
    }

    const descriptionHeadTd = {
        fontSize: "16px",
        textAlign: "center",
        borderRight: "1px solid #ddd",
        width: "34%",
    }

    const priceHeadTdStyle = {
        fontSize: "16px",
        textAlign: "center",
        borderRight: "1px solid #ddd",
        width: "8%",
    }
    const vatPerHeadTdStyle = {
        fontSize: "16px",
        textAlign: "center",
        borderRight: "1px solid #ddd",
        width: "4%",
    }

    const priceWithVatStyle = {
        padding: "4px",
        margin: "4px",
        fontSize: "16px",
    }

    const blankRowBorderedTdStyle = {
        borderTop: "1px solid white",
        backgroundColor: "white",
        maxHeight: "2px",
        height: "2px",
        lineHeight: "2px",
    }

    const infoValueStyle = {
        color: "#555",
        fontWeight: "normal",
        lineHeight: "1.2",
    }

    const contNotationStyle = {
        border: "1px solid #555",
        padding: "8px",
        borderRadius: "8px",
        fontSize: "13px",
        overflow: "hidden",
        marginTop: "10px",
        overflowWrap: "break-word",
    }

    const contSignsStyle = {
        border: "1px solid #555",
        borderRadius: "8px",
        margin: "10px auto 0 auto",
        boxSizing: "border-box",
        textAlign: "center",
        width: "200px",
        height: "200px",
        overflow: "hidden",
        padding: "5px 0",
    }

    const hideQrCodeStyle = {
        borderRadius: "8px",
        margin: "10px auto 0 auto",
        boxSizing: "border-box",
        textAlign: "center",
        width: "160px",
        height: "160px",
        overflow: "hidden",
        padding: "5px 0",
    }

    const footerTableTdStyle = {
        verticalAlign: "bottom",
        maxWidth:"300px",
    }

    const perVatTotalsContainerStyle = {
        border: "1px solid #555",
        borderRadius: "8px",
        overflow: "hidden",
        overflowWrap: "anywhere",
        marginTop: "10px",
        padding: "5px"
    }

    const totalsPerVatThStyle = {
        width: "10%",
        color: "#555",
        height: "5%",
        fontWeight: "bold",
        lineHeight: 1,
    }

    const countTotalsContainerStyle = {
        padding: "4px",
        border: "4px solid #555",
        borderRadius: "8px",
        minHeight: "16px",
        // maxHeight: "16px",
        overflow: "hidden",
        marginBottom: "4px"
    }

    const countTotalProdsStyle = {
        fontSize: "16px",
        fontWeight: "bold",
    }

    const finalPricePStyle = {
        fontWeight: "bold",
        fontSize: "16px",
        padding: "4px",
        margin: "4px",
    }

    const informationTdLeftStyle = {
        fontSize: "16px",
        border: 'none',
        padding: "0 0px",
    }

    const informationLeftStyle = {
        border: "1px solid #555",
        height: "180px",
        width: "395px",
        borderRadius: "4px",
        overflow: "hidden",
        padding: "1px",
    }

    const sellerDetailsStyle = {
        color: "#555",
        fontWeight: "bold",
        fontSize: "16px",
        textDecoration: "underline",
        textAlign: "center",
    }

    const totalBoxTd2 = {
        textAlign: "right",
        marginRright: "20px",
        backgroundColor: "white",
    }

    const totalsValueStyle = {
        padding: "4px",
        margin: "4px",
        fontSize: "16px",
        textAlign: "right"
    }

    const invoiceTableValues = {
        fontSize: "16px",
    }

    const descriptionValues = {
        fontSize: "16px",
    }

    const senderSignStyle = {
        left: "0px"
    }
    const pageBreak = {
        marginBottom: "260px"
    }
    const marginzero = {
        margin: "0"
    }

    const totalPagesA = templateData.TOTAL_PAGES !== undefined ? templateData.TOTAL_PAGES : [];
    const refHeight = useRef(null);
    return (
        <React.Fragment>
            <div style={marginzero} ref={ref}>
                { totalPagesA.length > 0 && totalPagesA.map((currentPage1,index) => (
                    <>
                        <div style={pageStyle} id={"page" + (index + 1)} className="buy-print-template">
                            <div style={invoiceBoxStyle}>
                                {showWaterMark && <div style={waterMarkStyle}>ΑΚΥΡΟ</div>}
                                <div className="top_table">
                                    <span>Page: {index + 1} of {totalPagesA.length}</span>
                                    <table style={{ width: "100%", textAlign: "left" }}>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <table style={headerTableStyle}>
                                                    <tbody>
                                                    <tr>
                                                        <td style={logoContainerStyle}>
                                                            {templateData.ISSUER_LOGO && templateData.ISSUER_LOGO !== "" && <img alt="Company_Logo" src={templateData.ISSUER_LOGO}></img>}
                                                        </td>
                                                        {templateData.BRANCH === "" &&
                                                            <td style={issuerContainerStyle}>
                                                                {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span style={IssuerName}>{templateData.ISSUER_NAME}</span>}
                                                                {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField} >{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                                {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField}>{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                                {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle} >{templateData.ISSUER_ADDRESS}, {templateData.ISSUER_CITY}, {templateData.ISSUER_TK}, PHONE: {templateData.ISSUER_PHONE}, DOY: {templateData.ISSUER_DOY}</span></p>}
                                                                {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>VAT NUMBER: {templateData.ISSUER_VAT}, GEMH: {templateData.ISSUER_GEMH}, EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            </td>
                                                        }
                                                        {templateData.BRANCH !== "" &&
                                                            <td style={issuerContainerStyle}>
                                                                {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span style={IssuerName}>{templateData.ISSUER_NAME}</span>}
                                                                {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField} >{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                                {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p style={issuerContainerPStyle}><span style={issuerSubjectField}>{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                                {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle} >Branch: {templateData.BRANCH?.address}, {templateData.BRANCH?.city}, {templateData.BRANCH?.postalCode}</span></p>}
                                                                {templateData.ISSUER_PHONE && templateData.ISSUER_PHONE !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>Phone: {templateData.BRANCH?.phone}</span></p>}
                                                                {templateData.ISSUER_DOY && templateData.ISSUER_DOY !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>DOY: {templateData.BRANCH?.doy}</span></p>}
                                                                {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>VAT Number: {templateData.ISSUER_VAT}</span></p>}
                                                                {templateData.ISSUER_GEMH && templateData.ISSUER_GEMH !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>GEMH: {templateData.ISSUER_GEMH}</span></p>}
                                                                {templateData.ISSUER_EMAIL && templateData.ISSUER_EMAIL !== "" && <p style={issuerContainerPStyle}><span style={issuerAddressStyle}>EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            </td>
                                                        }
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div style={mainInfoStyle}>
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                        <tr style={headingStyle}>
                                                            <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>INVOICE_TYPE</p></td>
                                                            <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>SERIES</p></td>
                                                            <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>NUMBER</p></td>
                                                            <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>DATE</p></td>
                                                            <td style={headingTdStyle}><p style={mainInfoTableHeadingPStyle}>TIME</p></td>
                                                        </tr>
                                                        <tr style={mainInfoTableTr}>
                                                            <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.INVOICE_TYPE ? templateData.INVOICE_TYPE : '-'}</span></td>
                                                            <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.SERIES ? templateData.SERIES : '-'}</span></td>
                                                            <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.NUMBER ? templateData.NUMBER : '-'}</span></td>
                                                            <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.INVOICE_DATE ? templateData.INVOICE_DATE : '-'}</span></td>
                                                            <td style={invoiceBoxTableTdStyle}><span style={invoiceTableValues}>{templateData.INVOICE_TIME ? templateData.INVOICE_TIME : '-'}</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <table style={informationTableStyle} className="information_table">
                                        <tbody>
                                        <tr>
                                            <td style={informationTdLeftStyle}>
                                                <div style={informationLeftStyle} className="changeWidth">
                                                    <table style={{width: "100%"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan="2" style={customerInformationStyle} >
                                                                <span>SUPPLIER DETAILS</span>
                                                            </td>
                                                        </tr>
                                                        {
                                                            templateData.SUPPLIER_CODE !== '' && templateData.SUPPLIER_CODE &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle}>
                                                                    <span style={descriptionValues}>CODE:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SUPPLIER_CODE}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SUPPLIER_NAME !== '' && templateData.SUPPLIER_NAME &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle}>
                                                                    <span style={descriptionValues}>NAME:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SUPPLIER_NAME}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SUPPLIER_COMPANY !== '' && templateData.SUPPLIER_COMPANY &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle}>
                                                                    <span style={descriptionValues}>COMPANY:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SUPPLIER_COMPANY}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SUPPLIER_ACTIVITY !== '' && templateData.SUPPLIER_ACTIVITY &&
                                                            <tr>
                                                                <td style={skinBoldStyle}><span style={descriptionValues}>ACTIVITY:</span></td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SUPPLIER_ACTIVITY}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SUPPLIER_EMAIL !== '' && templateData.SUPPLIER_EMAIL &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>EMAIL:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SUPPLIER_EMAIL}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SUPPLIER_PHONE !== '' && templateData.SUPPLIER_PHONE &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>PHONE:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SUPPLIER_PHONE}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SUPPLIER_VAT !== '' && templateData.SUPPLIER_VAT &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>VAT Number:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SUPPLIER_VAT}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SUPPLIER_DOY !== '' && templateData.SUPPLIER_DOY &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>DOY:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SUPPLIER_DOY}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SUPPLIER_ADDRESS !== '' && templateData.SUPPLIER_ADDRESS &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>ADDRESS:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SUPPLIER_ADDRESS}, </span>
                                                                    {templateData.SUPPLIER_CITY !== '' && templateData.SUPPLIER_CITY &&
                                                                        <span style={descriptionValues}>{templateData.SUPPLIER_CITY}, </span>
                                                                    }
                                                                    {templateData.SUPPLIER_ZIP !== '' && templateData.SUPPLIER_ZIP &&
                                                                        <span style={descriptionValues}>{templateData.SUPPLIER_ZIP}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                            <td className="d-none" style={informationTdLeftStyle}>
                                                <div style={informationLeftStyle} className="changeWidth">
                                                    <table style={{width: "100%"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan="2" style={sellerDetailsStyle}>
                                                                <span>SELLER DETAILS</span>
                                                            </td>
                                                        </tr>
                                                        {
                                                            templateData.SALER_NAME !== '' && templateData.SALER_NAME &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>NAME:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SALER_NAME}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SALER_COMPANY_NAME !== '' && templateData.SALER_COMPANY_NAME &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>COMPANY NAME:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SALER_COMPANY_NAME}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SALER_EMAIL !== '' && templateData.SALER_EMAIL &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>EMAIL:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SALER_EMAIL}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SALER_GEMH !== '' && templateData.SALER_GEMH &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>GEMH:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SALER_GEMH}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SALER_VAT !== '' && templateData.SALER_VAT &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>VAT Number:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.SALER_VAT}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.TAX_REPRESENTATIVE !== '' && templateData.TAX_REPRESENTATIVE &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>TAX REPRESENTATIVE:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.TAX_REPRESENTATIVE}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.TAX_REPRESENTATIVE_VAT !== '' && templateData.TAX_REPRESENTATIVE_VAT &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>TAX REPRESENTATIVE VAT Number:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.TAX_REPRESENTATIVE_VAT}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                            <td style={informationTdLeftStyle} className="information_td_left">
                                                <div style={informationLeftStyle} className="changeWidth">
                                                    <table style={{width: "100%"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan="2" style={customerInformationStyle}>
                                                                <span>EXTRA DETAILS</span>
                                                            </td>
                                                        </tr>
                                                        {templateData.LOCATION_NAME !== '' && templateData.LOCATION_NAME &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>DELIVERY NAME:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.LOCATION_NAME}</span>
                                                                </td>
                                                            </tr>}
                                                        {
                                                            templateData.LOCATION_ADDRESS !== '' && templateData.LOCATION_ADDRESS &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>DELIVERY ADDRESS:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.LOCATION_ADDRESS}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.LOCATION_CITY !== '' && templateData.LOCATION_CITY &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>DELIVERY CITY:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.LOCATION_CITY}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.LOCATION_ZIP !== '' && templateData.LOCATION_ZIP &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>DELIVERY ZIP:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.LOCATION_ZIP}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.PAYMENT_METHOD !== '' && templateData.PAYMENT_METHOD &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>PAYMENT METHOD:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.PAYMENT_METHOD}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.PURPOSE !== '' && templateData.PURPOSE &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>PURPOSE:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.PURPOSE}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.HIGHLIGHT !== '' && templateData.HIGHLIGHT &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>HIGHLIGHT:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.HIGHLIGHT}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.CORRELATION !== '' && templateData.CORRELATION &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>CORRELATIONS:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.CORRELATION}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.DISPATCH_TIME !== '' && templateData.DISPATCH_TIME &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>DISPATCH TIME:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.DISPATCH_TIME}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.VEHICLE_NUMBER !== '' && templateData.VEHICLE_NUMBER &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>VEHICLE NUMBER:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.VEHICLE_NUMBER}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style={index + 1 !== totalPagesA.length ? productContainerStyleLastPage : productContainerStyle}>
                                    <table style={{width: "100%"}} ref={refHeight}>
                                        <tbody>
                                        <tr style={productTableHeadingStyle} key={Math.random()}>
                                            <td style={quantityHeadTd}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> CODE</p></td>
                                            <td style={descriptionHeadTd}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> DESCRIPTION</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> QTY</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> QTY PRICE</p></td>
                                            <td style={vatPerHeadTdStyle}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> VAT %</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> DISCOUNT</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> NET VALUE</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> VAT VALUE</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> OTHER TAXES</p></td>
                                            <td style={priceHeadTdStyle}><p style={{fontSize: "14px", lineHeight: "1", margin: "10px 0",}}> TOTAL VALUE</p></td>
                                        </tr>
                                        {templateData.PRODUCT_DETAILS && templateData.PRODUCT_DETAILS[index] && templateData.PRODUCT_DETAILS.map((product) =>
                                            (
                                                <tr style={productsStyle} key={Math.random()}>
                                                    <td style={productsStyleTdStyle}><span>{product.CODE}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.PRODUCT_DESCRIPTION}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.QTY}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.PRICE_QTY}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.VAT}%</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.DISCOUNT}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.PRICE_NOVAT}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.VAT_PRICE}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.OTHER_TAXES}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.TOTAL}</span></td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={footerContainerStyle}>
                                    <table style={{width: "100%"}}>
                                        <tbody>
                                        <tr>
                                            <td style={footerTableTdStyle}>
                                                <div style={perVatTotalsContainerStyle}>
                                                    <table style={{width: "100%"}}>
                                                        <tbody>
                                                        <tr>
                                                            <th style={totalsPerVatThStyle}>VAT Percentage</th>
                                                            <th style={totalsPerVatThStyle}>Net Value</th>
                                                            <th style={totalsPerVatThStyle}>Vat Value</th>
                                                            <th style={totalsPerVatThStyle}>Other Taxes</th>
                                                            <th style={totalsPerVatThStyle}>Total Value</th>
                                                        </tr>
                                                        {templateData.VAT_TABLE && index + 1 === totalPagesA.length && templateData.VAT_TABLE?.length > 0 && templateData.VAT_TABLE.map((taxes) => {
                                                            return <tr key={Math.random()}>
                                                                <td>{taxes.TAX_PERCENT} {taxes.TAX_CODE ? `(${taxes.TAX_CODE})` : null}</td>
                                                                <td>{taxes.NET_VALUE}</td>
                                                                <td>{taxes.TAX_VALUE}</td>
                                                                <td>{taxes.OTHER_TAXES}</td>
                                                                <td>{taxes.TOTAL_VALUE}</td>
                                                            </tr>
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div style={contNotationStyle} >
                                                    {templateData.VATEXPCAT && templateData.VATEXPCAT?.length > 0 && templateData.VATEXPCAT.map((reason) => {
                                                        return (
                                                            <div className="exception-block">
                                                                <span style={skinBoldStyle}>VAT EXCEPTION :</span>
                                                                <div className="cont_notation_inner">
                                                                    <span>{reason.vat} {reason.description} <br /></span>
                                                                </div>
                                                            </div>
                                                        )})}
                                                    <div className="notes-block">
                                                        <span style={skinBoldStyle}>INVOICE NOTES:</span>
                                                        <div className="cont_notation_inner">
                                                            <span>{templateData?.INVOICE_NOTES}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={footerTableTdStyle}>
                                                { index + 1 === totalPagesA.length && templateData?.UID !== "" && templateData?.AUTHCODE !== undefined &&
                                                    <div style={contSignsStyle}>
                                                        <img src={templateData?.QR_CODE} alt="Qr code" />
                                                    </div>
                                                }
                                                { templateData?.UID !== "" && templateData?.AUTHCODE === undefined &&
                                                    <div style={hideQrCodeStyle}></div>
                                                }
                                            </td>
                                            <td style={totalTdBlock}>
                                                <div style={countTotalsContainerStyle}>
                                                    <span>TOTAL QTY: </span>
                                                    {index + 1 === totalPagesA.length && (
                                                        <span style={countTotalProdsStyle}> {templateData.TOTAL_QTY ? templateData.TOTAL_QTY : '0'}</span>
                                                    )}
                                                </div>
                                                <div style={totalContainerStyle}>
                                                    <div style={totalContainerTotalsStyle}>
                                                        <table style={totalTdBlockTotalsTable}>
                                                            <tbody>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>NET VALUE BEFORE DISCOUNT</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <p style={{fontSize: "14px"}}><span style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_WITHOUT_DISCOUNT ? templateData.TOTAL_WITHOUT_DISCOUNT : ""}</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>DISCOUNT</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <p style={{fontSize: "14px"}}><span style={totalsValueStyle}>{ index + 1 === totalPagesA.length && templateData.TOTAL_DISCOUNT ? templateData.TOTAL_DISCOUNT : ""}</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>NET VALUE</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <p style={{fontSize: "14px"}}><span style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_NOVAT ? templateData.TOTAL_NOVAT : ""}</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>VAT VALUE</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <p style={{fontSize: "14px"}}><span style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_VAT ? templateData.TOTAL_VAT : ""}</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>TOTAL VALUE</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <p style={{fontSize: "14px"}}><span style={priceWithVatStyle}>{index + 1 === totalPagesA.length && templateData.PRICE_WITHVAT ? templateData.PRICE_WITHVAT : ""}</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr style={blankRowBorderedTdStyle}>
                                                                <td style={totalContainerHeadingStyle}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={finalPricePStyle}>PAYMENT AMOUNT</p>
                                                                </td>
                                                                <td style={totalPaymentStyleTd}>
                                                                    <p style={totalPaymentPStyle}>
                                                                        <span style={totalPaymentStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_PRICE ? templateData.TOTAL_PRICE : ""}</span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    {((templateData.TRANSMISSION_FAILURE && (templateData.TRANSMISSION_FAILURE === "1" || templateData.TRANSMISSION_FAILURE === "2") && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                        <div>
                                            <span style={senderSignStyle}>www.primer.gr/search-invoice Provided by Primer Software P.C.</span>
                                            <br/>
                                            <span>Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer MyData_V1_21012021</span>
                                        </div>
                                    }
                                    {index + 1 === totalPagesA.length && (
                                        <div>
                                            {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                                <span>Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1<br/></span>}
                                            {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                                <span>Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2<br/></span>}
                                            {templateData.UID && templateData.UID !== "" && templateData.AUTHCODE !== "" &&
                                                <span> UID: {templateData.UID}</span>}
                                            {templateData.MARK && templateData.MARK !== "" && <span> MARK: {templateData.MARK}</span>}
                                            {templateData.AUTHCODE && templateData.AUTHCODE !== "" &&
                                                <span> <br/>AUTHENTICATION CODE: {templateData.AUTHCODE}</span>}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {(index + 1 !== totalPagesA.length &&  totalPagesA.length !== 1 ) && templateData.PRINT_SIZE === "A4" &&
                            <div style={(index + 1 !== totalPagesA.length &&  totalPagesA.length !== 1 ) ? pageBreak : null}></div>
                        }
                    </>
                ))}
            </div>
        </React.Fragment>
    );
});

export default BuyPrintA4English;
