import React, { useState } from 'react';
import {Button, Spinner} from "react-bootstrap";

const CameraAccess = ({accessGrantedFunction}) => {
    const [hasPermission, setHasPermission] = useState(false);
    const [error, setError] = useState(null);
    const [loadingCamera, setLoadingCamera] = useState(false);

    const requestCameraPermission = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" } } });
            setHasPermission(true);
            mediaStream.getTracks().forEach((track) => {
                track.stop();
            });
            if (typeof accessGrantedFunction === "function") accessGrantedFunction(true);
            setLoadingCamera(false);
        } catch (err) {
            setError('Camera permission denied or no camera available');
            console.error(err);
            setLoadingCamera(false);
        }
    }

    return (
        <div style={{textAlign: "center"}}>
            <h1>Πρόσβαση στην κάμερα</h1>
            {!hasPermission ? (
                <Button className={"mt-2"} size={"sm"} onClick={() => requestCameraPermission()} disabled={loadingCamera}>
                    Ενεργοποίηση κάμερας {loadingCamera && <Spinner className={"ml-2"} animation="border" variant="dark"/>}
                </Button>
            ) : (
                <p>Camera access granted</p>
            )}
            {error && <p style={{ color: 'red' }}>Σφάλμα: {error}</p>}
        </div>
    )
}

export default CameraAccess;
