import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import getMyData, {
    getICCategoryOptions,
    getICTypeOptions,
    getInvoiceTypesOptions,
    getMyDataRowTypes,
    getVatExpenseClassificationType
} from "../../../../_helpers/sharedmydata";
import {toast} from "react-toastify";
import {
    addProductEditAllVariants,
    addProductEditVariant,
    resetNewVariantProps,
    resetTempEditProductProperties,
    setAllCheckedEditVariants,
    setEditProductCode,
    setEditProductCodes,
    setEditProductDisplayedVariants,
    setEditProductsMyData,
    setEditSelectedVariantsRows,
    setEditVariantIds,
    setEditVariantSearchTerm,
    setNewVariant,
    setProductsSelectedCategoryData,
    setSelectedEditProduct,
    setTempEditProductProperties,
    updateProductEditVariants
} from "../../../../_reducers/DataPanel/Products/productsSlice";
import {
    deleteObjectFromArrayOfObjects,
    findObjectFromArrayOfObjects,
    getLabelByValue,
    getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import {
    checkPermission,
    getDefaultOptionByValue,
    getGeneratedMaskCode,
    getMaskCodePrefix,
    getMaskCodeWithNewPrefix,
    getOptionsByData,
    isEmpty,
    isValidNumber,
    productCategoryOptions,
    removeEmptyValues,
    vatIncludedOptions
} from "../../../../_helpers/commonFunctions";
import {Button, Col, FormLabel, Modal, Row} from "react-bootstrap";
import Input from "../../../common/Input";
import LogTable from "../../../primer/LogTable";
import BetterCheckBox from "../../../common/BetterCheckBox";
import Dropdown from "../../../primer/Dropdown";
import AccountingBox from "../../../primer/AccountingBox";
import EditableTable from "../../../primer/Editable-Table";
import TextArea from "../../../common/TextArea";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {setSaveEditProduct} from "../../../../_reducers/activitySlice";
import Checkbox from "../../../common/CheckBox";
import ModalInstructions from "../../../primer/POPUP-COMPONENTS/Instructions";

export const ProductsEditGeneral = () => {
    const [t] = useTranslation("common");
    const invoiceTypes = getInvoiceTypesOptions("all");
    const icCategory = getICCategoryOptions();
    const icType = getICTypeOptions();
    const myDataRowTypes = getMyDataRowTypes();
    const dispatch = useDispatch();
    const vatExpenseTypes = getVatExpenseClassificationType();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
    const PRODUCTS_CATEGORY = useSelector((state) => state.PRODUCTS_CATEGORY.categories);
    const vatTaxes = getMyData({type: 'vat'});
    const SETTINGS = useSelector(state => state.SETTINGS);
    const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
    const measurementUnitOptions = getMyData({type: 'mu', settings: SETTINGS});
    const [showCategoryConfirmation, setShowCategoryConfirmation] = useState(false);
    const [tempCatValue, setTempCatValue] = useState({});
    const permissionData = JSON.parse(localStorage.getItem("permissions"));

    // For editable table re-rendering
    const [incrKey, setIncrKey] = useState(0);
    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [PRODUCTS_DATA.selectedEditProduct.mydata])

    const inventoryInstallationsColumns = [
        {name: "No", width: "10%", field: "no", editable: false},
        {
            name: t('ProductsNewGeneralEdit.table4.type'),
            field: "type",
            editable: false,
            width: "30%",
            inputType: {type: "dropdown", config: {searchEnable: true}}
        },
        {
            name: t('ProductsNewGeneralEdit.table4.address'),
            field: "address",
            editable: false,
            width: "30%",
            inputType: {type: "text"}
        },
        {
            name: t('ProductsNewGeneralEdit.table4.inventory'),
            field: "inventory",
            editable: false,
            width: "20%",
            inputType: {type: "number", config: {searchEnable: true}}
        },
    ];
    const productsColumns = [
        {
            name: t('ProductsNewGeneral.table.rowType'),
            field: "rowType",
            width: "10%",
            editable: true,
            inputType: {type: 'select', options: myDataRowTypes}
        },
        {
            name: t('ProductsNewGeneralEdit.table.invoiceType'),
            field: "invoiceType",
            editable: true,
            width: "30%",
            inputType: {type: 'select', multiselect: true, options: invoiceTypes}
        },
        {
            name: t('ProductsNewGeneralEdit.table.category'),
            field: "category",
            editable: true,
            width: "20%",
            inputType: {type: 'select', options: icCategory}
        },
        {
            name: t('ProductsNewGeneralEdit.table.type'),
            field: 'type',
            editable: true,
            width: "20%",
            inputType: {type: 'select', options: icType}
        },
        {
            name: t('ProductsNewGeneral.table.vatExpenseType'),
            field: 'vatExpenseType',
            editable: true,
            width: "20%",
            inputType: {type: 'select', options: vatExpenseTypes}
        }
    ];

    const propertiesColumns = [
        {
            name: 'Όνομα Ιδιότητας',
            field: 'name',
            editable: true,
            width: "20%",
            inputType: {
                type: 'text',
            }
        },
        {
            name: 'Τιμές Ιδιότητας',
            field: 'values',
            editable: true,
            width: "80%",
            inputType: {
                type: 'text',
            }
        },
    ];

    const propertiesInstructions = {
        title: "Οδηγίες Εισαγωγής Ιδιοτήτων",
        content: "<div>Εισάγετε ιδιότητες ειδών χωρίζοντάς τις τιμές με κόμμα(,).<br><br>Καθορίστε αυξομειώσεις τιμής σε συγκεκριμένες παραλλαγές με ανω-κάτω τελεία(:)<br><br><u>Παράδειγμα</u><br>Όνομα Ιδιότητας: Χρώμα<br>Τιμές Ιδιότητας: μαύρο,κόκκινο:3,κίτρινο:-3,μπλε</div>"
    };

    const setSelectedCategoryData = (categoryId) => {
        const selected = getObjectFromArrayOfObjects(PRODUCTS_CATEGORY, categoryId, '_id')['0'];
        dispatch(setProductsSelectedCategoryData(selected));
        // Update the Taxes to current product.
        const categoryTaxesData = selected.taxes && (selected.taxes !== 'undefined') ? JSON.parse(selected.taxes) : '';
        const taxesData = {
            category: categoryId ? categoryId : '',
            categoryMaskCodePrefix: selected.maskCodePrefix ? selected.maskCodePrefix : 'PC',
            vat: categoryTaxesData.vat ? categoryTaxesData.vat : '',
            vatReason: categoryTaxesData.vatreason ? categoryTaxesData.vatreason : '',
            otherTaxes: categoryTaxesData.othertaxes ? categoryTaxesData.othertaxes : '',
            withholdTax: categoryTaxesData.witholdtaxes ? categoryTaxesData.witholdtaxes : '',
            stamp: categoryTaxesData.stamp ? categoryTaxesData.stamp : '',
            fees: categoryTaxesData.fees ? categoryTaxesData.fees : '',
            accountingCode: selected.accountingCode ? selected.accountingCode : '',
            netAmountAccountingCode: selected.netAmountAccountingCode ? selected.netAmountAccountingCode : '',
            vatAccountingCode: selected.vatAccountingCode ? selected.vatAccountingCode : '',
            taxAccountCode: selected.taxAccountCode ? selected.taxAccountCode : '',
            accountingNonDeductibleVAT: selected.accountingNonDeductibleVAT ? selected.accountingNonDeductibleVAT : '',
        }

        // Update my data to current product
        let combinedData = {...taxesData}
        if (selected.mydata && selected.mydata.length > 0) {
            const categoryMyData = selected.mydata && selected.mydata !== [] ? JSON.parse(selected.mydata) : {};
            const myData = {mydata: categoryMyData}

            combinedData = {...myData, ...taxesData}
        }
        let tempProductCode = getGeneratedMaskCode('product', categoryId, PRODUCTS_CATEGORY)
        combinedData.categoryMaskCodePrefix = getMaskCodePrefix(selected.maskCode);
        combinedData.code = getMaskCodeWithNewPrefix(tempProductCode, combinedData.categoryMaskCodePrefix); // Set Product Code
        dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, ...combinedData}));
    }

    const handleConfirmCategory = () => {
        setSelectedCategoryData(tempCatValue.new);
        setShowCategoryConfirmation(false);
    }

    const propertiesActions = {

        insert: (e, data) => {
            if (isEmpty(data.value)) return;

            let tempEditProperty = structuredClone(PRODUCTS_DATA.tempEditProductProperties);
            if (data.name) data.name = data.name.trim();
            if(data.values) data.values = data.values.replace(/\s+/g, '');
            tempEditProperty[data.field] = data.value;
            if (isEmpty(tempEditProperty.name) || isEmpty(tempEditProperty.values)) {
                dispatch(setTempEditProductProperties(tempEditProperty));
            } else {
                let productProperties = Object.assign([], PRODUCTS_DATA.selectedEditProduct.productProperties);
                if(productProperties.filter((prop) => prop.name === tempEditProperty.name).length !== 0) return toast.error("Διπλότυπη Ιδιότητα.");
                productProperties.push(tempEditProperty);
                dispatch(resetTempEditProductProperties());
                dispatch(resetNewVariantProps());
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, productProperties: productProperties}));
                if(!ACTIVITY.saveEditProduct) dispatch(setSaveEditProduct(true));
            }
        },
        update: (data, id, rowIndex) => {
            if(!ACTIVITY.saveEditProduct) dispatch(setSaveEditProduct(true));
            let productProperties = structuredClone(PRODUCTS_DATA.selectedEditProduct.productProperties);
            if (data.name) data.name = data.name.trim();
            if (data.values) data.values = data.values.replace(/\s+/g, '');
            if (productProperties.filter((prop, index) => index !== rowIndex && prop.name === data.name).length !== 0) return toast.error("Διπλότυπη Ιδιότητα.");
            productProperties[rowIndex] = {...productProperties[rowIndex], ...data};
            dispatch(resetNewVariantProps());
            dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, productProperties: productProperties}));
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            if (!ACTIVITY.saveEditProduct) dispatch(setSaveEditProduct(true));
            let productProperties = structuredClone(PRODUCTS_DATA.selectedEditProduct.productProperties);
            const deleted = productProperties.splice(rowIndex, 1);

            let canBeDeleted = true;
            PRODUCTS_DATA.selectedEditProduct.variants.forEach(variant => {
                if (JSON.stringify(variant.properties).includes(deleted[0].name)) canBeDeleted= false;
            });
            if (!canBeDeleted) {
                toast.error("Η ιδιότητα δε μπορει να διαγραφεί καθώς υπάρχει σε παραλλαγή του είδους.");
                return;
            }
            dispatch(resetNewVariantProps());
            dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, productProperties: productProperties}));
        }
    }

    const parentMydataActions = {
        insert: (e, data) => {
            if(!ACTIVITY.saveEditProduct) {
                dispatch(setSaveEditProduct(true));
            }
            let tempPcMyData = Object.assign({}, PRODUCTS_DATA.editProductsMyData);
            if (e.key !== "Enter") {
                tempPcMyData[data.field] = data.value;
                dispatch(setEditProductsMyData(tempPcMyData)); // Update redux my data values
            }
            if (tempPcMyData.rowType !== '' || tempPcMyData.invoiceType !== '' || tempPcMyData.category !== '' || tempPcMyData.type !== '') {
                let updateNewPcMydata = Object.assign({}, tempPcMyData);

                updateNewPcMydata.id = PRODUCTS_DATA.selectedEditProduct.mydata.length + 1;
                const tempMydata = [...PRODUCTS_DATA.selectedEditProduct.mydata, updateNewPcMydata];
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, ...{mydata: tempMydata}}));  // update products mydata
                dispatch(setEditProductsMyData(PRODUCTS_DATA.newProductsMyDataInitialValue)); // Reset new category mydata
            }

            // Add on press enter
            if (e.key === "Enter" && data.field !== 'add') {
                let updateNewPcMydata = Object.assign({}, PRODUCTS_DATA.editProductsMyData);
                updateNewPcMydata.id = PRODUCTS_DATA.selectedEditProduct.mydata.length + 1;
                const tempMydata = [...PRODUCTS_DATA.selectedEditProduct.mydata, updateNewPcMydata];
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, ...{mydata: tempMydata}}));  // update products mydata
                dispatch(setEditProductsMyData(PRODUCTS_DATA.newProductsMyDataInitialValue)); // Reset new category mydata
            }
            // Fix allow empty row insert on check box click
            if (e.key === "Enter" && data.field === 'add') {
                let updateNewPcMydata = Object.assign({}, PRODUCTS_DATA.editProductsMyData);
                updateNewPcMydata.id = PRODUCTS_DATA.selectedEditProduct.mydata.length + 1;
                updateNewPcMydata.rowType = "";
                updateNewPcMydata.invoiceType = "";
                updateNewPcMydata.category = "";
                updateNewPcMydata.type = "";
                updateNewPcMydata.vatExpenseType = "";
                const tempMydata = [...PRODUCTS_DATA.selectedEditProduct.mydata, updateNewPcMydata];
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, ...{mydata: tempMydata}}));  // update products mydata
                dispatch(setEditProductsMyData(PRODUCTS_DATA.newProductsMyDataInitialValue)); // Reset new category mydata
            }
        },
        update: (data, id, rowIndex) => {
            if(!ACTIVITY.saveEditProduct) dispatch(setSaveEditProduct(true));
            let cloneMyData = structuredClone(PRODUCTS_DATA.selectedEditProduct.mydata);
            cloneMyData[rowIndex] = {...cloneMyData[rowIndex], ...data}
            dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, mydata: cloneMyData}));  // update products mydata
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            if(!ACTIVITY.saveEditProduct) dispatch(setSaveEditProduct(true));
            let clonedMyData = structuredClone(PRODUCTS_DATA.selectedEditProduct.mydata);
            clonedMyData.splice(rowIndex, 1);
            dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, mydata: clonedMyData}));
        },
    }

    const handleOnChange = (e, type, compName) => {
        if(!ACTIVITY.saveEditProduct) {
            dispatch(setSaveEditProduct(true));
        }
        if (type === "checkbox") {
            if (compName === "isActive") {
                const activity = e.target.checked === true ? "active" : "inActive";
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, isActive: activity}))
            } else if (compName === "showAlternativeNameOnPrint") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, showAlternativeNameOnPrint: String(e.target.checked)}))
            } else if (compName === "showAlternativeCodeOnPrint") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, showAlternativeCodeOnPrint: String(e.target.checked)}))
            }
        } else if (type === "dropdown") {
            if (compName === "category") {
                setIncrKey(incrKey + 1);
                setShowCategoryConfirmation(true);
                setTempCatValue({new: e.value, old: PRODUCTS_DATA.selectedEditProduct.category});
            } else if (compName === "vat") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, vat: e.value}))
            } else if (compName === "vatReason") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, vatReason: e.value}))
            } else if (compName === "measurementUnit") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, measurementUnit: e.value}))
            } else if (compName === "vatIncluded") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, vatIncluded: String(e.value)}))
            } else if (compName === "vatIncludedBuy") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, vatIncludedBuy: String(e.value)}))
            }
        } else if (type === "input") {
            if (compName === "name") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, name: e.target.value}))
            } else if (compName === "alternateName") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, alternateName: e.target.value}))
            } else if (compName === "alternateCode") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, alternateCode: e.target.value}))
            } else if (compName === "barcode") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, barcode: e.target.value}))
            } else if (compName === "price") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, price: e.target.value}))
            } else if (compName === "buyPrice") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, buyPrice: e.target.value}))
            } else if (compName === "cpvCode") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, cpvCode: e.target.value}))
            }
        } else if (type === "textarea") {
            if(compName === "notes") {
                dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, notes: e.target.value}))
            }
        }
    }

    const isEmpty = (o) => {
        return o === null || o === undefined || String(o).trim() === ""
    }

    return (
        <React.Fragment>
            <Row className="position-relative border-bottom mb-3">
                <Col xs="12" md="8"></Col>
                <Col xs="12" md="2">
                    {PRODUCTS_DATA.selectedEditProduct.measurementUnit !== "4" && PRODUCTS_DATA.selectedEditProduct.isTaxLine !== "true" && (
                        <div className="d-flex justify-content-end mb-3">
                            <Input
                                name="productInventoryBalance"
                                label={t('ProductsNewGeneralEdit.invetoryBalance')}
                                value={PRODUCTS_DATA.selectedEditProduct.inventoryBalance ? PRODUCTS_DATA.selectedEditProduct.inventoryBalance : 0}
                                disabled={true}
                            />
                        </div>
                    )}
                </Col>
                <Col xs="12" md="2">
                    <div className="d-flex justify-content-end mb-3 mt-4">
                        <label htmlFor="product-active"
                               className="mb-0 mr-2">{t('ProductsNewGeneralEdit.productActive')}</label>
                        <BetterCheckBox
                            className="mb-2"
                            name="isActive"
                            key={Math.random()}
                            checked={PRODUCTS_DATA.selectedEditProduct.isActive === "active"}
                            onChange={(e) => handleOnChange(e, "checkbox", "isActive")}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="4">
                    <Dropdown
                        name={"category"}
                        label={t('ProductsNewGeneralEdit.productCategory.productCategory')}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(productCategoryOptions(PRODUCTS_CATEGORY), PRODUCTS_DATA.selectedEditProduct.category, 'value', 'label', 'value')}
                        options={productCategoryOptions(PRODUCTS_CATEGORY)}
                        onChange={(e) => handleOnChange(e, "dropdown", "category")}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs="12" md="6">
                    <Input
                        name="name"
                        charlimit={200}
                        required={true}
                        autoComplete={"off"}
                        label={t('ProductsNewGeneralEdit.productName.productName')}
                        placeholder={t('ProductsNewGeneralEdit.productName.placeholder')}
                        value={PRODUCTS_DATA.selectedEditProduct.name}
                        onChange={(e) => handleOnChange(e, "input", "name")}
                    />
                    {isEmpty(PRODUCTS_DATA.selectedEditProduct.name) &&
                        <span className="text-danger">{t('ProductsNewGeneral.productName.required')}</span>}
                </Col>
                <Col xs="12" md="4">
                    <Input
                        name="alternateName"
                        autoComplete={"off"}
                        label={t('ProductsNewGeneralEdit.alternativeName.alternativeName')}
                        placeholder={t('ProductsNewGeneralEdit.alternativeName.placeholder')}
                        value={PRODUCTS_DATA.selectedEditProduct.alternateName}
                        onChange={(e) => handleOnChange(e, "input", "alternateName")}
                    />
                </Col>
                <Col md={2}>
                    <BetterCheckBox
                        className={"mt-3"}
                        name={"showAlternativeNameOnPrint"}
                        text={"Προβολή εναλλακτικού ονόματος στα παραστατικά"}
                        checked={PRODUCTS_DATA.selectedEditProduct?.showAlternativeNameOnPrint === "true"}
                        onChange={(e) => handleOnChange(e, "checkbox", "showAlternativeNameOnPrint")}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs="12" md="3">
                    <Input
                        name="code"
                        autoComplete={"off"}
                        label={t('ProductsNewGeneralEdit.productCode.productCode')}
                        placeholder={t('ProductsNewGeneralEdit.productCode.placeholder')}
                        disabled={true}
                        value={getMaskCodeWithNewPrefix(PRODUCTS_DATA.selectedEditProduct.code, PRODUCTS_DATA.selectedEditProduct.categoryMaskCodePrefix)}
                    />
                </Col>
                <Col xs="12" md="3">
                    <Input
                        name="alternateCode"
                        autoComplete={"off"}
                        label={t('ProductsNewGeneralEdit.alternativeCode.alternativeCode')}
                        placeholder={t('ProductsNewGeneralEdit.alternativeCode.placeholder')}
                        value={PRODUCTS_DATA.selectedEditProduct.alternateCode}
                        onChange={(e) => handleOnChange(e, "input", "alternateCode")}
                    />
                </Col>
                <Col md={2}>
                    <BetterCheckBox
                        className={"mt-3"}
                        name={"showAlternativeCodeOnPrint"}
                        text={"Προβολή εναλλακτικού κωδικού στα παραστατικά"}
                        checked={PRODUCTS_DATA.selectedEditProduct?.showAlternativeCodeOnPrint === "true"}
                        onChange={(e) => handleOnChange(e, "checkbox", "showAlternativeCodeOnPrint")}
                    />
                </Col>
                <Col xs="12" md="4">
                    <Input
                        name="barcode"
                        autoComplete={"off"}
                        label={"EAN Barcode"}
                        placeholder={"EAN Barcode"}
                        value={PRODUCTS_DATA.selectedEditProduct.barcode}
                        onChange={(e) => handleOnChange(e, "input", "barcode")}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs="12" md="4">
                    <Dropdown
                        name={"vat"}
                        required={true}
                        label={t('ProductsNewGeneralEdit.vat.vat')}
                        disabled={PRODUCTS_DATA.selectedEditProduct.isTaxLineWithoutVat === "true"}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(vatTaxes, PRODUCTS_DATA.selectedEditProduct.vat, 'value', 'label', 'value')}
                        options={vatTaxes}
                        onChange={(e) => handleOnChange(e, "dropdown", "vat")}
                        className={"mb-0"}
                    />
                    {isEmpty(PRODUCTS_DATA.selectedEditProduct.vat) &&
                        <span className="text-danger">{t('ProductsNewGeneral.vat.required')}</span>}
                </Col>
                {PRODUCTS_DATA.selectedEditProduct.vat === "0" && (
                    <Col xs="12" md="4">
                        <Dropdown
                            name={"vatReason"}
                            required={true}
                            label={t('ProductsNewGeneralEdit.0VatReason.0VatReason')}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(zeroVatReasonOptions, PRODUCTS_DATA.selectedEditProduct.vatReason, 'value', 'label', 'value')}
                            options={zeroVatReasonOptions}
                            onChange={(e) => handleOnChange(e, "dropdown", "vatReason")}
                            className={"mb-0"}
                        />
                        {isEmpty(PRODUCTS_DATA.selectedEditProduct.vatReason) &&
                            <span className="text-danger">{t('ProductsNewGeneral.0VatReason.required')}</span>}
                    </Col>
                )}
                {checkPermission("custom-b2g", permissionData) && (
                    <Col md={4}>
                        <Input
                            name={"cpvCode"}
                            label={"Κωδικός CPV"}
                            value={PRODUCTS_DATA.selectedEditProduct.cpvCode}
                            placeholder={"Προσθέστε Κωδικό CPV"}
                            onChange={(e) => handleOnChange(e, "input", "cpvCode")}
                        />
                    </Col>
                )}
            </Row>
            <Row>
                <Col xs="12" md="4">
                    <Dropdown
                        name={"measurementUnit"}
                        required={true}
                        label={t('ProductsNewGeneralEdit.measurementUnit.measurementUnit')}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(measurementUnitOptions, String(PRODUCTS_DATA.selectedEditProduct.measurementUnit), 'value', 'label', 'value')}
                        options={measurementUnitOptions}
                        onChange={(e) => handleOnChange(e, "dropdown", "measurementUnit")}
                        className={"mb-0"}
                    />
                    {isEmpty(PRODUCTS_DATA.selectedEditProduct.measurementUnit) &&
                        <span className="text-danger">{t("ProductsNewGeneral.measurementUnit.required")}</span>}
                </Col>
                <Col xs="12" md="2">
                    <label>{t('General.preferredSalePrice')}</label>
                    <AccountingBox
                        name={"price"}
                        placeholder={t('Placeholders.preferredSalePrice')}
                        value={PRODUCTS_DATA.selectedEditProduct.price}
                        defaultValue={PRODUCTS_DATA.selectedEditProduct.price}
                        onChange={(e) => handleOnChange(e, "input", "price")}
                    />
                </Col>
                <Col xs="12" md="2">
                    <Dropdown
                        name={"vatIncluded"}
                        label={t('ProductsNewGeneralEdit.vatIncluded')}
                        key={Math.random()}
                        defaultValue={vatIncludedOptions().find((el) => String(el.value) === String(PRODUCTS_DATA.selectedEditProduct.vatIncluded))}
                        options={vatIncludedOptions()}
                        onChange={(e) => handleOnChange(e, "dropdown", "vatIncluded")}
                        className={"mb-0"}
                    />
                </Col>
                {checkPermission("buys-buy/new-write", permissionData) && (
                    <React.Fragment>
                        <Col xs="12" md="2">
                            <label>{t('General.preferredBuyPrice')}</label>
                            <AccountingBox
                                name={"buyPrice"}
                                placeholder={t('Placeholders.preferredBuyPrice')}
                                value={PRODUCTS_DATA.selectedEditProduct.buyPrice}
                                defaultValue={PRODUCTS_DATA.selectedEditProduct.buyPrice}
                                onChange={(e) => handleOnChange(e, "input", "buyPrice")}
                            />
                        </Col>
                        <Col xs="12" md="2">
                            <Dropdown
                                name={"vatIncludedBuy"}
                                label={t('ProductsNewGeneralEdit.vatIncluded')}
                                key={Math.random()}
                                defaultValue={vatIncludedOptions().find((el) => String(el.value) === String(PRODUCTS_DATA.selectedEditProduct.vatIncludedBuy))}
                                options={vatIncludedOptions()}
                                onChange={(e) => handleOnChange(e, "dropdown", "vatIncludedBuy")}
                                className={"mb-0"}
                            />
                        </Col>
                    </React.Fragment>
                )}
            </Row>
            <hr/>
            <Row>
                <EditableTable
                    tableName="New Product My Data" // Do not change name
                    showErrors={PRODUCTS_DATA.selectedEditProduct.mydata && PRODUCTS_DATA.selectedEditProduct.mydata.length === 0}
                    showErrorMessage={t('ProductsNewGeneralEdit.errorMessage')}
                    key={"PEMD" + incrKey}
                    allowActions={true}
                    onUpdate={parentMydataActions}
                    heading="My Data"
                    allowInsertRow={true}
                    enableNewRowCheckbox={true}
                    columns={productsColumns}
                    data={PRODUCTS_DATA.selectedEditProduct.mydata}
                />
            </Row>
            {checkPermission("products-products/edit-variants-write", permissionData) && (
                <React.Fragment>
                    <hr/>
                    <Row>
                        <Col>
                            <div className="text-muted mb-2 d-flex align-items-center">
                                <i>{t("ProductVariants.productProperties")}</i>
                                <div className="ml-2">
                                    <ModalInstructions data={propertiesInstructions} />
                                </div>
                            </div>
                            <EditableTable
                                tableName="Edit Product Properties" // Do not change name
                                key={Math.random()}
                                allowActions={true}
                                onUpdate={propertiesActions}
                                allowInsertRow="true"
                                columns={propertiesColumns}
                                enableNewRowCheckbox={true}
                                data={PRODUCTS_DATA.selectedEditProduct.productProperties}/>
                        </Col>
                    </Row>
                </React.Fragment>
            )}
            <hr/>
            <Row>
                <TextArea
                    name="notes"
                    rows="4"
                    autoComplete={"off"}
                    label={t('ProductsNewGeneralEdit.productNotes')}
                    value={PRODUCTS_DATA.selectedEditProduct.notes}
                    onChange={(e) => handleOnChange(e, "textarea", "notes")}
                />
            </Row>
            {PRODUCTS_DATA.selectedEditProduct.measurementUnit !== "4" && PRODUCTS_DATA.selectedEditProduct.isTaxLine !== "true" && (
                <Row>
                    <Col>
                        <EditableTable
                            heading="Installations Inventory"
                            tableName="Company Installations List"
                            key={"Installations Inventory Table"}
                            columns={inventoryInstallationsColumns}
                            data={PRODUCTS_DATA.selectedEditProduct.installationsInventory}
                            className="mb-4"
                        />
                    </Col>
                </Row>
            )}
            <Modal show={showCategoryConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('ProductsNewGeneral.warning')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('ProductsNewGeneral.warningMessage')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary"
                            onClick={() => setShowCategoryConfirmation(false)}>{t('ProductsNewGeneral.no')}</Button>
                    <Button variant="primary"
                            onClick={() => handleConfirmCategory()}>{t('ProductsNewGeneral.yes')}</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export const ProductsEditOthers = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const SETTINGS = useSelector(state => state.SETTINGS);
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const permissionData = JSON.parse(localStorage.getItem("permissions"));
    let pMDDSettings = SETTINGS.primerMyDataData;
    let otherTaxes = getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? (pMDDSettings.otherTaxes).filter(otherTax => otherTax.taxPer !== "true" || otherTax.taxLevel !== 1) : SETTINGS.otherHoldTaxes, 'code', 'name');
    let taxesWithhold = getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? (pMDDSettings.withHoldTaxes).filter(withHoldTax => withHoldTax.taxPer !== "true" || withHoldTax.taxLevel !== 1) : SETTINGS.withHoldTaxes, 'code', 'name');
    let taxesStamp = getOptionsByData(pMDDSettings?.stamp !== undefined && pMDDSettings?.stamp !== '' ? (pMDDSettings.stamp).filter(stamp => stamp.taxPer !== "true" || stamp.taxLevel !== 1) : SETTINGS.stamp, 'code', 'name');
    let taxesFees = getOptionsByData(pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? (pMDDSettings.fees).filter(fee => fee.taxPer !== "true" || fee.taxLevel !== 1) : SETTINGS.fees, 'code', 'name');
    // Fix Blank In the first position
    otherTaxes = [{value: "", label: t("Null"), color: "#76AEB7"}, ...otherTaxes]
    taxesWithhold = [{value: "", label: t("Null"), color: "#76AEB7"}, ...taxesWithhold]
    taxesStamp = [{value: "", label: t("Null"), color: "#76AEB7"}, ...taxesStamp]
    taxesFees = [{value: "", label: t("Null"), color: "#76AEB7"}, ...taxesFees]

    const handleOnFieldChange = (e, name = '') => {
        if(!ACTIVITY.saveEditProduct) {
            dispatch(setSaveEditProduct(true));
        }
        let tax = {};
        tax[name] = e.value;
        let selectedProduct = Object.assign({}, PRODUCTS_DATA.selectedEditProduct);
        if (name === "otherTaxes") {
            const otherTax = findObjectFromArrayOfObjects(pMDDSettings.otherTaxes, e.value, 'code');
            if (otherTax && otherTax.taxAmount === "true") {
                selectedProduct.price = !isNaN(Number(otherTax.tax)) ? Number(otherTax.tax).toString() : "0";
                selectedProduct.isTaxLine = "true";
                if (otherTax.vatIncluded === 1 || otherTax.vatIncluded === 2) {
                    selectedProduct.isTaxLineWithoutVat = "false";
                    if (otherTax.vatIncluded === 2) {
                        selectedProduct.vatIncluded = false;
                    }
                    if (otherTax.vatPercentage !== 0) {
                        selectedProduct.vat = otherTax.vatPercentage.toString();
                    }
                } else {
                    selectedProduct.vat = "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)";
                    selectedProduct.isTaxLineWithoutVat = "true";
                }
                tax["withholdTax"] = "";
                tax["stamp"] = "";
                tax["fee"] = "";
                selectedProduct["measurementUnit"] = "1";
                dispatch(setSelectedEditProduct({selectedProduct}));
            } else {
                selectedProduct.isTaxLineWithoutVat = "false";
                selectedProduct.isTaxLine = "false";
                dispatch(setSelectedEditProduct({selectedProduct}));
            }
        }
        if (name === "withholdTax") {
            const witholdTax = findObjectFromArrayOfObjects(pMDDSettings.withHoldTaxes, e.value, 'code');
            if (witholdTax && witholdTax.taxAmount === "true") {
                selectedProduct.price = !isNaN(Number(witholdTax.tax)) ? Number(witholdTax.tax).toString() : "0";
                selectedProduct.isTaxLine = "true";
                if (witholdTax.vatIncluded === 1 || witholdTax.vatIncluded === 2) {
                    selectedProduct.isTaxLineWithoutVat = "false";
                    if (witholdTax.vatIncluded === 2) {
                        selectedProduct.vatIncluded = false;
                    }
                    if (witholdTax.vatPercentage !== 0) {
                        selectedProduct.vat = witholdTax.vatPercentage.toString();
                    }
                } else {
                    selectedProduct.vat = "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)";
                    selectedProduct.isTaxLineWithoutVat = "true";
                }
                tax["otherTaxes"] = "";
                tax["stamp"] = "";
                tax["fees"] = "";
                tax["fee"] = "";
                selectedProduct["measurementUnit"] = "1";
                dispatch(setSelectedEditProduct({selectedProduct}));
            } else {
                selectedProduct.isTaxLineWithoutVat = "false";
                selectedProduct.isTaxLine = "false";
                dispatch(setSelectedEditProduct({selectedProduct}));
            }
        }
        if (name === "fee") {
            const fee = findObjectFromArrayOfObjects(pMDDSettings.fees, e.value, 'code');
            if (fee && fee.taxAmount === "true") {
                selectedProduct.price = !isNaN(Number(fee.tax)) ? Number(fee.tax).toString() : "0";
                selectedProduct.isTaxLine = "true";
                if (fee.vatIncluded === 1 || fee.vatIncluded === 2) {
                    selectedProduct.isTaxLineWithoutVat = "false";
                    if (fee.vatIncluded === 2) {
                        selectedProduct.vatIncluded = false;
                    }
                    if (fee.vatPercentage !== 0) {
                        selectedProduct.vat = fee.vatPercentage.toString();
                    }
                } else {
                    selectedProduct.vat = "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)";
                    selectedProduct.isTaxLineWithoutVat = "true";
                }
                tax["otherTaxes"] = "";
                tax["stamp"] = "";
                tax["withholdTax"] = "";
                tax["fee"] = e.value;
                selectedProduct["measurementUnit"] = "1";
                dispatch(setSelectedEditProduct({selectedProduct}));
            } else {
                selectedProduct.isTaxLineWithoutVat = "false";
                selectedProduct.isTaxLine = "false";
                dispatch(setSelectedEditProduct({selectedProduct}));
            }
        }
        if (name === "stamp") {
            const stamp = findObjectFromArrayOfObjects(pMDDSettings.stamp, e.value, 'code');
            if (stamp && stamp.taxAmount === "true") {
                selectedProduct.price = !isNaN(Number(stamp.tax)) ? Number(stamp.tax).toString() : "0";
                selectedProduct.isTaxLine = "true";
                if (stamp.vatIncluded === 1 || stamp.vatIncluded === 2) {
                    selectedProduct.isTaxLineWithoutVat = "false";
                    if (stamp.vatIncluded === 2) {
                        selectedProduct.vatIncluded = false;
                    }
                    if (stamp.vatPercentage !== 0) {
                        selectedProduct.vat = stamp.vatPercentage.toString();
                    }
                } else {
                    selectedProduct.vat = "No VAT No VAT (π.χ. Μισθοδοσία, Αποσβέσεις κλπ)";
                    selectedProduct.isTaxLineWithoutVat = "true";
                }
                tax["otherTaxes"] = "";
                tax["fee"] = "";
                tax["withholdTax"] = "";
                selectedProduct["measurementUnit"] = "1";
                dispatch(setSelectedEditProduct({selectedProduct}));
            } else {
                selectedProduct.isTaxLineWithoutVat = "false";
                selectedProduct.isTaxLine = "false";
                dispatch(setSelectedEditProduct({selectedProduct}));
            }
        }
        dispatch(setSelectedEditProduct({...selectedProduct, ...tax}));
    }

    const disabledFields = PRODUCTS_DATA.selectedEditProduct.category === undefined;

    return (
        <Row hidden={!checkPermission("products-products/edit-othertaxes-read", permissionData)}>
            <div className="position-relative product-form">
                <div className="text-muted mb-2"><i>{t('ProductsNewOtherTaxes.otherTaxes')}</i></div>
                {disabledFields && <div className="overlay_w">{t('ProductsNewOtherTaxes.message')}</div>}
                <Row>
                    <Col xs="12" md="3">
                        <AdvancedCombobox key={Math.random()} defaultValue={{ value: PRODUCTS_DATA.selectedEditProduct.otherTaxes, label: getLabelByValue(otherTaxes, PRODUCTS_DATA.selectedEditProduct.otherTaxes,'value'), color: "#76AEB7" }} onChange={(e) => handleOnFieldChange(e, 'otherTaxes', 'ac')} searchableDropdownEnable={true} label={t('ProductsNewOtherTaxes.otherTaxes')} data={otherTaxes} />
                    </Col>
                    <Col xs="12" md="3">
                        <AdvancedCombobox key={Math.random()} defaultValue={{ value: PRODUCTS_DATA.selectedEditProduct.withholdTax, label: getLabelByValue(taxesWithhold, PRODUCTS_DATA.selectedEditProduct.withholdTax,'value'), color: "#76AEB7" }} onChange={(e) => handleOnFieldChange(e, 'withholdTax', 'ac')} searchableDropdownEnable={true} label={t('ProductsNewOtherTaxes.withholdTaxes')} data={taxesWithhold} />
                    </Col>
                    <Col xs="12" md="3">
                        <AdvancedCombobox key={Math.random()} defaultValue={{ value: PRODUCTS_DATA.selectedEditProduct.stamp, label: getLabelByValue(taxesStamp, PRODUCTS_DATA.selectedEditProduct.stamp,'value'), color: "#76AEB7" }} onChange={(e) => handleOnFieldChange(e, 'stamp', 'ac')} searchableDropdownEnable={true} label={t('ProductsNewOtherTaxes.stamp')} data={taxesStamp} />
                    </Col>
                    <Col xs="12" md="3">
                        <AdvancedCombobox key={Math.random()} defaultValue={{ value: PRODUCTS_DATA.selectedEditProduct.fee, label: getLabelByValue(taxesFees, PRODUCTS_DATA.selectedEditProduct.fee,'value'), color: "#76AEB7" }} onChange={(e) => handleOnFieldChange(e, 'fee', 'ac')} searchableDropdownEnable={true} label={t('ProductsNewOtherTaxes.fees')} data={taxesFees} />
                    </Col>
                </Row>
                <p className="mb-4">{t('ProductsNewOtherTaxes.message2')}</p>
            </div>
        </Row>
    );
};
export const ProductsEditAccounting = () => {
    const [t] = useTranslation("common");
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
    const dispatch = useDispatch();

    const handleOnChange = (e, type, compName) => {
        if(!ACTIVITY.saveEditProduct) dispatch(setSaveEditProduct(true));

        if (type === "input") {
            dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, [compName] : e.target.value}));
        } else if (type === "checkbox") {
            dispatch(setSelectedEditProduct({...PRODUCTS_DATA.selectedEditProduct, [compName] : e.target.checked === true ? "active" : "inactive"}));
        }
    }
    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('ProductsNewAccounting.accounting')}</i></div>
            <Row>
                <Col xs="12" md="4">
                    <Input
                        name="accountingCode"
                        className="mb-3"
                        charLimit={20}
                        placeholder="Εισάγετε Πρόθεμα Κωδικού Λογαριασμού"
                        label="Πρόθεμα Κωδικού Λογαριασμού"
                        value={PRODUCTS_DATA.selectedEditProduct.accountingCode}
                        onChange={(e) => handleOnChange(e, "input", "accountingCode")}
                    />
                </Col>
                <Col md={4}>
                    <BetterCheckBox
                        name={"accountingNonDeductibleVAT"}
                        className={"mt-3"}
                        text={"Μη Εκπιπτόμενος Φ.Π.Α."}
                        checked={PRODUCTS_DATA.selectedEditProduct.accountingNonDeductibleVAT === "active"}
                        onChange={(e) => handleOnChange(e, "checkbox", "accountingNonDeductibleVAT")}
                    />
                </Col>
            </Row>
            <Row>
                <div className="text-muted mb-2"><i>{t("General.bypassAccounting")}:</i></div>
                <Col xs="12" md="4">
                    <Input
                        name="netAmountAccountingCode"
                        className="mb-3"
                        charLimit={20}
                        placeholder="Εισάγετε Κωδικό Λογαριασμού Καθαρής Αξίας"
                        label="Κωδικός Λογαριασμού Καθαρής Αξίας"
                        value={PRODUCTS_DATA.selectedEditProduct.netAmountAccountingCode}
                        onChange={(e) => handleOnChange(e, "input", "netAmountAccountingCode")}
                    />
                </Col>
                <Col xs="12" md="4">
                    <Input
                        name="vatAccountingCode"
                        className="mb-3"
                        charLimit={20}
                        placeholder="Εισάγετε Κωδικό Λογαριασμού ΦΠΑ"
                        label="Κωδικός Λογαριασμού ΦΠΑ"
                        value={PRODUCTS_DATA.selectedEditProduct.vatAccountingCode}
                        onChange={(e) => handleOnChange(e, "input", "vatAccountingCode")}
                    />
                </Col>
                <Col xs="12" md="4">
                    <Input
                        name={"taxAccountCode"}
                        className={"mb-3"}
                        charLimit={20}
                        placeholder={"Εισάγετε Κωδικό Λογαριασμού Φόρου"}
                        label={"Κωδικός λογαριασμού Φόρου"}
                        value={PRODUCTS_DATA.selectedEditProduct.taxAccountCode}
                        onChange={(e) => handleOnChange(e, "input", "taxAccountCode")}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export const ProductsEditProductCodes = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
    const permissionData = JSON.parse(localStorage.getItem("permissions"));

    const parentProductCodes = {
        insert: (e, data) => {

            let productCodeObj = Object.assign({},  PRODUCTS_DATA.editProductCode);
            if (e.key !== "Enter") {
                productCodeObj[data.field] = data.value;

                // special case to populate name
                if (data.field === 'code') {
                    if (data.value) {
                        const products = getObjectFromArrayOfObjects(PRODUCTS_DATA.products, data.value, data.field);
                        if (products.length > 0) {
                            productCodeObj['name'] = products['0'].name;
                        }
                    }
                }

                // special case to populate code
                if (data.field === 'name') {
                    if (data.value) {
                        const products = getObjectFromArrayOfObjects(PRODUCTS_DATA.products, data.value, data.field);
                        if (products.length > 0) {
                            productCodeObj['code'] = products['0'].code;
                        }
                    }
                }

                dispatch(setEditProductCode(productCodeObj)); // Update redux my data values
            }

            // If validated fields done add new row
            if (productCodeObj.name !=='' && productCodeObj.code !=='') {
                let editProductCode = Object.assign({},  PRODUCTS_DATA.editProductCode);
                let productCodes = PRODUCTS_DATA.selectedEditProduct.productCodes.slice();
                editProductCode.id = productCodes.length + 1;
                editProductCode.no = productCodes.length + 1;
                editProductCode.name = productCodeObj.name;
                editProductCode.code = productCodeObj.code;
                dispatch(setEditProductCodes([...productCodes, editProductCode]));  // update product codes
                dispatch(setEditProductCode(PRODUCTS_DATA.newProductCodeInitialValue)); // Reset new category mydata
            }

            // Add on press enter
            if (e.key === "Enter" && data.field !== 'add') {
                if (data.field === 'name' && data.value === '') {
                    toast.error("Product Name should not be empty.");
                } else {
                    let editProductCode = Object.assign({},  PRODUCTS_DATA.editProductCode);
                    if (editProductCode.field === 'name' && editProductCode.value === '') {
                        toast.error("Product Name should not be empty.");
                    } else {
                        let productCodes = PRODUCTS_DATA.selectedEditProduct.productCodes.slice();
                        if (productCodes.find(item => item.name === data.value) === undefined) {
                            editProductCode.id = productCodes.length + 1;
                            editProductCode.no = productCodes.length + 1;
                            editProductCode.name = data.value;
                            dispatch(setEditProductCodes([...productCodes, editProductCode]));  // update product codes
                            dispatch(setEditProductCode(PRODUCTS_DATA.newProductCodeInitialValue)); // Reset new category mydata
                        } else {
                            toast.error("Product Name should be unique.");
                        }
                    }
                }
            }

            // Fix allow empty row insert on check box click
            if (e.key === "Enter" && data.field !== 'add') {
                let editProductCode = Object.assign({},  PRODUCTS_DATA.editProductCode);
                let productCodes = PRODUCTS_DATA.selectedEditProduct.productCodes.slice();
                editProductCode.id = productCodes.length + 1;
                editProductCode.no = productCodes.length + 1;
                dispatch(setEditProductCodes([...productCodes, editProductCode]));  // update product codes
                dispatch(setEditProductCode(PRODUCTS_DATA.newProductCodeInitialValue)); // Reset new category mydata
            }

            // Fix allow empty row insert on check box click
            if (data.field === 'add') {
                let editProductCode = Object.assign({},  PRODUCTS_DATA.editProductCode);
                let productCodes = PRODUCTS_DATA.selectedEditProduct.productCodes.slice();
                editProductCode.id = productCodes.length + 1;
                editProductCode.no = productCodes.length + 1;
                editProductCode.name = "";
                editProductCode.code = "";
                dispatch(setEditProductCodes([...productCodes, editProductCode]));  // update product codes
            }

        },
        update: (data, id) => {
            if (data.name && data.name === '') {
                toast.error("Name is mandatory field.");
            } else {
                let productCodes = PRODUCTS_DATA.selectedEditProduct.productCodes.slice();
                let tempProductCodes = productCodes.slice();
                if (tempProductCodes && tempProductCodes.find(item => item.id === id) !== undefined) {
                    tempProductCodes[id-1] = {...tempProductCodes[id - 1], ...data};
                    dispatch(setEditProductCodes(tempProductCodes));
                }
            }
        },
        selected: () => {},
        delete: (id) => {
            let productCodes = PRODUCTS_DATA.selectedEditProduct.productCodes.slice();
            if (id >= 1) {
                let deletedProductCodes = deleteObjectFromArrayOfObjects(productCodes, id,'id');
                toast.success('1 row deleted.');
                dispatch(setEditProductCodes(deletedProductCodes));
            }
        }
    }


    const supplierCodeColumns = [
        {label: t('ProductsNewGeneralEdit.table2.supplierCode'), name: "code"},
        {label: t('ProductsNewGeneralEdit.table2.supplierName'), name: "name"},
        {label: t('ProductsNewGeneralEdit.table2supplier.vat'), name: "vat"},
    ];

    const productCodeColumns = [
        {label: t('ProductsNewGeneralEdit.table3.code'), name: "code"},
        {label: t('ProductsNewGeneralEdit.table3.productName'), name: "name"},
        {label: t('ProductsNewGeneralEdit.table3.alternativeName'), name: "alternateName"},
    ];

    const productCodesColumns = [
        {
            name: "No",
            field: "no",
        },
        {
            name: t('ProductsNewTags.table.supplier'),
            field: "supplierName", // supplier
            editable:true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: supplierCodeColumns,
                    data: PRODUCTS_DATA.products,
                    editEnable: false ,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t('ProductsNewTags.table.selectSupplierName')
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t('ProductsNewTags.table.productName'),
            field: "name",
            editable:true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: productCodeColumns,
                    data: PRODUCTS_DATA.products,
                    editEnable: false ,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t('ProductsListNew.table.placeholderName')
                },
                type: 'text',
                // type: 'advanced-combobox',
            }
        },
        {
            name: t('ProductsNewTags.table.productCode'),
            field: "code",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: productCodeColumns,
                    data: PRODUCTS_DATA.products,
                    editEnable: false ,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t('ProductsListNew.table.placeholderCode')
                },
                type: 'text',
                // type: 'advanced-combobox',
            }
        },
    ];

    const [tag, setTag] = useState("");

    const addTags = () => {}

    const newTag = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name !=="") {
            setTag(value)
        }
    }

    return (
        <React.Fragment>
            <Row hidden={!checkPermission("products-products/edit-tags-read", permissionData)}>
                <EditableTable
                    key={Math.random()}
                    heading={t('ProductsNewTags.productCodes')}
                    tableName="Product Codes" // do not change it
                    columns={productCodesColumns}
                    allowActions={ true }
                    allowInsertRow={ true }
                    data={ PRODUCTS_DATA.selectedEditProduct.productCodes }
                    enableNewRowCheckbox = {true}
                    onUpdate={parentProductCodes}
                />
            </Row>
            <Row hidden={!checkPermission("products-products/edit-tags-write", permissionData)}>
                <Col className="mt-3" xs="12">
                    <div className="text-muted mb-2"><i>{t('ProductsNewTags.productTag')}</i></div>
                    <div className="d-flex justify-content-start">
                        <Input unlabbled={true} placeholder={t('ProductsNewTags.addTag')} name="tagName" value={tag} className="mr-3" onChange={(e) => newTag(e)} />
                        <Button name="save" onClick={() => addTags()} className="mb-3 btn btn-primary btn-sm">{t('ProductsNewTags.addTag')}</Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export const ProductsEditVariants = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const PRODUCTS_DATA = useSelector( (state) => state.PRODUCTS_DATA );
    const permissionData = JSON.parse(localStorage.getItem("permissions"));
    const [variantPropertiesOptions, setVariantPropertiesOptions] = useState([]);
    const [variantName, setVariantName] = useState(PRODUCTS_DATA.selectedEditProduct.name);
    const [showVariantModal, setShowVariantModal] = useState(false);
    const [showAutoCreateModal, setShowAutoCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteMultipleModal, setShowDeleteMultipleModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const handleSelect = (e, type, dataKey) => {
        if (type === "selectRow") {
            const selectedRows = Array.from(PRODUCTS_DATA.selectedEditVariantsRows);
            if (selectedRows.includes(dataKey)) {
                selectedRows.splice(selectedRows.indexOf(dataKey), 1);
            } else {
                selectedRows.push(dataKey);
            }
            dispatch(setEditSelectedVariantsRows(selectedRows));
        } else if (type === "selectAll") {
            if (!PRODUCTS_DATA.allCheckedEditVariants) {
                dispatch(setEditSelectedVariantsRows(Array.from({ length: PRODUCTS_DATA.selectedEditProduct.displayedVariants.length }, (v, index) => index)));
                dispatch(setAllCheckedEditVariants(true));
            } else {
                dispatch(setEditSelectedVariantsRows([]));
                dispatch(setAllCheckedEditVariants(false));
            }
        }
    }

    const variantColumns = [
        {
            field: "select_checkbox",
            name: "Select All",
            width: "40px",
            inputType: {
                type: 'selection',
                select: handleSelect
            }
        },{
            name: 'Ονομασία',
            field: "name",
            width: "20%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },{
            name: 'Ιδιότητες',
            field: "properties",
            width: "40%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },{
            name: 'SKU',
            field: "sku",
            width: "20%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },{
            name: 'Απόθεμα',
            field: "inventoryBalance",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },{
            name: 'Τιμή',
            field: "totalPrice",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ];

    useEffect(() => {
        dispatch(setEditVariantIds());
        dispatch(setEditVariantSearchTerm(""));
        PRODUCTS_DATA.selectedEditProduct.productProperties.forEach((property) => {
            if (property.values.length === 0) return;
            let options = property.values.split(",");

            options = [...new Set(options)]; //Remove duplicates

            // Create property options including price variations
            options = options.map((opt) => {
                let priceVariation;
                if (opt.includes(":") && isValidNumber(opt.split(":")[1])) {
                    let temp = opt.split(":")[1].trim();
                    priceVariation = temp > 0 ? `+${temp}€` : temp < 0 ? `${temp}€` : "";
                    opt = opt.split(":")[0].trim();
                    return {label: `${opt} ${priceVariation}`, value: opt.trim(), priceVariation: temp};
                }
                return  {label: opt, value: opt.trim()};
            });
            options.unshift({label: "Κενό", value: null});
            setVariantPropertiesOptions(oldArr =>[...oldArr, {name: property.name, options: options}]);
        });
    }, []);

    useEffect(() => {
        const props = PRODUCTS_DATA.newVariant.properties;
        setVariantName(`${PRODUCTS_DATA.selectedEditProduct.name}  ${Object.values(props).filter((el) => el !== null).join(" - ")}`);
        const priceVariationSum = Object.values(PRODUCTS_DATA.newVariant.priceVariations ?? {}).reduce((a = 0, b = 0) => Number(a) + Number(b), 0);
        const price = Number(PRODUCTS_DATA.newVariant.price);
        const totalPrice = price + priceVariationSum;
        dispatch(setNewVariant({...PRODUCTS_DATA.newVariant, price: price, totalPrice: totalPrice}))
    }, [PRODUCTS_DATA.newVariant.properties]);

    const handleOnChange = (e, type="default", name="") => {
        let newVariantData = structuredClone(PRODUCTS_DATA.newVariant);
        if (type === "property") {
            newVariantData["properties"][name] = e.value;
            if(e.priceVariation) newVariantData["priceVariations"][name] = e.priceVariation;
            else delete newVariantData["priceVariations"][name];
        } else if (type === "input" || type === "accountingBox") {
            newVariantData[name] = e.target.value;
        } else if (type === "checkbox") {
            newVariantData[name] = e.target.value;
        }
        dispatch(setNewVariant({...PRODUCTS_DATA.newVariant, ...newVariantData}));
    }

    const addVariant = () => {
        let newVariant = structuredClone(PRODUCTS_DATA.newVariant);
        let productVariants = structuredClone(PRODUCTS_DATA.selectedEditProduct.variants);

        if (newVariant.name.length === 0) newVariant.name = variantName;
        newVariant.id = productVariants.length;

        removeEmptyValues(newVariant.properties);
        if (isEmpty(newVariant.properties) || Object.values(newVariant.properties).every(value => value === null)) {
            return toast.error("Οι παραλλαγές πρέπει να έχουν τουλάχιστον μια ιδιότητα.");
        }
        let isDuplicate = false;
        productVariants.forEach((element) => {
            if(JSON.stringify(element.properties) === JSON.stringify(newVariant.properties)) isDuplicate = true;
        });
        if (isDuplicate) {
            return toast.error("Υπάρχει ήδη παραλλαγή με ίδιες ιδιότητες.");
        }
        productVariants.push(newVariant);

        dispatch(addProductEditVariant(newVariant));
        dispatch(setSaveEditProduct(true));
        setShowVariantModal(false);
        toast.success("Προστέθηκε νέα παραλλαγή.");
    }

    const editVariant = () => {
        let newVariant = structuredClone(PRODUCTS_DATA.newVariant);
        let productVariants = structuredClone(PRODUCTS_DATA.selectedEditProduct.variants);
        let displayedVariants = structuredClone(PRODUCTS_DATA.selectedEditProduct.displayedVariants);

        if (newVariant.name.length === 0) newVariant.name = variantName;

        removeEmptyValues(newVariant.properties);
        if (isEmpty(newVariant.properties) || Object.values(newVariant.properties).every(value => value === null)) {
            return toast.error("Οι παραλλαγές πρέπει να έχουν τουλάχιστον μια ιδιότητα.");
        }
        const index1 = productVariants.findIndex(obj => obj.id === newVariant.id);
        const index2 = displayedVariants.findIndex(obj => obj.id === newVariant.id);
        if (index1 !== -1) productVariants[index1] = newVariant;
        if (index2 !== -1) displayedVariants[index2] = newVariant;

        dispatch(updateProductEditVariants(productVariants));
        dispatch(setEditProductDisplayedVariants(displayedVariants));
        dispatch(setSaveEditProduct(true));
        setShowVariantModal(false);
        toast.success("Προστέθηκε νέα παραλλαγή.");
    }

    const createNewVariant = () => {
        const startingPrice = Number(PRODUCTS_DATA.selectedEditProduct.price);
        dispatch(setNewVariant({...PRODUCTS_DATA.newVariantInitialValue, price: startingPrice, totalPrice: startingPrice}));
        setShowVariantModal(true);
    }

    const variantTableActions =  {
        insert: () => {},
        update: () => {},
        selected: (data) => {
            dispatch(setNewVariant(data));
            setShowVariantModal(true);
        },
        delete: (id, rowIndex) => {
            setDeleteIndex(rowIndex)
            setShowDeleteModal(true);
        },
    }

    const handleDelete = () => {
        let displayedVariants = structuredClone(PRODUCTS_DATA.selectedEditProduct.displayedVariants);
        let variants = structuredClone(PRODUCTS_DATA.selectedEditProduct.variants);

        const deleted = displayedVariants.splice(deleteIndex, 1);
        if (!isEmpty(deleted)) variants = variants.filter(variant => variant.id !== deleted[0].id);

        dispatch(updateProductEditVariants(variants));
        dispatch(setEditProductDisplayedVariants(displayedVariants));
        setShowDeleteModal(false);
        dispatch(setSaveEditProduct(true));
    }

    const handleDeleteMany = () => {
        let displayedVariants = structuredClone(PRODUCTS_DATA.selectedEditProduct.displayedVariants);
        let variants = structuredClone(PRODUCTS_DATA.selectedEditProduct.variants);
        const selectedEditVariantsRows = structuredClone(PRODUCTS_DATA.selectedEditVariantsRows);
        let deletedIds = [];
        selectedEditVariantsRows.sort((x, y) => y - x);

        selectedEditVariantsRows.forEach((selIndex) => {
            let deleted = displayedVariants.splice(selIndex, 1);
            if (!isEmpty(deleted)) deletedIds.push(deleted[0].id);
        });
        variants = variants.filter((variant) => {
            return !deletedIds.includes(variant.id);
        });
        setShowDeleteMultipleModal(false);
        dispatch(setEditSelectedVariantsRows([]));
        dispatch(setAllCheckedEditVariants(false))
        dispatch(setEditProductDisplayedVariants(displayedVariants));
        dispatch(updateProductEditVariants(variants));
        dispatch(setSaveEditProduct(true));
    }

    const autoCreateVariants = () => {
        const productProperties = structuredClone(PRODUCTS_DATA.selectedEditProduct.productProperties);
        const startingPrice = Number(PRODUCTS_DATA.selectedEditProduct.price);
        const arrays = productProperties.map((prop) => prop.values.split(",").map(val => { return {[prop.name]: val} }));
        const variants = [];
        let createdCount = 0;

        function createCombos(currentProps, index) {
            if (index === arrays.length) {
                let priceVariation = 0;
                let priceVariations = {};
                let tempCurrentProps = structuredClone(currentProps);
                tempCurrentProps.forEach((prop) => {
                    Object.keys(prop).forEach((key) => {
                        let temp = prop[key].split(":");
                        prop[key] = temp[0].trim();
                        if (temp.length === 2 && isValidNumber(temp[1])) {
                            priceVariation += Number(temp[1]);
                            priceVariations[key] = temp[1];
                        }
                    });
                });
                // Merge all props
                const combinedProps = Object.assign({}, ...tempCurrentProps);

                let alreadyExists = false
                PRODUCTS_DATA.selectedEditProduct.variants?.forEach((variant) => {
                    if(JSON.stringify(variant.properties) === JSON.stringify(combinedProps)) {
                        variants.push(variant);
                        alreadyExists = true;
                    }
                });
                if (alreadyExists) return;

                let variantName = `${PRODUCTS_DATA.selectedEditProduct.name}  ${Object.values(combinedProps).filter((el) => el !== null).join(" - ")}`;
                const newVariant = {...PRODUCTS_DATA.newVariantInitialValue, name: variantName, properties: combinedProps, priceVariations: priceVariations, price: startingPrice, totalPrice: startingPrice + priceVariation};
                variants.push(newVariant);
                createdCount++
                return;
            }

            for (let i = 0; i < arrays[index].length; i++) {
                createCombos(currentProps.concat(arrays[index][i]), index + 1);
            }
        }
        createCombos([], 0);

        dispatch(addProductEditAllVariants(variants));
        dispatch(setEditVariantIds());
        dispatch(setSaveEditProduct(true));
        setShowAutoCreateModal(false);
        createdCount !== 0 ? toast.success(`Δημιουργήθηκαν ${createdCount} παραλλαγές επιτυχώς.`) : toast.info("Έχουν ήδη δημιουργηθεί όλες οι πιθανές παραλλαγές.");
    }

    const searchTermOnChange = (e) => {
        const term = e.target.value;
        const regex = /[{},:.]/g;
        dispatch(setEditVariantSearchTerm(term));

        let result = PRODUCTS_DATA.selectedEditProduct.variants?.filter((variant) => {
            return variant.name.match(term.trim()) || JSON.stringify(variant.properties).replace(regex).match(term.trim());
        });
        dispatch(setEditProductDisplayedVariants(result));
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs="12" md="4" className="mb-2">
                    <Input
                        name="search"
                        charLimit={100}
                        placeholder="Αναζήτηση"
                        autoComplete="off"
                        onChange={(e) => searchTermOnChange(e)}
                        value={PRODUCTS_DATA.editVariantSearchTerm}
                    />
                </Col>
                <Col xs="12" md="8" className="mb-2">
                    <div className="float-right">
                        <Button hidden={PRODUCTS_DATA.selectedEditVariantsRows.length === 0} type="button"
                                onClick={() => setShowDeleteMultipleModal(true)}
                                className="btn btn-primary mb-2 mr-2">Διαγραφή επιλεγμένων</Button>
                        <Button disabled={variantPropertiesOptions.length === 0} type="button" onClick={() => setShowAutoCreateModal(true)}
                                className="btn btn-primary mb-2 mr-2">Αυτόματη Δημιουργία</Button>
                        <Button disabled={variantPropertiesOptions.length === 0} type="button" onClick={createNewVariant}
                                className="btn btn-primary mb-2">Νέα Παραλλαγή</Button>
                    </div>
                </Col>
            </Row>
            <Modal show={showVariantModal} onEscapeKeyDown={() => setShowVariantModal(false)} onHide={() => setShowVariantModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{PRODUCTS_DATA.newVariant.id == null ? <strong>Νέα Παραλλαγή</strong> : <strong>Επεξεργασία: {PRODUCTS_DATA.newVariant.name}</strong>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="position-relative border-bottom mb-3 d-flex align-items-center px-2">
                        {PRODUCTS_DATA.selectedEditProduct.measurementUnit !== "4" && PRODUCTS_DATA.newVariant.id != null && (
                            <Col xs="12" md="6" className="mr-auto">
                                <div className="inline-field d-flex align-items-center mb-3">
                                    <label htmlFor="variantInventoryBalance" className="mr-2 mb-0 w-100 ">{"Υπόλοιπο Αποθέματος Παραλλαγής:"}</label>
                                    <Input
                                        name="variantInventoryBalance"
                                        charLimit={50}
                                        type={"number"}
                                        disabled={true}
                                        className="d-flex align-items-center w-100"
                                        value={PRODUCTS_DATA.newVariant.inventoryBalance ?? 0}
                                    />
                                </div>
                            </Col>
                        )}
                        <Col xs="12" md="4" className={`d-flex align-items-center ${PRODUCTS_DATA.newVariant.id != null && "justify-content-end"}`}>
                            <div className="inline-field d-flex align-items-center mb-3">
                                <label htmlFor="variant-active" className="mb-0 mr-2">{"Ενεργή Παραλλαγή"}</label>
                                <Checkbox
                                    className="mb-2"
                                    name="isActive"
                                    key={Math.random()}
                                    id="variant-active"
                                    defaultValue={PRODUCTS_DATA.newVariant.isActive}
                                    value={PRODUCTS_DATA.newVariant.isActive === 'active' ? "inactive" : "active"}
                                    onChange={(e) => handleOnChange(e, "checkbox", "isActive")}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {
                            variantPropertiesOptions.map((property) => {
                                let defaultValue = PRODUCTS_DATA["newVariant"]["properties"][property.name];
                                return (
                                    <Col md={6}>
                                        <Dropdown
                                            name={property.name}
                                            label={<strong>{property.name}</strong>}
                                            key={Math.random()}
                                            options={property.options}
                                            defaultValue={defaultValue ? {label: defaultValue, value: defaultValue} : {label: t("General.empty"), value: null}}
                                            enabledValue={true}
                                            disabled={PRODUCTS_DATA.newVariant.id != null}
                                            onChange={(e) => handleOnChange(e, "property", property.name)}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <Row>
                        <Col xs="12" md="12">
                            <div className="mb-3">
                                <Input
                                    name="name"
                                    charLimit={200}
                                    placeholder={variantName}
                                    label={t('General.name')}
                                    autoComplete="off"
                                    onChange={(e) => handleOnChange(e, 'input', 'name')}
                                    value={PRODUCTS_DATA.newVariant.name}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="12">
                            <div className="mb-3">
                                <Input
                                    name="sku"
                                    charLimit={100}
                                    placeholder="SKU"
                                    label="SKU"
                                    autoComplete="off"
                                    onChange={(e) => handleOnChange(e, 'input', 'sku')}
                                    value={PRODUCTS_DATA.newVariant.sku}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="6">
                            <div className="mb-3">
                                <FormLabel className="" htmlFor="price">{t('ProductsNewGeneral.preferredPrice.preferredPrice')}</FormLabel>
                                <AccountingBox
                                    name="price"
                                    defaultValue={PRODUCTS_DATA.newVariant.price}
                                    onChange={(e) => handleOnChange(e, 'accountingBox', 'price')}
                                />
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="mb-3">
                                <FormLabel className="" htmlFor="price">{t('General.totalPrice')}</FormLabel>
                                <AccountingBox
                                    name="totalPrice"
                                    disabled={true}
                                    defaultValue={PRODUCTS_DATA.newVariant.totalPrice}
                                />
                            </div>
                            <div>
                                {
                                    Object.entries(PRODUCTS_DATA.newVariant.priceVariations ?? {}).map(([key, val]) => {
                                        return (<div className="mb-2">{`${key}: ${val > 0 ? `+${val}€` : val < 0 ? `${val}€` : ""}`}</div>)
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowVariantModal(false)}>
                        Κλείσιμο
                    </Button>
                    {PRODUCTS_DATA.newVariant.id == null ?
                        <Button disabled={variantPropertiesOptions.length === 0} type="button" onClick={addVariant}
                                className="btn btn-primary mb-2 ml-2 float-right">Δημιουργία Παραλλαγής</Button>
                        :
                        <Button disabled={variantPropertiesOptions.length === 0} type="button" onClick={editVariant}
                                className="btn btn-primary mb-2 ml-2 float-right">Ενημέρωση Παραλλαγής</Button>
                    }
                </Modal.Footer>
            </Modal>
            {/*DELETE CONFIRM*/}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header>
                    <Modal.Title>{t('ProductVariants.delete')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('ProductVariants.confirmDelete')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={handleDelete}
                    >
                        {t('DataPanelIndex.yes')}
                    </Button>
                    <Button
                        variant="outline-primary"
                        onClick={() => setShowDeleteModal(false)}
                    >
                        {t('DataPanelIndex.no')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*DELETE MULTIPLE CONFIRM*/}
            <Modal show={showDeleteMultipleModal}>
                <Modal.Header>
                    <Modal.Title>{t('ProductVariants.deleteMultiple')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('ProductVariants.confirmDeleteMultiple')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={handleDeleteMany}
                    >
                        {t('DataPanelIndex.yes')}
                    </Button>
                    <Button
                        variant="outline-primary"
                        onClick={() => setShowDeleteMultipleModal(false)}
                    >
                        {t('DataPanelIndex.no')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*AUTO CREATE CONFIRM*/}
            <Modal show={showAutoCreateModal} onHide={() => setShowAutoCreateModal(false)}>
                <Modal.Header>
                    <Modal.Title>{t('ProductVariants.autoCreate')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('ProductVariants.confirmAutoCreate')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={autoCreateVariants}
                    >
                        {t('DataPanelIndex.create')}
                    </Button>
                    <Button
                        variant="outline-primary"
                        onClick={() => setShowAutoCreateModal(false)}
                    >
                        {t('DataPanelIndex.cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row>
                <Col md={12}>
                    <EditableTable
                        key={Math.random()}
                        heading={"Πίνακας Παραλλαγών Είδους"}
                        tableName="Product Variants"
                        columns={variantColumns}
                        allowActions={true}
                        allowInsertRow={false}
                        enableNewRowCheckbox = {false}
                        data={PRODUCTS_DATA.selectedEditProduct.displayedVariants}
                        onUpdate={variantTableActions}
                        selectedRows={PRODUCTS_DATA.selectedEditVariantsRows}
                        allChecked={PRODUCTS_DATA.allCheckedEditVariants}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export const ProductsEditLog = () => {
    const { t } = useTranslation('common');
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.productLog')}</i></div>
            <LogTable itemId={PRODUCTS_DATA.selectedEditProduct._id}/>
        </React.Fragment>
    )
}