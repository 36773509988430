import React, {useEffect} from "react";
import Sidebar from "../SIDEBAR-COMPONENTS/Sidebar";
import ViewWrapper from "../ViewWrapper";
import Navbar from "../../layouts/Navbar/Navbar";
import {
    fetchAccountingSettings,
    fetchCategory,
    fetchClientsSettings,
    fetchCompanyDetails,
    fetchCompanyInstallations, fetchCompanyStatus,
    fetchEmailSmtp, fetchPayrollSettings,
    fetchPrimerMyDataData, fetchSalesGeneralSettings,
    fetchSuppliersSettings, fetchSyncInvoicesProviderLog,
} from "../../../_apis/api";
import OfflineAlert from "../../common/OnlineOffline/offlineAlert";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUser} from "../../../_helpers/commonFunctions";

const MainWrapper = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;
            const isEpay = permissionsData?.find((el) => el.permissionName === "custom-epay" || el.permissionName === "custom-peiraios" || el.permissionName === "custom-daras");
            const isEmployee = permissionsData?.find((el) => el.permissionName === "custom-employee");

            if (!isEpay && !isEmployee) {
                dispatch(fetchCompanyStatus({company: company.id, userId: getCurrentUser()?._id}));
                dispatch(fetchCategory({type: "product", company: company.id, year: company.year, isActive: "active"}));
                dispatch(fetchPrimerMyDataData({company: company.id, year: company.year.toString()}));
                dispatch(fetchEmailSmtp({company: company.id}));
                dispatch(fetchClientsSettings({company: company.id, year: company.year.toString()}));
                dispatch(fetchSuppliersSettings({company: company.id, year: company.year.toString()}));
                dispatch(fetchCompanyInstallations(company.id));
                dispatch(fetchAccountingSettings({company: company.id}));
                dispatch(fetchCompanyDetails(company.id));
                dispatch(fetchSalesGeneralSettings({company: company.id, year: company.year.toString(), isActive: "active" }));
                dispatch(fetchPayrollSettings(company.id));
                dispatch(fetchSyncInvoicesProviderLog(company.id));
            }
        }
    }, [company, dispatch]);

    return (
        <React.Fragment>
            <OfflineAlert/>
            <Navbar/>
            <div className="container-fluid page-body-wrapper">
                <React.Fragment>
                    <Sidebar />
                    <ViewWrapper />
                </React.Fragment>
            </div>
        </React.Fragment>
    )
}

export default MainWrapper;
