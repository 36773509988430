import React, {useEffect, useState} from 'react';
import "./tableStyle.css";
import moment from "moment";
import {
    colorMapping, dayTranslate,
    decimalToHourMinutesFormat, getWorkType,
    getWorkTypeDDOptions,
    hourMinutesToDecimalFormat,
    workCategories,
} from "./constants";
import {Badge, Button, Col, Modal, Row} from "react-bootstrap";
import Dropdown from "../../../../primer/Dropdown";
import HourPicker from "../../../../primer/HourPicker";
import {useSelector} from "react-redux";
import BetterCheckBox from "../../../../common/BetterCheckBox";

const EmployeeProgram = ({dateFrom, dateTo, events, displayHoursFrom, displayHoursTo, onEventResize, onEventDrag, onEventAdd, onEventsAdd, onEventEdit, onEventDelete, onApplyRounding}) => {
    /*
        An event has:
        - day (day number from dateFrom)
        - hourFrom
        - hourTo
        - type: normal, overwork, overtime, permit, dayOff, noWork
     */
    const payrollSettings = useSelector((state) => state.PAYROLL.payrollSettings);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const [installationOptions, setInstallationOptions] = useState([]);
    const [mouseFrom, setMouseFrom] = useState(0);
    const [mouseDown, setMouseDown] = useState(false);
    const [resizeType, setResizeType] = useState("");

    const rowHeightNum = 40;
    const rowHeight = `${rowHeightNum}px`;
    const step = 1;
    const tableBorderError = 0;

    const [tableColumns, setTableColumns] = useState([]);
    const [hourRows, setHourRows] = useState([]);

    const [newEvent, setNewEvent] = useState({});
    const [newEventModal, setNewEventModal] = useState(false);

    const [editEvent, setEditEvent] = useState({});
    const [editEventModal, setEditEventModal] = useState(false);

    const [deleteEvent, setDeleteEvent] = useState(null);
    const [deleteEventModal, setDeleteEventModal] = useState(false);

    const [applyRounding, setApplyRounding] = useState(null);

    useEffect(() => {
        let tc = [{
            date: "",
            columnDisplay: "Ωράριο"
        }];
        const from = moment(dateFrom, "DD/MM/YYYY", true);
        const to = moment(dateTo, "DD/MM/YYYY", true);
        const diff = Number(to.diff(from, "days")) + 1;
        for (let i = 0; i < diff; i++) {
            let curMomentFormat = from.format("DD/MM/YYYY");
            let curMomentDay = from.format("dddd");

            tc.push({
                date: curMomentFormat,
                columnDisplay: `${dayTranslate[curMomentDay]} ${curMomentFormat}`,
            });
            from.add(1, "days");
        }
        setTableColumns(tc);

        let h = [];
        for (let i = displayHoursFrom; i < displayHoursTo; i++) {
            h.push(i);
        }
        setHourRows(h);

        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
    }, [])

    useEffect(() => {
        if (typeof onApplyRounding === "function" && applyRounding !== null) {
            onApplyRounding(applyRounding);
            setApplyRounding(null);
        }
    }, [applyRounding])

    const getShiftOptions = () => {
        let opt = [];
        if (payrollSettings && payrollSettings?.shifts) {
            for (let shift of payrollSettings.shifts) {
                opt.push(shift);
            }
        }
        return opt;
    }

    const handleMouseDown = (e, resizeType) => {
        setMouseDown(true);
        setMouseFrom(e.clientY);
        setResizeType(resizeType);
    }

    const handleMouseUp = (eventId) => {
        setApplyRounding(eventId);
        setMouseDown(false);
        setMouseFrom(0);
        setResizeType("");
    }

    const handleMouseMove = (e, eventId) => {
        if (mouseDown) {
            const diff = e.clientY - mouseFrom;
            if (resizeType === "top" || resizeType === "bottom") {
                if (Math.abs(diff) >= step && typeof onEventResize === "function") {
                    setMouseFrom(e.clientY);
                    onEventResize(eventId, diff, resizeType);
                }
            } else if (resizeType === "drag") {
                if (Math.abs(diff) >= step && typeof onEventDrag === "function") {
                    setMouseFrom(e.clientY);
                    onEventDrag(eventId, diff);
                }
            }
        }
    }

    const handleDoubleClick = (hour, day) => {
        let id = 0;
        while (events.find((el) => el.id === id)) {
            id++;
        }
        const findOverlap = events?.find((el) => el.day === day && Math.floor(el.hourFrom) <= hour && Math.ceil(el.hourTo) >= hour);
        if (!findOverlap) {
            let prepEvent = {
                id: id,
                day: day,
                installationMasterId: "0",
                hourFrom: hour,
                hourTo: hour,
                workType: "normal",
                workCategory: "company",
                allDay: false,
                permanentChange: false,

                splitHour: false,
                splitHourFrom: 0,
                splitHourTo: 0,
            }
            setNewEvent(prepEvent);
            setNewEventModal(true);
        }
    }

    const handleOpenEdit = (ev) => {
        setEditEvent(ev);
        setEditEventModal(true);
    }

    const handleSaveEditEvent = () => {
        if (typeof onEventEdit === "function") {
            onEventEdit(editEvent);
        }
        setEditEvent({});
        setEditEventModal(false);
    }

    const handleSaveNewEvent = () => {
        if (typeof onEventAdd ===  "function" && !newEvent.splitHour) {
            onEventAdd(newEvent);
        } else if (typeof onEventsAdd === "function" && newEvent.splitHour) {
            let ev1 = {...newEvent};
            delete ev1.splitHour;
            delete ev1.splitHourFrom;
            delete ev1.splitHourTo;
            let ev2 = {...newEvent};
            delete ev2.splitHour;
            ev2.hourFrom = ev2.splitHourFrom;
            ev2.hourTo = ev2.splitHourTo;
            delete ev2.splitHourFrom;
            delete ev2.splitHourTo;

            let id = 0;
            while (events.find((el) => el.id === id) || ev1.id === id) {
                id++;
            }
            ev2.id = id;
            onEventsAdd([ev1, ev2]);
        }
        setNewEvent({});
        setNewEventModal(false);
    }

    const handleDeleteEvent = (id) => {
        setDeleteEvent(id);
        setDeleteEventModal(true);
    }

    const confirmDelete = () => {
        if (typeof onEventDelete === "function") {
            onEventDelete(deleteEvent);
        }
        setDeleteEventModal(false);
        setDeleteEvent(null);
    }

    const renderDay = (day, rIdx) => {
        if (rIdx !== 0) return <React.Fragment></React.Fragment>; // Only render like first cell to match whole day

        const findMatchingEvents = events?.filter((el) => el.day === day);
        if (findMatchingEvents?.length > 0) {
            let componentArray = [];
            for (let findMatching of findMatchingEvents) {
                let isEditable = true;
                if (findMatching.workType === "permit") isEditable = false;

                let paddingTop = (findMatching.hourFrom - displayHoursFrom) * rowHeightNum + (tableBorderError * findMatching.hourFrom);
                let height = (Number(findMatching.hourTo) - Number(findMatching.hourFrom)) * rowHeightNum + (tableBorderError * (findMatching.hourTo - findMatching.hourFrom));

                componentArray.push(
                    <React.Fragment>
                        <div key={`event-${findMatching.id}`} style={{
                            position: "absolute",
                            borderRadius: "10px",
                            backgroundColor: colorMapping[findMatching.workType],
                            height: `${height}px`,
                            width: isEditable ? "82%" : "90%",
                            marginLeft: isEditable ? "13%" : "5%",
                            marginRight: "5%",
                            top: `${paddingTop}px`,
                            boxShadow: "0px 2px 4px -1px lightgray",
                            cursor: "pointer",
                            fontSize: "14px",
                            zIndex: "100",
                            verticalAlign: "center",
                        }}>
                            {isEditable && (
                                <div style={{
                                    position: "absolute",
                                    left: "-25px",
                                    backgroundColor: "lightgray",
                                    borderRadius: "4px",
                                    width: "20px",
                                    height: "20px",
                                }}>
                                    <i title={"Τροποποίηση"} className="far fa-edit"
                                       onClick={() => handleOpenEdit(findMatching)}></i>
                                </div>
                            )}
                            {isEditable && (
                                <div style={{
                                    position: "absolute",
                                    top: "25px",
                                    left: "-25px",
                                    backgroundColor: "lightgray",
                                    borderRadius: "4px",
                                    width: "20px",
                                    height: "20px",
                                }}>
                                    <i title={"Διαγραφή"} className="fa-solid fa-trash"
                                       onClick={() => handleDeleteEvent(findMatching.id)}></i>
                                </div>
                            )}
                            {(!findMatching.allDay && isEditable) && (
                                <div style={{
                                    position: "absolute",
                                    top: "2px",
                                    cursor: "n-resize",
                                    userSelect: "none",
                                    height: `20px`,
                                    width: "100%"
                                }}
                                     onMouseDown={(e) => handleMouseDown(e, "top")}
                                     onMouseMove={(e) => handleMouseMove(e, findMatching.id)}
                                     onMouseLeave={() => handleMouseUp(findMatching.id)}
                                     onMouseUp={() => handleMouseUp(findMatching.id)}>
                                    <i className="fa-solid fa-ellipsis"></i>
                                </div>
                            )}
                            <div style={{fontWeight: "600"}} className={"mt-3"}>{getWorkType(findMatching)}</div>
                            {findMatching.allDay ? (
                                <div>Όλη μέρα</div>
                            ) : (
                                <div>{decimalToHourMinutesFormat(Number(findMatching.hourFrom))} - {decimalToHourMinutesFormat(Number(findMatching.hourTo))}</div>
                            )}
                            {(!findMatching.allDay && isEditable) && (
                                <div style={{position: "relative", height: "70%", cursor: "move"}}
                                     onMouseDown={(e) => handleMouseDown(e, "drag")}
                                     onMouseMove={(e) => handleMouseMove(e, findMatching.id)}
                                     onMouseLeave={() => handleMouseUp(findMatching.id)}
                                     onMouseUp={() => handleMouseUp(findMatching.id)}>
                                </div>
                            )}
                            {(!findMatching.allDay && isEditable) && (
                                <div style={{
                                    position: "absolute",
                                    bottom: "2px",
                                    cursor: "n-resize",
                                    userSelect: "none",
                                    height: "20px",
                                    width: "100%"
                                }}
                                     onMouseDown={(e) => handleMouseDown(e, "bottom")}
                                     onMouseMove={(e) => handleMouseMove(e, findMatching.id)}
                                     onMouseLeave={() => handleMouseUp(findMatching.id)}
                                     onMouseUp={() => handleMouseUp(findMatching.id)}>
                                    <i className="fa-solid fa-ellipsis"></i>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )
            }
            return (
                <React.Fragment>
                    {componentArray}
                </React.Fragment>
            )
        } else {
            return <React.Fragment></React.Fragment>;
        }
    }

    return (
        <div>
            <table className={"employeeProgramTable mb-3"} style={{userSelect: "none", position: "relative", borderCollapse: "collapse"}}>
                <thead>
                <tr>
                    {tableColumns.map((col, idx) => (
                        <th key={`col-${idx}`}>{col.columnDisplay}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {hourRows.map((hour, rIdx) => (
                    <tr style={{height: rowHeight, margin: 0, padding: 0}} key={`ep-${rIdx}`}>
                        <td>{String(hour) + ":00"} - {String(hour + 1) + ":00"}</td>
                        {tableColumns.toSpliced(0, 1).map((col, colIdx) => (
                            <td key={`ep-${rIdx}-${colIdx}`} style={{position: "relative"}} onDoubleClick={() => handleDoubleClick(hour, colIdx)}>{renderDay(colIdx, rIdx)}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>

            <Modal show={newEventModal} onHide={() => setNewEventModal(false)} backdrop={"static"}>
                <Modal.Header closeButton>
                    <Modal.Title>Νέο ωράριο</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(newEvent).length > 0 && (
                        <React.Fragment>
                            <Row>
                                <Col md={12}>
                                    <Dropdown
                                        name={"installationMasterId"}
                                        label={"Εγκατάσταση"}
                                        options={installationOptions}
                                        key={Math.random()}
                                        defaultValue={installationOptions.find((el) => el.value === newEvent.installationMasterId)}
                                        onChange={(e) => setNewEvent({...newEvent, installationMasterId: e.value})}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Dropdown
                                        name={"workType"}
                                        label={"Τύπος Εργασίας"}
                                        options={getWorkTypeDDOptions()}
                                        key={Math.random()}
                                        defaultValue={getWorkTypeDDOptions().find((el) => el.value === newEvent.workType)}
                                        onChange={(e) => {
                                            let cloneEvent = {...newEvent}
                                            cloneEvent.workType = e.value;
                                            if (e.value === "dayOff" || e.value === "noWork") {
                                                cloneEvent.allDay = true;
                                                cloneEvent.hourFrom = displayHoursFrom;
                                                cloneEvent.hourTo = displayHoursTo;
                                            } else {
                                                cloneEvent.allDay = false;
                                                cloneEvent.hourFrom = 8;
                                                cloneEvent.hourTo = 8;
                                            }
                                            setNewEvent(cloneEvent)
                                        }}
                                    />
                                </Col>
                            </Row>
                            {newEvent.workType === "normal" && (
                                <Row className={"mb-2"}>
                                    <Col md={12}>
                                        <Dropdown
                                            name={"workCategory"}
                                            label={"Κατηγορία Εργασίας"}
                                            options={workCategories}
                                            key={Math.random()}
                                            defaultValue={workCategories.find((el) => el.value === newEvent.workCategory)}
                                            onChange={(e) => setNewEvent({...newEvent, workCategory: e.value})}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <span>Βάρδιες: </span>
                                        {getShiftOptions().map((shift, idx) => (
                                            <Badge bg={"primary"} key={`shift${idx}`} style={{cursor: "pointer"}} className={"mr-1"}
                                               onClick={() => {
                                                   if (shift.hourFrom && shift.hourTo) {
                                                       if (shift.splitHourFrom !== "00:00" && shift.splitHourTo !== "00:00") {
                                                           setNewEvent({
                                                               ...newEvent,
                                                               hourFrom: hourMinutesToDecimalFormat(shift.hourFrom),
                                                               hourTo: hourMinutesToDecimalFormat(shift.hourTo),
                                                               splitHour: true,
                                                               splitHourFrom: hourMinutesToDecimalFormat(shift.splitHourFrom),
                                                               splitHourTo: hourMinutesToDecimalFormat(shift.splitHourTo),
                                                           })
                                                       } else {
                                                           setNewEvent({
                                                               ...newEvent,
                                                               hourFrom: hourMinutesToDecimalFormat(shift.hourFrom),
                                                               hourTo: hourMinutesToDecimalFormat(shift.hourTo),
                                                               splitHour: false,
                                                           })
                                                       }
                                                   }
                                               }}>{shift.name}</Badge>
                                        ))}
                                    </Col>
                                </Row>
                            )}
                            {(newEvent.workType === "dayOff" || newEvent.workType === "noWork") && (
                                <Row>
                                    <Col md={12}>
                                        <BetterCheckBox
                                            name={"allDay"}
                                            text={"Όλη μέρα"}
                                            disabled={newEvent.workType === "dayOff" || newEvent.workType === "noWork"}
                                            checked={newEvent.allDay}
                                            onChange={(e) => setNewEvent({...newEvent, allDay: e.target.checked})}
                                        />
                                    </Col>
                                </Row>
                            )}
                            {newEvent.allDay === false && (
                                <React.Fragment>
                                    <Row>
                                        <Col md={6}>
                                            <HourPicker
                                                label={"Ώρα από"}
                                                selected={moment(decimalToHourMinutesFormat(newEvent.hourFrom), "HH:mm").toDate()}
                                                onChange={(e) => setNewEvent({...newEvent, hourFrom: hourMinutesToDecimalFormat(e)})}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <HourPicker
                                                label={"Ώρα έως"}
                                                selected={moment(decimalToHourMinutesFormat(newEvent.hourTo), "HH:mm").toDate()}
                                                onChange={(e) => setNewEvent({...newEvent, hourTo: hourMinutesToDecimalFormat(e)})}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <BetterCheckBox
                                                text={"Σπαστό ωράριο"}
                                                className={"mb-1 mt-3"}
                                                checked={newEvent.splitHour}
                                                onChange={(e) => setNewEvent({...newEvent, splitHour: e.target.checked})}
                                            />
                                        </Col>
                                    </Row>
                                    {newEvent.splitHour && (
                                        <Row>
                                            <Col md={6}>
                                                <HourPicker
                                                    label={"2η Ώρα από"}
                                                    selected={moment(decimalToHourMinutesFormat(newEvent.splitHourFrom), "HH:mm").toDate()}
                                                    onChange={(e) => setNewEvent({...newEvent, splitHourFrom: hourMinutesToDecimalFormat(e)})}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <HourPicker
                                                    label={"2η Ώρα μέχρι"}
                                                    selected={moment(decimalToHourMinutesFormat(newEvent.splitHourTo), "HH:mm").toDate()}
                                                    onChange={(e) => setNewEvent({...newEvent, splitHourTo: hourMinutesToDecimalFormat(e)})}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {/*
                    <BetterCheckBox
                        className={"mr-auto mt-2"}
                        name={"permanentChange"}
                        text={"Μόνιμο σταθερό ωράριο"}
                        checked={newEvent.permanentChange === true}
                        onChange={(e) => setNewEvent(({...newEvent, permanentChange: e.target.checked}))}
                    />
                    */}
                    <Button variant="primary" onClick={() => handleSaveNewEvent()}>
                        Αποθήκευση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={editEventModal} onHide={() => setEditEventModal(false)} backdrop={"static"}>
                <Modal.Header closeButton>
                    <Modal.Title>Τροποποίηση ωραρίου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(editEvent).length > 0 && (
                        <React.Fragment>
                            <Row>
                                <Col md={12}>
                                    <Dropdown
                                        name={"installationMasterId"}
                                        label={"Εγκατάσταση"}
                                        options={installationOptions}
                                        key={Math.random()}
                                        defaultValue={installationOptions.find((el) => el.value === editEvent.installationMasterId)}
                                        onChange={(e) => setEditEvent({...editEvent, installationMasterId: e.value})}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Dropdown
                                        name={"workType"}
                                        label={"Τύπος Εργασίας"}
                                        options={getWorkTypeDDOptions()}
                                        key={Math.random()}
                                        defaultValue={getWorkTypeDDOptions().find((el) => el.value === editEvent.workType)}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                            {editEvent.workType === "normal" && (
                                <Row>
                                    <Col md={12}>
                                        <Dropdown
                                            name={"workCategory"}
                                            label={"Κατηγορία Εργασίας"}
                                            options={workCategories}
                                            key={Math.random()}
                                            defaultValue={workCategories.find((el) => el.value === editEvent.workCategory)}
                                            onChange={(e) => setEditEvent({...editEvent, workCategory: e.value})}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <span>Βάρδιες: </span>
                                        {getShiftOptions().map((shift, idx) => (
                                            <Badge bg={"primary"} key={`shift${idx}`} style={{cursor: "pointer"}} className={"mr-1"}
                                               onClick={() => {
                                                   if (shift.hourFrom && shift.hourTo) {
                                                       setEditEvent({
                                                           ...editEvent,
                                                           hourFrom: hourMinutesToDecimalFormat(shift.hourFrom),
                                                           hourTo: hourMinutesToDecimalFormat(shift.hourTo)
                                                       })
                                                   }
                                               }}>{shift.name}</Badge>
                                        ))}
                                    </Col>
                                </Row>
                            )}
                            {(editEvent.workType === "dayOff" || editEvent.workType === "noWork") && (
                                <Row>
                                    <Col md={12}>
                                        <BetterCheckBox
                                            name={"allDay"}
                                            text={"Όλη μέρα"}
                                            disabled={editEvent.workType === "dayOff" || editEvent.workType === "noWork"}
                                            checked={editEvent.allDay}
                                            onChange={(e) => setEditEvent({...editEvent, allDay: e.target.checked})}
                                        />
                                    </Col>
                                </Row>
                            )}
                            {editEvent.allDay === false && (
                                <Row>
                                    <Col md={6}>
                                        <HourPicker
                                            label={"Ώρα από"}
                                            selected={moment(decimalToHourMinutesFormat(editEvent.hourFrom), "HH:mm").toDate()}
                                            onChange={(e) => setEditEvent({...editEvent, hourFrom: hourMinutesToDecimalFormat(e)})}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <HourPicker
                                            label={"Ώρα έως"}
                                            selected={moment(decimalToHourMinutesFormat(editEvent.hourTo), "HH:mm").toDate()}
                                            onChange={(e) => setEditEvent({...editEvent, hourTo: hourMinutesToDecimalFormat(e)})}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {/*
                    <BetterCheckBox
                        className={"mr-auto mt-2"}
                        name={"permanentChange"}
                        text={"Μόνιμο σταθερό ωράριο"}
                        checked={editEvent.permanentChange === true}
                        disabled={true}
                    />
                    */}
                    <Button variant="primary" onClick={() => handleSaveEditEvent()}>
                        Αποθήκευση
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteEventModal} onHide={() => setDeleteEventModal(false)} backdrop={"static"}>
                <Modal.Header closeButton>
                    <Modal.Title>Διαγραφή ωραρίου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Είσαστε σίγουροι ότι θέλετε να διαγράψετε το συγκεκριμένο ωράριο;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setDeleteEventModal(false)}>
                        Όχι, κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => confirmDelete()}>
                        Ναι, διαγραφή
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EmployeeProgram
