import { createSlice } from "@reduxjs/toolkit";

const initialState = ["start"];
const initialState_2 = 0;

// table call slice
export const TabsSlice = createSlice({
  name: "Tabs",
  initialState,
  reducers: {
    // Add a tab to the state
    addTab: (state, action) => {
      return [...state, action.payload];
    },
    // Delete a tab from the state
    removeTab: (state, action) => {
      return state.filter((item) => item !== action.payload);
    },
    // Shift the tabs from the statw when total number of tabs exceed 10

    shiftTabs: (state) => {
      return void state.shift();
    },
    reOrderTabs: (state, action) => {
      const [draggedTabIndex, dropIndex] = action.payload;
      if (draggedTabIndex === null || draggedTabIndex === dropIndex) return;
      const [removedTab] = state.splice(draggedTabIndex, 1);
      state.splice(dropIndex, 0, removedTab);
    }
  },
});

// table count slice
export const TabCountSlice = createSlice({
  name: "Tabs Count",
  initialState: initialState_2,
  reducers: {
    // Add count,
    addCount: (state) => (state = state + 1),

    // Subtract count
    subtractCount: (state) => (state = state - 1),
  },
});

// Update Tab Active Index
export const ActiveMainTabSlice = createSlice({
  name: "Active Main Tab",
  initialState: 0,
  reducers: {
    updateActiveMainTab: (state, action) => {
      return action.payload;
    },
  },
});
export const ActiveMainLinkSlice = createSlice({
  name: "Active Main Link",
  initialState: 0,
  reducers: {
    updateActiveMainLink: (state, action) => {
      return action.payload;
    },
  },
});

export const collapsedSideMenu = createSlice({
  name: "collapsed Side Menu",
  initialState: 0,
  reducers: {
    updateCollapsedMenuId: (state, payload ) => {
    return  state = payload;
    },
  },
});

export const { updateActiveMainTab } = ActiveMainTabSlice.actions;
export const { updateActiveMainLink } = ActiveMainLinkSlice.actions;
export const { updateCollapsedMenuId } = collapsedSideMenu.actions;
export const { addTab, removeTab, shiftTabs, reOrderTabs } = TabsSlice.actions;

const tabReducers = {
  TabsSliceReducer: TabsSlice.reducer,
  TabsCountReducer: TabCountSlice.reducer,
  ActiveMainTabReducer: ActiveMainTabSlice.reducer,
  ActiveMainLinkReducer: ActiveMainLinkSlice.reducer
};

export default tabReducers;
