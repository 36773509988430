import {isEmpty} from "../../../../../_helpers/commonFunctions";

export const colorMapping = {
    normal: "#b8d3ff",
    overwork: "#b8d3ff",
    overtime: "#b8d3ff",
    permit: "#befac3",
    dayOff: "#ffe6b5",
    noWork: "#ffb0b0",
}

export const wordMapping = {
    normal: "Κανονική εργασία",
    overwork: "Overwork",
    overtime: "Overtime",
    permit: "Άδεια εργασίας",
    dayOff: "Ανάπαυση/Ρεπό",
    noWork: "Μη εργασία",
}

export const workCategories = [
    { label: "Δια ζώσης", value: "company" },
    { label: "Εκ περιτροπής (Τηλεργασία)", value: "remote" },
]

export const dayTranslate = {
    "Monday": "Δευτέρα",
    "Tuesday": "Τρίτη",
    "Wednesday": "Τετάρτη",
    "Thursday": "Πέμπτη",
    "Friday": "Παρασκευή",
    "Saturday": "Σάββατο",
    "Sunday": "Κυριακή",
}

export const movementTypeOptions = [
    { label: "Προσέλευση", value: "arrive" },
    { label: "Αναχώρηση", value: "leave" }
]

export const delayReasonOptions = [
    { label: "Κενό", value: "" },
    { label: "Πρόβλημα στην ηλεκτροδότηση/τηλεπικοινωνίες", value: "001" },
    { label: "Πρόβλημα στα συστήματα του εργοδότη", value: "002" },
    { label: "Πρόβλημα σύνδεσης με το ΠΣ Εργάνη", value: "003" },
]

export const submissionTypeOptions = [
    { label: "Κενό", value: "" },
    { label: "Οργάνωση Χρόνου Εργασίας - Σταθερό Εβδομαδιαίο", value: "WTOWeek" },
    { label: "Δήλωση έναρξης/λήξης εργασίας εργαζομένων", value: "WRKCardSE" },
    { label: "Οργάνωση Χρόνου Εργασίας - Άδειες", value: "WTOLeave" },
]

export const permitTypes = [
    {
        "label": "Κανονική άδεια",
        "value": "ΑΔΚΑΝ"
    },
    {
        "label": "Αιμοδοτική άδεια",
        "value": "ΑΔΑΙΜ"
    },
    {
        "label": "Άδεια εξετάσεων",
        "value": "ΑΔΕΞ"
    },
    {
        "label": "Άδεια άνευ αποδοχών",
        "value": "ΑΔΑΑ"
    },
    {
        "label": "Άδεια μητρότητας",
        "value": "ΑΔΜΗ"
    },
    {
        "label": "Ειδική παροχή προστασίας της μητρότητας",
        "value": "ΑΔΠΠΜ"
    },
    {
        "label": "Άδεια πατρότητας",
        "value": "ΑΔΠΑ"
    },
    {
        "label": "Άδεια φροντίδας παιδιού",
        "value": "ΑΔΦΠ"
    },
    {
        "label": "Γονική άδεια",
        "value": "ΑΔΓΟΝ"
    },
    {
        "label": "Άδεια φροντιστή",
        "value": "ΑΔΦΡΟ"
    },
    {
        "label": "Απουσία από την εργασία για λόγους ανωτέρας βίας",
        "value": "ΑΔΑΠΑΒ"
    },
    {
        "label": "Άδεια για υποβολή σε μεθόδους ιατρικώς υποβοηθούμενης αναπαραγωγής",
        "value": "ΑΔΙΥΑ"
    },
    {
        "label": "Άδεια εξετάσεων προγεννητικού ελέγχου",
        "value": "ΑΔΠΕ"
    },
    {
        "label": "Άδεια γάμου",
        "value": "ΑΔΓΑΜ"
    },
    {
        "label": "Άδεια λόγω σοβαρών νοσημάτων των παιδιών",
        "value": "ΑΔΣΝΠ"
    },
    {
        "label": "Άδεια λόγω νοσηλείας των παιδιών",
        "value": "ΑΔΝΠ"
    },
    {
        "label": "Άδεια μονογονεϊκών οικογενειών",
        "value": "ΑΔΜΟ"
    },
    {
        "label": "Άδεια παρακολούθησης σχολικής επίδοσης τέκνου",
        "value": "ΑΔΠΣΕΤ"
    },
    {
        "label": "Άδεια λόγω ασθένειας παιδιού ή άλλου εξαρτώμενου μέλους",
        "value": "ΑΔΑΠΕΜ"
    },
    {
        "label": "Απουσία από την εργασία λόγω επικείμενου σοβαρού κινδύνου βίας ή παρενόχλησης",
        "value": "ΑΔΑΠΣΚ"
    },
    {
        "label": "Άδεια ασθένειας (ανυπαίτιο κώλυμα παροχής εργασίας)",
        "value": "ΑΔΑΣ"
    },
    {
        "label": "Άδεια απουσίας Α.Μ.Ε.Α.",
        "value": "ΑΔΑΜΕΑ"
    },
    {
        "label": "Άδεια λόγω θανάτου συγγενούς ",
        "value": "ΑΔΘΣΥΓ"
    },
    {
        "label": "Άδεια ανήλικων σπουδαστών",
        "value": "ΑΔΑΝΣΠ"
    },
    {
        "label": "Άδεια για μεταγγίσεις αίματος και των παραγώγων του ή αιμοκάθαρση",
        "value": "ΑΔΜΑΑ"
    },
    {
        "label": "Εκπαιδευτική άδεια για φοιτητές στο Κ.ΑΝ.Ε.Π. - Γ.Σ.Ε.Ε.",
        "value": "ΑΔΕΚΦ"
    },
    {
        "label": "Άδεια λόγω AIDS",
        "value": "ΑΔΣΕΑΑ"
    },
    {
        "label": "Ευέλικτες ρυθμίσεις εργασίας",
        "value": "ΑΔΕΡΕ"
    }
]

export const getWorkType = (dcObject) => {
    if (!isEmpty(dcObject.permitType)) {
        let findMatch = permitTypes.find((el) => el.value === dcObject?.permitType);
        if (findMatch) {
            return findMatch.label;
        } else {
            return "Άδεια εργασίας";
        }
    }
    if (dcObject?.workType === "normal") {
        if (dcObject?.workCategory === "company") {
            return "Κανονική εργασία";
        } else if (dcObject?.workCategory === "remote") {
            return "Τηλεργασία";
        }
    } else if (dcObject?.workType === "noWork") {
        return "Μη εργασία"
    } else if (dcObject?.workType === "dayOff") {
        return "Ανάπαυση/Ρεπό";
    } else {
        return "";
    }
}

export const decimalToHourMinutesFormat = (hour) => {
    const onlyHour = Math.floor(hour);
    const onlyMinutes = Math.floor((hour - onlyHour) * 60);

    return `${String(onlyHour).padStart(2, "0")}:${String(onlyMinutes).padStart(2, "0")}`;
}

export const hourMinutesToDecimalFormat = (hourMinutes) => {
    const split = hourMinutes.split(":");
    const hours = Number(split[0]);
    const decimal = ((Number(split[1]) * 100) / 60) / 100;

    return Number(Number(hours + decimal).toFixed(2));
}

export const getWorkTypeDDOptions = () => {
    let opt = [];
    for (let key in wordMapping) {
        if (key !== "overwork" && key !== "overtime") {
            opt.push({
                label: wordMapping[key], value: key,
            })
        }
    }
    return opt;
}

export const applyRounding = (hour, minuteStep) => {
    const minuteStepDecimal = ((minuteStep * 100) / 60) / 100;

    let bot = Math.floor(hour);
    while (bot + minuteStepDecimal <= hour) {
        bot += minuteStepDecimal;
    }
    let top = Math.ceil(hour);
    while (top - minuteStepDecimal >= hour) {
        top -= minuteStepDecimal;
    }

    return top - hour >= hour - bot ? bot : top;
}