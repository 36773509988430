import React, {useState} from "react";
import {
  Button,
} from 'react-bootstrap';
import Checkbox from '../../common/CheckBox';
const TableHead = (props) => {
  const columns = props.headColumns;
  const [toggleColsDd, settoggleColsDd] = useState(false)
  const [allChecked, setAllChecked] = useState(false)
  const selectAll = (e, type, rowkey) => {
    if (props.allowSelectionCheckbox) {
      props.headColumns['0'].inputType.select(e, type, rowkey, !allChecked);
      setAllChecked(!allChecked);
    } else if (props.tableName === "Product Variants") {
      props.headColumns['0'].inputType.select(e, type, rowkey);
    }
  }
  const toggleColumnsDropDown = (e) => {
    settoggleColsDd(!toggleColsDd);
  }

  const handleTableShowHideColumns = (field) => {
    props.toggleColumns && props.toggleColumns(field);
  }

  // Permanently Save Table in Device Local Storage
   const permanentlySaveTable = () => {
    props.saveToggleColumns && props.saveToggleColumns();
  }

  return (
    <thead>
      <tr>
        {
          columns && columns.map((th, ind) => {
            return(
              th.field === 'select_checkbox' ?
              <th className={th.field} key={ ind }>
                <input type="checkbox" defaultChecked={props.allChecked !== undefined ? props.allChecked : allChecked} onClick={(e) => selectAll(e, 'selectAll', '0')} />
              </th>
              :
                th.field === 'actions'
                ?
                  <th style={{ maxWidth: `${th.width}`, important: th.width ? 'true' : 'false' }} className={ `position-relative ` + th.field} key={ ind }>
                    { props.tableName !== 'Product Balance Report' &&  props.tableName !== 'Sales Balance Report' &&  props.tableName !== 'MyData Report' && props.tableName !== "MyData Report Buys" && props.tableName !== 'Sales Payment Report' && props.tableName !== 'Product Movement Report' &&  props.tableName !== 'Client Balance Report' && props.tableName !== 'Supplier Balance Report' ? th.name : ''}
                  { props.allowToggleColumns &&
                    <div className="et-columns-dropdown dropdown">
                      <div className="dropdown-toggle" onClick={(e) => toggleColumnsDropDown(e)}>
                        <i className="fas fa-bars"></i>
                      </div>
                      { toggleColsDd &&
                        <ul className="dropdown-menu show">
                          {props.tableName !== 'Product Balance Report' && props.tableName !== 'Sales Balance Sheet' &&  props.tableName !== 'MyData Report' && props.tableName !== "MyData Report Buys" && props.tableName !== 'Client Payments Report' && props.tableName !== 'Product Movements Report' &&  props.tableName !== 'Sales Balance Sheet' && props.tableName !== 'Buys Balance Sheet' && props.tableName !== 'Supplier Payments Report' &&
                          <li className="dropdown-item">
                            <Button onClick={() => permanentlySaveTable()} variant="primary" size="small">Permanent Save Table</Button>
                          </li>
          }
                          {
                            columns.map((col, ind) => {
                              return(
                                col.field !== 'actions' && col.field !== 'select_checkbox' &&
                                <li className="dropdown-item">
                                <Checkbox
                                    key={ind}
                                    text={col.name}
                                    className="mb-0 mr-2 centered-checkbox"
                                    name={col.field}
                                    defaultValue={!col.hiddenColumn}
                                    value={ true }
                                    onChange = {() =>
                                      handleTableShowHideColumns(col.field)
                                    }
                                />
                                </li>
                              )
                            })
                          }
                        </ul>
                      }
                    </div>
                    }
                  </th>
                :
                  !th.hiddenColumn && <th key={ind} id={(props?.emronPaymentsMonth && th.field === props?.emronPaymentsMonth ? "emronPaymentsSelected" : "")} className={th.field + (props?.emronPaymentsMonth && th.field === props?.emronPaymentsMonth ? " emronPaymentsSelected" : "")} style={{maxWidth: `${th.width}`, important: th.width ? 'true' : 'false' }}>{ th.name }</th>
            )}
          )
        }
      </tr>
    </thead>
  );
}
export default TableHead;
