import React, {useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import TimePicker from 'react-time-picker';
import {
  fetchAdvancedTypesSales,
  fetchBankAccounts,
  fetchCashRegisters,
  fetchPOS,
  fetchSalesGeneralSettings,
  postClient,
  putClient,
} from "../../../../_apis/api";
import EditableTable from "../../../primer/Editable-Table";
import QuickEdit from "../../../primer/QuickEdit";
import {useDispatch, useSelector} from "react-redux";
import {
  checkPermission,
  filterNaNValue,
  getCountryOptions,
  getCurrentUser,
  getDefaultOptionByValue,
  getDOYOptions,
  getGreekCurrencyFormat,
  getInstallationsTypes,
  getModeByInstallation,
  getNumberByInstallation,
  getOptionsByData,
  getPreNumberByInstallation,
  getSelectedInstallation,
  getSelectedPreNumbers,
  getVatCategoryOptions,
  isAlreadyExists,
  isEmpty,
  removeDuplicateObjects,
  salesMyDataStatus,
} from "../../../../_helpers/commonFunctions";
import {setSaveSales} from "../../../../_reducers/activitySlice";
import {
  deleteObjectFromArrayOfObjects,
  findObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import {
  setNewSaleClientCustomData,
  setNewSales,
  setNewSalesExtraData,
  setNewSalesNewProduct,
  setSalesDiscountObject
} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import TextArea from "../../../common/TextArea";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import {toast} from "react-toastify";
import getMyData from "../../../../_helpers/sharedmydata";
import axios from "axios";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import NewClientModal from "../Clients/new-client-modal";
import {
  getDiscountExclusiveVat,
  getNetValue,
  getNetValueByTotalValue,
  getPriceExclusiveVat,
  getProductFee,
  getProductOtherTax,
  getProductStamp,
  getProductWithholdTax,
  getSalesTaxesData,
  getSalesTotals,
  getTaxes,
  getTotalValue,
  getUnitPrice,
  getVatPercent,
  getVatValue,
  parseNumber,
  salesDuplicateProductCheck
} from "../../../../_helpers/product-sales-calculations";
import {useTranslation} from 'react-i18next';
import {vatCategoryData} from "../../../../_data/mydata/vatCategory";
import {salesLocationData} from "../../../../_data/sales/sales-payment-types";
import BetterCheckBox from "../../../common/BetterCheckBox";
import TipComponent from "../../../primer/TipComponent/TipComponent";
import moment from "moment";
import {Popover} from "antd";
import SelectAnathetousaModal from "../../../primer/POPUP-COMPONENTS/SelectAnathetousaModal";
import AccountingBox from "../../../primer/AccountingBox";
import {classicInternationalMeasurementUnits} from "../../../../_data/international_codes/international_codes";

const apiURL2 = process.env.REACT_APP_API_URL2;

const SalesNew = () => {
  const [t] = useTranslation('common');
  let date = new Date();
  const currentTime = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
  const SALES = useSelector((state) => state.SALES);
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
  const SETTINGS = useSelector((state) => state.SETTINGS);
  const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
  const CASH_DATA = useSelector((state) => state.CASH_DATA);
  const newSaleClientCustomData = useSelector((state) => state.SALES.newSaleClientCustomData);
  const clientVatCategory = newSaleClientCustomData.clientVatCategory;
  const clientVatReason = newSaleClientCustomData.clientVatReason;
  const clientCustomVat = newSaleClientCustomData.clientCustomVat;
  let countryData = getCountryOptions('all');
  const isProviderPosPayment = SALES.newSales?.documentType
      && SETTINGS.settings.advancedTypesSales.find((doc) => doc._id === SALES.newSales.documentType)
      && SALES.newSales.myDataPaymentId === "7"
      && SETTINGS.settings.advancedTypesSales.find((doc) => doc._id === SALES.newSales.documentType)?.mydataProvider === "active";

  const requestData = {
    company: company.id
  }
  const currentUser = getCurrentUser();
  const requestData2 = {
    company: company.id,
    year: company.year.toString(),
    isActive: "active",
    userId: currentUser.id
  }
  const productCodeColumns = [
    {
      label: t('SalesNew.productCodeTable.code'),
      name: "code",
    },
    {
      label: t('SalesNew.productCodeTable.alternativeCode'),
      name: "alternateCode",
    },
    {
      label: t('SalesNew.productCodeTable.productName'),
      name: "name",
    },
    {
      label: t('SalesNew.productCodeTable.alternativeName'),
      name: "alternateName",
    },
  ];
  const clientDropdownColumns = [
    {
      label: t('SalesNew.clientTable.code'),
      name: "systemCode",
    },
    {
      label: t('SalesNew.clientTable.name'),
      name: "name",
    },
    {
      label: t('SalesNew.clientTable.phone'),
      name: "phone",
    },
    {
      label: t('SalesNew.clientTable.email'),
      name: "email",
    },
    {
      label: t('SalesNew.clientTable.vatNumber'),
      name: "vatNumber",
    },
  ];
  const clientInstallationsDropdownColumns = [
    {
      label: t('SalesNew.installationTable.type'),
      name: "type",
    },
    {
      label: t('SalesNew.installationTable.address'),
      name: "address",
    }
  ];
  const corelatedDocumentColumns = [
    {
      label: t('SalesNew.corelatedTable.name'),
      name: "clientName",
    },
    {
      label: t('SalesNew.corelatedTable.clientVat'),
      name: "vat",
    },
    {
      label: t('SalesNew.corelatedTable.documentType'),
      name: "documentTypeName",
    },
    {
      label: t('SalesNew.corelatedTable.date'),
      name: "date",
    },
    {
      label: t('SalesNew.corelatedTable.preNumber'),
      name: "preNumber",
    },
    {
      label: t('SalesNew.corelatedTable.number'),
      name: "number",
    },
    {
      label: t('SalesNew.corelatedTable.totalAmount'),
      name: "totalAmount",
    },
  ];
  const [refreshPage, setRefreshPage] = useState(0);
  const [showCorelatedDocument, setShowCorelatedDocument] = useState(false);
  const [isCorelatedDocumentMandatory, setIsCorelatedDocumentMandatory] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [showCateogryConfirmation, setShowCateogryConfirmation] = useState(false);
  const [tempCatValue, setTempCatValue] = useState({});
  const [readOnlySalesTime, setReadOnlySalesTime] = useState(false);
  const [readOnlySalesDate, setReadOnlySalesDate] = useState(false);
  const [allowOlderDate, setAllowOlderDate] = useState(false);
  const [showProductTableSpinner, setProductTableSpinner] = useState('false');
  const [salesProductList, setSalesProductList] = useState(PRODUCTS_DATA.products.slice());
  const [clientsListData, setClientsListData] = useState(CLIENTS_DATA.clients.slice());
  const [clientInstallationListData, setClientInstallationListData] = useState(CLIENTS_DATA.installations.slice());
  const [corelatedDocumentsList, setCorelatedDocumentsList] = useState([]);
  const [pRequestData, setPRequestData] = useState({});
  const permissions = localStorage.getItem("permissions");
  const permissionsData = JSON.parse(permissions);
  const [showDiscountModal, setShowDiscountModal] = useState(false);

  const canHaveOtherCorrelations = !isEmpty(SALES.newSales.myDataType) && SALES.newSales.myDataType !== "1.6" && SALES.newSales.myDataType !== "2.4" && SALES.newSales.myDataType !== "5.1";
  const [otherCorrelatedInvoicesDefaultData, setOtherCorrelatedInvoicesDefaultData] = useState([]);

  // Fetch Correlated Documents List
  const fetchCorrelatedDocumentsList = (keyword, setData, newMyDataType) => {
    let toRequestData = { company: company.id, myDataType: SALES.newSales?.myDataType };
    if (newMyDataType) toRequestData.myDataType = newMyDataType;
    if (keyword) toRequestData.keyword = keyword;
    axios.post(apiURL2 + `/sale/correlated-document-list`, toRequestData, {
      headers: { 'Content-Type': 'application/json'}
    }).then((res) => {
      if (res.data && res.data.status === "200") {
        if (res.data.data && res.data.data.length > 0) {
          if (setData) {
            setData(res.data.data);
          } else {
            setCorelatedDocumentsList(res.data.data);
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  const fetchOtherCorrelatedInvoicesData = (keyword, setData, forceClient, forceMyDataPaymentId) => {
    if (canHaveOtherCorrelations) {
      let requestData = {
        company: company.id,
        year: company.year,
        client: SALES.newSales.client,
        myDataPaymentId: SALES.newSales.myDataPaymentId,
        paymentAmount: SALES.newSales.paymentAmount
      }
      if (!isEmpty(keyword)) requestData.keyword = keyword;
      if (!isEmpty(forceClient)) requestData.client = forceClient;
      if (!isEmpty(forceMyDataPaymentId)) requestData.myDataPaymentId = forceMyDataPaymentId;
      axios.post(apiURL2 + "/sale/other-correlated-invoices-list", requestData, {
        headers: { 'Content-Type': 'application/json'}
      }).then((res) => {
        if (res.data.status === "200") {
          if (setData) setData(res.data.data);
        } else {
          if (setData) setData([]);
        }
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  useEffect(() => {
      dispatch(fetchAdvancedTypesSales(requestData));
      dispatch(fetchSalesGeneralSettings(requestData2));
      dispatch(fetchPOS(requestData2));
      dispatch(fetchBankAccounts(requestData2));
      dispatch(fetchCashRegisters(requestData2));
  }, []);

  useEffect(() => {
    // Update number after save and repeat
    if (SETTINGS.settings.advancedTypesSales && SALES.newSales.numberingMode !== 'manual') {
      const findDoc = SETTINGS.settings.advancedTypesSales.find((el) => el._id === SALES.newSales.documentType);
      if (findDoc) {
        const findDocNumbering = findDoc.documentNumbering.find((el) => el.installationMasterId === SALES.newSales.installation && String(SALES.newSales.preNumber) === String(el.preNumber))
        if (findDocNumbering) {
          if (Number(findDocNumbering.number) + 1 !== Number(SALES.newSales.number)) {
            dispatch(setNewSales({...SALES.newSales, number: findDocNumbering.number + 1}));
          }
        }
      }
    }
  }, [SETTINGS.settings.advancedTypesSales])

  useEffect(() => {
    // Load Product based on selected advanced type if my data rule applies
    if (SALES.newSales.documentType !== "") {
      loadClientsListData(SALES.newSales.documentType);
      loadProductListData(SALES.newSales.documentType);
      let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES.newSales.documentType, '_id');
      if (dtData.length > 0) {
        let currentAdvancedType = dtData['0'];
        // Load Corelated document on page render
        loadCorelatedFieldSettings(currentAdvancedType);
        if (currentAdvancedType.enableMyDataRules && currentAdvancedType.enableMyDataRules === 'active') {
          let isEnabledMyDataRule = currentAdvancedType.enableMyDataRules === 'active';
          setReadOnlySalesTime(isEnabledMyDataRule);
          setReadOnlySalesDate(isEnabledMyDataRule);
          let isAllowOlderDate = currentAdvancedType.enableMyDataRules === 'active' && currentAdvancedType.allowOlderDate === 'active';
          setAllowOlderDate(isAllowOlderDate);
        }
      }
      if(SALES.newSales.installation !== undefined && SALES.newSales.installation !== ''){
        let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, SALES.newSales.installation);
        setPreNumberGreekOptions(newPreNumbers);
      }
    }
  },[])
  //useEffect for loading sale template
  useEffect(() => {
    // Load Product based on selected advanced type if my data rule applies
    if (SALES.newSales.documentType !== "") {
      loadClientsListData(SALES.newSales.documentType);
      loadProductListData(SALES.newSales.documentType);
      let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES.newSales.documentType, '_id');
      if (dtData.length > 0) {
        let currentAdvancedType = dtData['0'];
        // Load Corelated document on page render
        loadCorelatedFieldSettings(currentAdvancedType);
        if (currentAdvancedType.enableMyDataRules && currentAdvancedType.enableMyDataRules === 'active') {
          let isEnabledMyDataRule = currentAdvancedType.enableMyDataRules === 'active';
          setReadOnlySalesTime(isEnabledMyDataRule);
          setReadOnlySalesDate(isEnabledMyDataRule);
          let isAllowOlderDate = currentAdvancedType.enableMyDataRules === 'active' && currentAdvancedType.allowOlderDate === 'active';
          setAllowOlderDate(isAllowOlderDate);
        }
      }
      if(SALES.newSales.installation !== undefined && SALES.newSales.installation !== '') {
        let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, SALES.newSales.installation);
        setPreNumberGreekOptions(newPreNumbers);
        let newSalesData = {};
        let selectedInstallation = getObjectFromArrayOfObjects(selectedDocumentTypeData['documentNumbering'], SALES.newSales.installation, "installationMasterId");
        let selectedInstallationNumbering = selectedInstallation.filter(selected => selected.preNumber === SALES.newSales.preNumber);
        if (Object.keys(selectedInstallation).length > 0 && selectedInstallationNumbering.length > 0 && selectedInstallationNumbering[0].numberingMode !== "manual") {
          newSalesData.number = selectedInstallationNumbering[0].number + 1;
        } else {
          newSalesData.number = "";
        }
        dispatch(setNewSales({ ...SALES.newSales, ...newSalesData }));
      }
    }
  },[SALES.newSales.installation, SALES.newSales.preNumber])

  // Options for Document Type Dropdown
  let pMDDSettings = SETTINGS.primerMyDataData;
  const documentTypesOptions = useMemo(() => getOptionsByData(SETTINGS.settings.advancedTypesSales, "_id", "name"), [SETTINGS.settings.advancedTypesSales]);
  const waysOfSendingGoodsOptions = useMemo(() => getOptionsByData(SALES_SETTINGS.salesGeneralSettings.sendingGoods, "_id", "name"), [SALES_SETTINGS.salesGeneralSettings.sendingGoods]);
  const carNumbersOptions = useMemo(() => getOptionsByData(SALES_SETTINGS.salesGeneralSettings.carNumbers, "_id", "number"), [SALES_SETTINGS.salesGeneralSettings.carNumbers]);

  let ptTypes = SALES_SETTINGS.salesGeneralSettings.paymentTypes;
  if ((SALES.newSales?.myDataType === "5.1" || SALES.newSales?.myDataType === "5.2" || SALES.newSales?.myDataType === "11.4") && isProviderPosPayment) {
    ptTypes = ptTypes.filter((el) => el.myDataPaymentId !== "7");
  }
  const paymentTypeOptions = getOptionsByData(ptTypes, "_id", "name");

  let otherTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? pMDDSettings.otherTaxes.filter(otherTax => otherTax.taxLevel === 1) : SETTINGS.otherHoldTaxes, 'code', 'name'), [pMDDSettings, SETTINGS.otherHoldTaxes]);
  let withHoldTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? pMDDSettings.withHoldTaxes.filter(withHoldTax => withHoldTax.taxLevel === 1) : SETTINGS.withHoldTaxes, 'code', 'name'), [pMDDSettings, SETTINGS.withHoldTaxes]);
  let stampOptions = useMemo(() => getOptionsByData(pMDDSettings?.stamp !== undefined && pMDDSettings?.stamp !== '' ? pMDDSettings.stamp.filter(stamp => stamp.taxLevel === 1) : SETTINGS.stamp, 'code', 'name'), [pMDDSettings, SETTINGS.stamp]);
  let feesOptions = useMemo(() => getOptionsByData(pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? pMDDSettings.fees.filter(fee => fee.taxLevel === 1) : SETTINGS.fees, 'code', 'name'), [pMDDSettings, SETTINGS.fees]);
  otherTaxesOptions = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...otherTaxesOptions]
  withHoldTaxesOptions = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...withHoldTaxesOptions]
  stampOptions = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...stampOptions]
  feesOptions = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...feesOptions]
  const salesPurposeOptions = [{ value: "", label: t("Null")}, ...getMyData({type: 'salePurpose', settings: SETTINGS})];
  const measurementUnitOptions     = getMyData({type: 'mu', settings: SETTINGS});
  const salesMyDataStatusOptions = salesMyDataStatus();
  const [requiredPaymentType, setRequiredPaymentType] = useState(false);
  const [preNumberGreekOptions, setPreNumberGreekOptions] = useState([]);
  let selectedDocumentTypeData = [];
  let dtInstallationsOptions = [];
  let disabledFields = false;
  if (SALES.newSales.documentType === undefined || SALES.newSales.documentType === '') {
    disabledFields = true;
  } else {
    let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, SALES.newSales.documentType, '_id');
    if (dtData.length > 0) {
      selectedDocumentTypeData = dtData['0'];
      dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installation");
      dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
    }
  }

  const clientProductsColumns = [
    {
      name: "No",
      field: "no",
      width: "40px",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNew.productTable.code.code'),
      field: "code",
      width: "120px",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: salesProductList,
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('SalesNew.productTable.code.placeholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('SalesNew.productTable.name.name'),
      field: "name",
      width: "400px",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: salesProductList,
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: t('SalesNew.productTable.name.placeholder')
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t('SalesNew.productTable.quantity'),
      field: "quantity",
      width: "90px",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          maxLength: 6 // Given in the wireframe
        }
      }
    },
    {
      name: t('SalesNew.productTable.mu'),
      field: "muLabel",
      editable: false,
      width: "60px",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNew.productTable.unitPrice'),
      field: "unitPrice",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      width: "130px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7 // Given in the wireframe
        }
      }
    },
    {
      name: t('SalesNew.productTable.discount'),
      field: "discount",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      width: "80px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: "%",
      field: "isPerDiscount",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'radio',
        name: 'discountType',
        radioLogic: 'oneperrow',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: "€",
      field: "isDiscountAmount",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'radio',
        radioLogic: 'oneperrow',
        name: 'discountType',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: t('SalesNew.productTable.vat'),
      field: "vatPercentage",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('SalesNew.productTable.netValue'),
      field: "netValue",
      editable: SALES_SETTINGS.salesGeneralSettings?.forbitChange !== "active",
      width: "150px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: t('SalesNew.productTable.vatValue'),
      field: "vat",
      width: "90px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: t('SalesNew.productTable.taxes'),
      field: "taxes",
      editable: false,
      width: "90px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: t('SalesNew.productTable.totalValue'),
      field: "totalValue",
      editable: true,
      width: "150px",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
  ]

  const otherCorrelatedInvoicesTableColumns = [
    {
      name: "Παραστατικό",
      field: "docInfo",
      width: "60%",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: [
            {label: "Ημ/νια", name: "date"},
            {label: "Παραστατικό", name: "docInfo"},
            {label: "ΜΑΡΚ", name: "docMark"},
            {label: "Τύπος", name: "docMyDataType"},
          ],
          data: otherCorrelatedInvoicesDefaultData,
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: "Εισάγετε παραστατικό",
          onChangeSearchCell: (cellName, keyword, setData) => fetchOtherCorrelatedInvoicesData(keyword, setData),
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: "MARK",
      field: "docMark",
      width: "20%",
      inputType: {
        type: 'text',
      }
    },
    {
      name: "Επιπλέον πληροφορίες",
      field: "docNotes",
      width: "20%",
      inputType: {
        type: 'text',
      }
    },
  ]

  useEffect(() => {
    if (canHaveOtherCorrelations) {
      fetchOtherCorrelatedInvoicesData(null, setOtherCorrelatedInvoicesDefaultData, SALES.newSales.client);
    }
  }, [])

  const handleConfirmCategory = async () => {
    // Populate Selected Category Data
    let newSalesData = {};
    let value = tempCatValue.new;
    newSalesData.documentType = value;
    newSalesData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
    const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, value, "_id");
    if (selectedDocumentTypeData.length > 0) {
      let selectedDTData = selectedDocumentTypeData['0'];
      newSalesData.myDataType = selectedDTData.myDataType?.toString();
      newSalesData.enablePeppol = selectedDTData?.enablePeppol === "active" ? "active" : "inactive";
      if ((newSalesData.myDataType !== "1.1" && newSalesData.myDataType !== "2.1"
          && newSalesData.myDataType !== "5.1" && newSalesData.myDataType !== "5.2") || selectedDTData?.mydataProvider !== "active") {
        newSalesData.enablePeppol = "inactive";
      }
      newSalesData.paymentType = "";
      newSalesData.paymentTypeName = "";
      newSalesData.myDataPaymentId = "";
      newSalesData.bankAccount = "";
      newSalesData.bankAccountName = "";
      loadCorelatedFieldSettings(selectedDTData);
      let selectedInstallation = getSelectedInstallation(selectedDTData['documentNumbering']);
      // This applies only if selected check box is tick in the table.
      if (Object.keys(selectedInstallation).length > 0) {
        newSalesData.installation = selectedInstallation.installationMasterId;
        newSalesData.preNumber = selectedInstallation.preNumber;
        newSalesData.installationMode = selectedInstallation.numberingMode;
        newSalesData.numberingMode = selectedInstallation.numberingMode;
        newSalesData.number = selectedInstallation.number + 1;
      } else if(dtInstallationsOptions.length === 1){
        newSalesData.installation = dtInstallationsOptions[0].value;
        newSalesData.preNumber = "";
        newSalesData.number = "";
        newSalesData.installationMode = "";
        newSalesData.numberingMode = "";
      } else {
        newSalesData.installation = "";
        newSalesData.preNumber = "";
        newSalesData.number = "";
        newSalesData.installationMode = "";
        newSalesData.numberingMode = "";
      }
      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDTData['documentNumbering'], preNumberGreek, selectedInstallation.installationMasterId);
      setPreNumberGreekOptions(newPreNumbers);
      // Preposed Sales Purpose from the advanced types page
      newSalesData.salePurpose = selectedDTData.proposedSalePurpose;
      loadProductListData(value);
      loadClientsListData(value);
      // Load Product based on selected advanced type if my data rule applies
      if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active') {
        setRequiredPaymentType(true);
        let isEnabledMyDataRule = selectedDTData.enableMyDataRules === 'active';
        setReadOnlySalesTime(isEnabledMyDataRule);
        setReadOnlySalesDate(isEnabledMyDataRule);
        let isAllowOlderDate = selectedDTData.enableMyDataRules === 'active' && selectedDTData.allowOlderDate === 'active';
        setAllowOlderDate(isAllowOlderDate);
      }
      // Clear Product List on change
      newSalesData.products = [];
      //Clear Client
      newSalesData.client = "";
      newSalesData.clientName = "";
      newSalesData.clientCode = "";
      newSalesData.address = "";
      newSalesData.vat = "";
      newSalesData.doy = "";
      newSalesData.city = "";
      newSalesData.country = "";
      newSalesData.pc = "";
      newSalesData.email = "";
      newSalesData.phone = "";
      newSalesData.activity = "";
      newSalesData.vatCategory = "";
      setClientInstallationListData(CLIENTS_DATA.installations.slice());
      newSalesData.clientInstallationType = "";
      newSalesData.clientInstallationId = "";
      // Set Client Vat Category used for product vat calculation
      dispatch(setNewSaleClientCustomData({
        clientVatCategory: "Normal VAT",
        clientVatReason: "",
        clientCustomVat: 0,
      }))
      // This applies on Date and Time fields
      let isEnabledMyDataRule = selectedDTData.enableMyDataRules === 'active';
      setReadOnlySalesTime(isEnabledMyDataRule);
      setReadOnlySalesDate(isEnabledMyDataRule);
      let isAllowOlderDate = selectedDTData.enableMyDataRules === 'active' && selectedDTData.allowOlderDate === 'active';
      setAllowOlderDate(isAllowOlderDate);
    }
    dispatch(setNewSales({ ...SALES.newSales, ...newSalesData }));
    setShowCateogryConfirmation(false);
  }

  const loadClientsListDataOnChange = (value, setData) => {
    let data = {
      id: SALES.newSales.documentType,
      name: value,
      company : company.id
    }
    axios.get(process.env.REACT_APP_API_URL2 + `/client/client-list-by-advanced-type-id/${data.id}`,{params :data})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              // Remove none empty client name data
              let filteredData = deleteObjectFromArrayOfObjects(res.data.data, '', 'name');
              setData(filteredData);
            } else {
              setData([]);
            }
          } else {
            setData([]);
          }
        }).catch((err) => {
          console.log(err);
        })
  }
  const onChangeSearch = (keyword,setData) => {
    if(keyword.length >= 2) {
      loadClientsListDataOnChange(keyword,setData)
    }
  }

  const loadClientsListData = (value) => {
    const data = {
      id: value,
      company : company.id,
      name:''
    }
    axios.get(process.env.REACT_APP_API_URL2 + `/client/client-list-by-advanced-type-id/${value}`,{params :data})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              // Remove none empty client name data
              let filteredData = deleteObjectFromArrayOfObjects(res.data.data, '', 'name');
              setClientsListData([...filteredData]);
            } else {
              setClientsListData([]);
            }
          } else {
            setClientsListData([]);
          }
        }).catch((err) => {
          console.log(err);
        })
  }

  const loadProductListData = (value) => {
    let data = {
      id: value,
      name: '',
      company : company.id,
    }
    setPRequestData({...data});
    axios.get(process.env.REACT_APP_API_URL2 + `/sale/product-list-by-advanced-type-id/${data.id}`,{params :data})
        .then(res => {
          if (res.data && res.data.status === '200') {
            if (res.data.data) {
              setSalesProductList(res.data.data);
            } else {
              setSalesProductList([]);
            }
          } else {
            setSalesProductList([]);
          }
        }).catch((err) => {
          console.log(err);
        })
  }

  const loadCorelatedFieldSettings = (selectedDTData) => {
    if (selectedDTData.myDataType && selectedDTData.myDataType !== '') {
      // Show field only if "Document type" supports "MyData Document Type"
      // Is settings-Document Types-Advanced types that is 1.6, 2.4, 5.1
      if (
          selectedDTData.myDataType.toString() === '1.6' ||
          selectedDTData.myDataType.toString() === '2.4' ||
          selectedDTData.myDataType.toString() === '5.1'
      ) {
        fetchCorrelatedDocumentsList(null, null, selectedDTData.myDataType.toString());
        setShowCorelatedDocument(true);
      } else {
        setShowCorelatedDocument(false);
      }

      // If Advanced type is 5.1, field is mandatory, if not, field is not mandatory.
      if (selectedDTData.myDataType.toString() === '5.1') {
        setIsCorelatedDocumentMandatory(true);
      } else {
        setIsCorelatedDocumentMandatory(false);
      }
    }
  }

  const getCorelatedDocumentName = (selectedCDoc) => {
    let dName = `${selectedCDoc.documentTypeName}`;
    if (selectedCDoc.preNumber && selectedCDoc.preNumber !== 'noprefix') {
      dName += ` | ${selectedCDoc.preNumber}`
    }
    if (selectedCDoc.number) {
      dName += ` | ${selectedCDoc.number}`;
    }
    return dName;
  }

  const handleOnChange = async (e, type = "default", ddname = '') => {
    if (!ACTIVITY.saveSales) dispatch(setSaveSales(true));

    let name;
    let value = '';
    if (type === 'dd' || type === 'ac') {
      value = e.value;
      name = ddname;
    } else if (type === 'dp') {
      value = e;
      name = ddname;
    } else if (type === 'acdd') {
      e = e.row;
      value = e._id;
      name = ddname;
    } else if (type === 'tp') {
      value = e;
      name = ddname;
    } else if (type === "checkbox") {
      value = e.target.checked;
      name = ddname;
    } else if (type === "bettercheckbox") {
      value = e.target.checked ? "active" : "inactive";
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }

    let salesData = Object.assign({}, SALES);
    let newSalesData = {};
    if(name === "time"){
      if(value !== currentTime && value !== null){
        newSalesData.customTime = "true";
      }
    }
    if(name === "date"){
      if(value !== date && value !== null){
        newSalesData.customTime = "true";
      }
    }
    if (name === 'documentType' && salesData.newSales.documentType !== value) {
      if (!isEmpty(SALES_SETTINGS?.salesGeneralSettings?.proposedSaleNotes)) {
        newSalesData["notes"] = SALES_SETTINGS.salesGeneralSettings.proposedSaleNotes;
      }
      if (salesData.newSales.documentType !== "") {
        newSalesData.otherCorrelatedInvoices = [];
        // Show confirmation before category change.
        setShowCateogryConfirmation(true);
        setTempCatValue({ e: e, new: value, old: salesData.newSales.documentType });
      } else {
        // Populate Selected Category Data
        newSalesData[name] = value;
        let dtData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, value, '_id');
        if (dtData.length > 0) {
          dtInstallationsOptions = getOptionsByData(dtData['0']['documentNumbering'], "installationMasterId", "installation");
          dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
        }
        newSalesData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
        const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, value, "_id");
        if (selectedDocumentTypeData.length > 0) {
          let selectedDTData = selectedDocumentTypeData['0'];
          newSalesData.myDataType = selectedDTData?.myDataType ? selectedDTData.myDataType.toString() : "";
          newSalesData.enablePeppol = selectedDTData?.enablePeppol === "active" ? "active" : "inactive";
          if ((newSalesData.myDataType !== "1.1" && newSalesData.myDataType !== "2.1"
              && newSalesData.myDataType !== "5.1" && newSalesData.myDataType !== "5.2") || selectedDTData?.mydataProvider !== "active") {
            newSalesData.enablePeppol = "inactive";
          }
          loadCorelatedFieldSettings(selectedDTData);
          let selectedInstallation = getSelectedInstallation(selectedDTData['documentNumbering']);
          if (Object.keys(selectedInstallation).length > 0) {
            newSalesData.installation = selectedInstallation.installationMasterId;
            newSalesData.preNumber = selectedInstallation.preNumber;
            newSalesData.installationMode = selectedInstallation.numberingMode;
            newSalesData.numberingMode = selectedInstallation.numberingMode;
            if(newSalesData.numberingMode !== "manual") {
              newSalesData.number = selectedInstallation.number + 1;
            }
          } else if(dtInstallationsOptions.length === 1){
            newSalesData.installation = dtInstallationsOptions[0].value;
            newSalesData.preNumber = "";
            newSalesData.number = "";
            newSalesData.installationMode = "";
            newSalesData.numberingMode = "";
          } else {
            newSalesData.installation = "";
            newSalesData.preNumber = "";
            newSalesData.number = "";
            newSalesData.installationMode = "";
            newSalesData.numberingMode = "";
          }

          // This will pre number given in document number table
          let newPreNumbers = getSelectedPreNumbers(selectedDTData['documentNumbering'], preNumberGreek, newSalesData.installation);
          setPreNumberGreekOptions(newPreNumbers);
          // Preposed Sales Purpose from the advanced types page
          newSalesData.salePurpose = selectedDTData.proposedSalePurpose;

          // Load Product based on selected advanced type if my data rule applies
          if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active') {
            loadProductListData(value);
            loadClientsListData(value);
            setRequiredPaymentType(true);
            let isEnabledMyDataRule = selectedDTData.enableMyDataRules === 'active';
            console.log(selectedDTData)
            setReadOnlySalesTime(isEnabledMyDataRule);
            setReadOnlySalesDate(isEnabledMyDataRule);
            let isAllowOlderDate = selectedDTData.enableMyDataRules === 'active' && selectedDTData.allowOlderDate === 'active';
            setAllowOlderDate(isAllowOlderDate);
          }
        }
      }
    } else {
      newSalesData[name] = value;
    }
    // newSalesData['date'] = new Date(SALES.newSales.date);
    if (name === 'client') {
      if (canHaveOtherCorrelations) {
        fetchOtherCorrelatedInvoicesData(null, setOtherCorrelatedInvoicesDefaultData, value);
      }
      newSalesData.otherCorrelatedInvoices = [];
      // populating client values on the sales form
      // Same code is used with quick edit client to repopulate the data after change. Starts from line number 933.
      let selectedClient = getObjectFromArrayOfObjects(clientsListData, value, '_id');
      // Get Client Data from the API.
      await axios.get(apiURL2 + `/client/get-client-id-by/${value}`)
          .then((res) => {
            if (res.data && res.data.status === "200") {
              selectedClient['0'] = res.data.data;
            }
          }).catch((err) => {
            console.log(err);
          })
      if (selectedClient.length > 0) {
        let sClient = selectedClient['0'];
        newSalesData.clientName = sClient.name;
        newSalesData.clientCode = sClient.systemCode; // It is the client code
        newSalesData.address = sClient.address;
        newSalesData.vat = sClient.vatNumber;
        newSalesData.doy = getLabelByValue(getDOYOptions(), Number(sClient.DOY), 'value');
        newSalesData.city = sClient.city;
        newSalesData.country = sClient.country;
        newSalesData.pc = sClient.postalCode;
        newSalesData.email = sClient.email;
        newSalesData.phone = sClient.phone;
        newSalesData.activity = sClient.activity;
        newSalesData.vatCategory = sClient.vatCategory;
        sClient.installations.map(installation => {
          installation.type = getLabelByValue(getInstallationsTypes(), installation.type, 'value');
          return installation;
        })
        setClientInstallationListData(sClient.installations);
        newSalesData.clientInstallationType = sClient.installations[0]?.type;
        newSalesData.clientInstallationId = sClient.installations[0]?.no;
        // Set Client Vat Category used for product vat calculation
        dispatch(setNewSaleClientCustomData({
          clientVatCategory: sClient?.vatCategory ? sClient.vatCategory : "Normal VAT",
          clientVatReason: sClient?.vatReason ? sClient.vatReason : "",
          clientCustomVat: !isEmpty(sClient?.customVat) ? sClient.customVat : 0,
        }))
      }
    }

    if(name === 'clientInstallation'){
      let selectedClientInstallation = "";
      clientInstallationListData.map(installation => {
        if(value === installation._id){
          selectedClientInstallation = installation;
        }
        return installation;
      })
      if(selectedClientInstallation !== ""){
        newSalesData.clientInstallationType = selectedClientInstallation.type;
        newSalesData.clientInstallationId = selectedClientInstallation.no;
        newSalesData.address = selectedClientInstallation.address;
        newSalesData.doy = getLabelByValue(getDOYOptions(), Number(selectedClientInstallation.DOY), 'value');
        newSalesData.city = selectedClientInstallation.city;
        newSalesData.pc = selectedClientInstallation.postalCode;
      }
    }

    if (name === 'paymentType') {
      newSalesData.otherCorrelatedInvoices = [];
      newSalesData.paymentTypeName = getLabelByValue(paymentTypeOptions, value, 'value');

      // This is will update the pre selected POS / Bank
      let paymentTypesData = getObjectFromArrayOfObjects(SALES_SETTINGS.salesGeneralSettings.paymentTypes, value, '_id');
      if (paymentTypesData && paymentTypesData.length > 0) {
        let selectedPaymentType = paymentTypesData['0'];
        newSalesData.myDataPaymentId = selectedPaymentType?.myDataPaymentId;
        if (canHaveOtherCorrelations) {
          fetchOtherCorrelatedInvoicesData(null, setOtherCorrelatedInvoicesDefaultData, null, selectedPaymentType?.myDataPaymentId);
        }
        if (selectedPaymentType?.type === "pos") {
          newSalesData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
        }

        if (selectedPaymentType?.type === "bank-account") {
          newSalesData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
        }

        if (selectedPaymentType?.type === "cash") {
          newSalesData.bankAccount = "";
        }

        if (SALES.newSales?.enablePeppol === "active" && !isEmpty(newSalesData.paymentTypeName) && isEmpty(SALES.newSales?.peppol?.paymentMethodInfo)) {
          newSalesData.peppol = {};
          if (SALES.newSales.peppol) newSalesData.peppol = {...SALES.newSales.peppol}
          newSalesData.peppol.paymentMethodInfo = newSalesData.paymentTypeName;
        }
      }
    }

    // Get Corelated Document Name
    if (name === 'corelatedDocument') {
      let doc;
      let selectedCDoc = findObjectFromArrayOfObjects(corelatedDocumentsList, value, '_id');
      if (selectedCDoc) {
        doc = selectedCDoc;
      } else {
        doc = e;
      }
      newSalesData.corelatedDocumentName = getCorelatedDocumentName(doc);
      newSalesData.otherCorrelatedInvoices = [];
      let copyKeys = ["client", "clientName", "clientCode", "address", "vat", "doy", "city", "country",
        "pc", "email", "phone", "activity", "vatCategory", "clientType", "clientInstallationType", "clientInstallationId", "installation"]
      await axios.get(apiURL2 + `/client/get-client-id-by/${doc.client}`)
        .then((res) => {
          if (res.data && res.data.status === "200") {
            dispatch(setNewSaleClientCustomData({
              clientVatCategory: res.data.data?.vatCategory ? res.data.data?.vatCategory : "Normal VAT",
              clientVatReason: res.data.data?.vatReason ? res.data.data?.vatReason : "",
              clientCustomVat: !isEmpty(res.data.data?.customVat?.customVat) ? res.data.data?.customVat : 0,
            }))
          }
        }).catch((err) => {
            console.log(err);
          })
      for (let key of copyKeys) {
        newSalesData[key] = doc[key];
      }
      if (doc.enablePeppol === "active" && doc?.peppol && SALES.newSales.myDataType === "5.1") {
        newSalesData.enablePeppol = "active";
        newSalesData.peppol = {};
        newSalesData.peppol.projectReference = "";
        newSalesData.peppol.partyIdentification = doc.peppol.partyIdentification;
        newSalesData.peppol.contractDocumentReference = doc.peppol.contractDocumentReference;
        newSalesData.peppol.dueDate = moment(SALES.newSales.date).format("YYYY-MM-DD");
        newSalesData.peppol.invoiceDocumentReference
            = `${company.vatNumber}|${moment(doc.date).utc().format("DD/MM/YYYY")}|${newSalesData.installation}|${doc.myDataType}|${doc.preNumber === "noprefix" ? "0" : doc.preNumber}|${doc.number}`;
        //newSalesData.peppol.documentMeasurementUnit = doc.peppol.documentMeasurementUnit;
      }
    }

    // Bank Account name
    if (name === 'bankAccount') {
      newSalesData.bankAccountName = getLabelByValue(bankOptions, value, 'value')
    }
    // Update Installtion Field Values on Change
    if (name === 'installation') {
      newSalesData.installationName = getLabelByValue(dtInstallationsOptions, value, 'value');
      newSalesData.preNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newSalesData.installationMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newSalesData.numberingMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      if(newSalesData.numberingMode !== "manual") {
        newSalesData.number = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId') + 1;
      }
      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, value);
      setPreNumberGreekOptions(newPreNumbers);
    }
    if(name === "date" && (readOnlySalesDate === false || (readOnlySalesDate === true  && allowOlderDate === true))){
      if(readOnlySalesDate === true  && allowOlderDate === true){
        let allowedDateHours = checkPermission("custom-logistirio", permissionsData) === false ? pMDDSettings.otherData.filter(otherData => otherData.name === "allowOlderDate")[0].value : "999999";
        let allowedDate = new Date();
        allowedDate.setHours(allowedDate.getHours() - Number(allowedDateHours));
        if(allowedDate > new Date(value)){
          toast.error("You are not allowed to issue invoices before " + allowedDate.toString());
          newSalesData['date'] = new Date();
        } else {
          newSalesData['date'] = new Date(value);
          newSalesData['transmissionFailure'] = "1";
        }
      } else {
        newSalesData['date'] = new Date(value);
      }
    }
    if (name === "salePurpose") {
      newSalesData['salePurposeName'] = "";
    }
    // It will update the last available number from the API.
    if ((name === 'documentType' || name === 'preNumber' || name === 'installation') &&
        (
            (salesData.newSales.documentType !== '' || newSalesData.documentType !== '') &&
            (salesData.newSales.preNumber !== '' || newSalesData.preNumber !== '') &&
            (salesData.newSales.installation !== '' || newSalesData.installation !== '')
        )) {
      let checkAtParams = {};
      checkAtParams.id = !newSalesData.documentType ? salesData.newSales.documentType : newSalesData.documentType;
      checkAtParams.preNumber = !newSalesData.preNumber ? salesData.newSales.preNumber : newSalesData.preNumber;
      checkAtParams.installation = !newSalesData.installation ? salesData.newSales?.installation?.toString() : newSalesData?.installation?.toString();

      if (checkAtParams.id && checkAtParams.preNumber && checkAtParams.installation && allowOlderDate === false) {
        await axios.post(apiURL2 + `/settings/add-setting-advance-type-check-sale`, JSON.stringify(checkAtParams), { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
              if (res.data && res.data.status === '200') {
                if (res.data.number !== undefined && allowOlderDate === false) {
                  newSalesData.number = parseInt(res.data.number) + 1;
                }
              }
              dispatch(setNewSales({ ...salesData.newSales, ...newSalesData }));
            }).catch((err) => {
              console.log(err);
            })
      } else {
        dispatch(setNewSales({ ...salesData.newSales, ...newSalesData }));
      }
    } else {
      dispatch(setNewSales({ ...salesData.newSales, ...newSalesData }));
    }
  }

  const handleOnChangeExtraData = (e, type = "default", ddname = '') => {
    // Set if editing the form
    if (!ACTIVITY.saveSales) {
      dispatch(setSaveSales(true));
    }
    let name;
    let value;

    if (type === 'dd' || type === 'ac') {
      value = e.value;
      name = ddname;
    } else if (type === 'dp') {
      value = e;
      name = ddname;
    } else if (type === 'tp') {
      value = e;
      name = ddname;
    } else if (type === "acb") {
      value = e.target.value;
      name = ddname;
    } else if (type === "cb") {
      value = String(e.target.checked)
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }

    let extraData = {...SALES.newSales.extraData};
    extraData[name] = String(value);

    if (name === 'wayOfSendingGoods') {
      extraData.wayOfSendingGoodsName = getLabelByValue(waysOfSendingGoodsOptions, value, 'value');
    } else if (name === 'carNumber') {
      extraData.carNumberName = getLabelByValue(carNumbersOptions, value, 'value');
    } else if (name === 'otherTax') {
      extraData.otherTax = value;
      if (!isEmpty(value)
          && SETTINGS.primerMyDataData?.otherTaxes?.find((el) => el.code === value)?.taxPer === "false"
          && !isNaN(SETTINGS.primerMyDataData?.otherTaxes?.find((el) => el.code === value)?.tax)
          && !isEmpty(SETTINGS.primerMyDataData?.otherTaxes?.find((el) => el.code === value)?.tax)) {
        extraData.otherTaxEuro = parseFloat(SETTINGS.primerMyDataData?.otherTaxes?.find((el) => el.code === value)?.tax).toFixed(2);
      }
    } else if (name === 'withHoldTax') {
      extraData.withHoldTax = value;
      if (!isEmpty(value)
          && SETTINGS.primerMyDataData?.withHoldTaxes?.find((el) => el.code === value)?.taxPer === "false"
          && !isNaN(SETTINGS.primerMyDataData?.withHoldTaxes?.find((el) => el.code === value)?.tax)
          && !isEmpty(SETTINGS.primerMyDataData?.withHoldTaxes?.find((el) => el.code === value)?.tax)) {
        extraData.witholdTaxEuro = parseFloat(SETTINGS.primerMyDataData?.withHoldTaxes?.find((el) => el.code === value)?.tax).toFixed(2);
      }
    } else if (name === 'fee') {
      extraData.fee = value;
      if (!isEmpty(value)
          && SETTINGS.primerMyDataData?.fees?.find((el) => el.code === value)?.taxPer === "false"
          && !isNaN(SETTINGS.primerMyDataData?.fees?.find((el) => el.code === value)?.tax)
          && !isEmpty(SETTINGS.primerMyDataData?.fees?.find((el) => el.code === value)?.tax)) {
        extraData.feeEuro = parseFloat(SETTINGS.primerMyDataData?.fees?.find((el) => el.code === value)?.tax).toFixed(2);
      }
    } else if (name === 'stamp') {
      extraData.stamp = value;
      if (!isEmpty(value)
          && SETTINGS.primerMyDataData?.stamp?.find((el) => el.code === value)?.taxPer === "false"
          && !isNaN(SETTINGS.primerMyDataData?.stamp?.find((el) => el.code === value)?.tax)
          && !isEmpty(SETTINGS.primerMyDataData?.stamp?.find((el) => el.code === value)?.tax)) {
        extraData.stampEuro = parseFloat(SETTINGS.primerMyDataData?.stamp?.find((el) => el.code === value)?.tax).toFixed(2);
      }
    } else if (name === "withHoldTaxAsLine") {
      if (value === "true") {
        if (!isEmpty(SETTINGS.primerMyDataData?.withHoldTaxes?.find((el) => el.code === extraData.withHoldTax)?.name) && isEmpty(extraData?.withHoldTaxAsLineText)) {
          extraData.withHoldTaxAsLineText = SETTINGS.primerMyDataData?.withHoldTaxes?.find((el) => el.code === extraData.withHoldTax)?.name;
        }
      }
    } else if (name === "otherTaxAsLine") {
      if (value === "true") {
        if (!isEmpty(SETTINGS.primerMyDataData?.otherTaxes?.find((el) => el.code === extraData.otherTax)?.name) && isEmpty(extraData?.otherTaxAsLineText)) {
          extraData.otherTaxAsLineText = SETTINGS.primerMyDataData?.otherTaxes?.find((el) => el.code === extraData.otherTax)?.name;
        }
      }
    } else if (name === "feeAsLine") {
      if (value === "true") {
        if (!isEmpty(SETTINGS.primerMyDataData?.fees?.find((el) => el.code === extraData.fee)?.name) && isEmpty(extraData?.feeAsLineText)) {
          extraData.feeAsLineText = SETTINGS.primerMyDataData?.fees?.find((el) => el.code === extraData.fee)?.name;
        }
      }
    } else if (name === "stampAsLine") {
      if (value === "true") {
        if (!isEmpty(SETTINGS.primerMyDataData?.stamp?.find((el) => el.code === extraData.stamp)?.name) && isEmpty(extraData?.stampAsLineText)) {
          extraData.stampAsLineText = SETTINGS.primerMyDataData?.stamp?.find((el) => el.code === extraData.stamp)?.name;
        }
      }
    } else if (name === "deductionsAsLine") {
      if (value === "true" && isEmpty(extraData.deductionsAsLineText)) {
        extraData.deductionsAsLineText = "Γενικές κρατήσεις"
      }
    }

    let productTotals = getSalesTotals(SALES.newSales.products, SETTINGS);
    let productSalesTaxes = getSalesTaxesData(SALES.newSales.products, extraData, productTotals, SETTINGS);
    productSalesTaxes.map((salesTax) => {
      if(salesTax.vatPercentage === "Invoice Level Tax"){
        extraData.witholdTaxEuro = salesTax.withholdTaxes;
        extraData.withHoldTaxEuro = salesTax.withholdTaxes;
        extraData.otherTaxEuro = salesTax.otherTaxes;
        extraData.feeEuro = salesTax.fees;
        extraData.stampEuro = salesTax.stamp;
      }
      return salesTax;
    })
    let totalTaxes = 0;
    let withHoldTaxes = 0;
    let deductions = 0;
    if(productSalesTaxes.length > 0 && productSalesTaxes[0].vatPercentage === "Invoice Level Tax"){
      totalTaxes = parseFloat( (Number(productSalesTaxes[0].otherTaxes) + Number(productSalesTaxes[0].fees) + Number(productSalesTaxes[0].stamp)).toString());
      withHoldTaxes = parseFloat(productSalesTaxes[0].withholdTaxes).toFixed(2);
      deductions = parseFloat(productSalesTaxes[0].deductions).toFixed(2);
    }
    if(totalTaxes !== 0 || withHoldTaxes !== 0 || deductions !== 0){
      productTotals.paymentAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes) - parseFloat(deductions)).toFixed(2);
      productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes)).toFixed(2);
    }
    dispatch(setNewSales({ ...SALES.newSales,salesTaxes: productSalesTaxes, ...productTotals, extraData: extraData }));
    //dispatch(setNewSalesExtraData(extraData));
  }

  const quickClientFields = [
    {
      name: t('SalesNew.quickClientTable.name'),
      field: "name",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNew.quickClientTable.vatCategory'),
      field: "vatCategory",
      editable: true,
      inputType: {
        options: getVatCategoryOptions(),
        type: 'select',
      }
    },
    {
      name: t('SalesNew.quickClientTable.vatNumber'),
      field: "vatNumber",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNew.quickClientTable.phone'),
      field: "phone",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNew.quickClientTable.email'),
      field: "email",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNew.quickClientTable.address'),
      field: "address",
      editable: true,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesNew.quickClientTable.city'),
      field: "city",
      editable: true,
      inputType: {
        type: "text",
      },
    },
  ];

  const [updateSaleAmounts, setUpdateSaleAmounts] = useState(false);

  const salesProductListActions = {
    insert: async (e, data) => {

      // Set if editing the form
      if (!ACTIVITY.saveSales) {
        dispatch(setSaveSales(true));
      }
      let productData = null;
      // Get Product By Code
      if (data && data?.field === 'code') {
        await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.value)}`,{params :company})
            .then(res => {
              if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                productData = res.data.data['0'];
              }
            }).catch((err) => {
              console.log(err);
            })
      }
      // Get Product By Name
      if (data && data?.field === 'name') {
        await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.value)}`,{params :company})
            .then(res => {
              if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                productData = res.data.data['0'];
              }
            }).catch((err) => {
              console.log(err);
            })
      }
      if (productData !== null && Object.keys(productData).length > 0) {
        let productExists = isAlreadyExists(SALES.newSales.products, data.field, data.value);
        if (productExists) {
          toast.error('Product already added in the table.');
        } else {
          if (SALES.newSales.enablePeppol === "active" && !productData.cpvCode) {
            toast.error("Το είδος που επιλέξατε δεν έχει κωδικό CPV καθώς απαιτείται στις δημόσιες συμβάσεις.");
            return;
          }
          // Set Loading true
          setProductTableSpinner('true');
          if (e.key !== "Enter") {
            let tempNewProduct = Object.assign({}, SALES.newSalesNewProduct);
            if (SALES.newSales.enablePeppol === "active") {
              if (productData.cpvCode) {
                tempNewProduct.cpvCode = productData.cpvCode;
              }
              tempNewProduct.peppolMeasurementUnit = classicInternationalMeasurementUnits[String(productData.measurementUnit)]
              if (isEmpty(tempNewProduct.peppolMeasurementUnit)) tempNewProduct.peppolMeasurementUnit = "H87";
            }
            if (productData?.showAlternativeNameOnPrint === "true") {
              tempNewProduct.showAlternativeNameOnPrint = "true";
              tempNewProduct.alternativeName = productData.alternateName;
            }
            if (productData?.showAlternativeCodeOnPrint === "true") {
              tempNewProduct.showAlternativeCodeOnPrint = "true";
              tempNewProduct.alternativeCode = productData.alternateCode;
            }
            tempNewProduct.name = productData?.name;
            tempNewProduct.code = productData?.code;
            tempNewProduct.quantity = 1;
            tempNewProduct.mu = productData?.measurementUnit;
            tempNewProduct.muLabel = getLabelByValue(measurementUnitOptions, productData?.measurementUnit.toString(), 'value');
            tempNewProduct.discount = 0;
            tempNewProduct.taxes = 0;
            tempNewProduct.isPerDiscount = "false";
            tempNewProduct.isDiscountAmount = "true";
            if (!isEmpty(productData?.deductionsEuro) && parseFloat(productData?.deductionsEuro) > 0) {
              tempNewProduct.deductionsEuro = Number(parseFloat(productData?.deductionsEuro).toFixed(2));
            }
            if (productData?.vatIncluded === "true") {
              const price = parseFloat(productData.price);
              const vat = isNaN(productData.vat) ? 0 : parseFloat(productData.vat);
              if (vat > 0) {
                tempNewProduct.unitPrice = Number(Number(price/(1 + vat/100)).toFixed(2));
              } else {
                tempNewProduct.unitPrice = parseNumber(productData.price);
              }
            } else {
              tempNewProduct.unitPrice = parseNumber(productData.price); // Direct price from the product preferred price
            }
            tempNewProduct[data.field] = data.value; // Updates from table edit
            if (data.field === 'isPerDiscount' && (data.value === "on" || data.value === "true")) {
              tempNewProduct.isPerDiscount = "true";
              tempNewProduct.isDiscountAmount = "false";
            }
            if (data.field === 'isDiscountAmount' && (data.value === "on" || data.value === "true")) {
              tempNewProduct.isPerDiscount = "false";
              tempNewProduct.isDiscountAmount = "true";
            }
            // Get Current Product Details with the API if product and client are selected.
            if (tempNewProduct.code && tempNewProduct.code !== '' && SALES.newSales.client && SALES.newSales.client !== '') {
              await axios
                  .post(`${process.env.REACT_APP_API_URL2}/category/get-product-code-by-price`, { code: tempNewProduct.code, client: SALES.newSales.client, company: requestData?.company})
                  .then((res) => {
                    if (res.status === 200) {
                      console.log('product details loading success');
                      sessionStorage.setItem("salesSelectedProductData", JSON.stringify(res.data.data));
                      let pldata = res.data?.data;
                      tempNewProduct.vatType = !pldata?.vatType ? pldata.vatIncluded : pldata.vatType.toString();
                      tempNewProduct.isPerDiscount = pldata.isPerDiscount;
                      tempNewProduct.isDiscountAmount = pldata.isDiscountAmount;
                      tempNewProduct.vatPercentage = pldata.vat;
                      tempNewProduct.discount = pldata.discount; // Discount from the price list table
                      tempNewProduct.unitPrice = filterNaNValue(pldata?.productPrice ? pldata.productPrice : pldata.price); // Price from the price list table
                      console.log(tempNewProduct)
                    } else {
                      console.log('Product details loading fail.');
                    }
                  }).catch(error => {
                    setProductTableSpinner('false');
                    console.log(error);
                    console.log('Unable to fetch product price list.');
                  })
            }
            // Fix for loading calculation on insert
            let discountType = 'euro';
            if (tempNewProduct.isPerDiscount === 'true') {
              discountType = 'per';
            }
            const myDataData = productData.mydata.filter(myData => String(myData.invoiceType) === SALES.newSales.myDataType.toString() && (isEmpty(myData.rowType) || myData.rowType === "income"));
            if(productData?.isTaxLine === "true"){
              tempNewProduct.vatPercentage  = getVatPercent(clientVatCategory, productData.vat, clientCustomVat);
              if (clientVatCategory === "Reduced VAT" && ["24", "13", "6"].includes(String(productData.vat))) {
                tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, String(tempNewProduct.vatPercentage), 'percentage', 'code', 'percentage').label;
              } else if (clientVatCategory === "Zero VAT") {
                tempNewProduct.vatId = "7";
              } else {
                tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
              }
              tempNewProduct.vatReason      = clientVatReason !== "" ? clientVatReason : productData?.vatReason;
              tempNewProduct.unitPrice      = getPriceExclusiveVat(tempNewProduct.unitPrice, tempNewProduct?.vatPercentage, tempNewProduct?.vatType);
              tempNewProduct.discount       = getDiscountExclusiveVat(tempNewProduct.discount, tempNewProduct?.vatPercentage, tempNewProduct?.vatType, discountType);
              tempNewProduct.netValue       = 0;
              tempNewProduct.withHoldTax    = productData.withholdTax;
              tempNewProduct.otherTax       = productData.otherTaxes;
              tempNewProduct.stamp          = productData.stamp;
              tempNewProduct.fee           = productData.fee;
              tempNewProduct._id            = productData._id;
              tempNewProduct.isTaxLine      = productData.isTaxLine;
              if( myDataData.length > 0) {
                tempNewProduct.classificationCategory = myDataData[0].category;
                tempNewProduct.classificationType = myDataData[0].type;
              } else {
                tempNewProduct.classificationCategory = '';
                tempNewProduct.classificationType = '';
              }
              tempNewProduct.isTaxLineWithoutVat      = productData.isTaxLineWithoutVat;
              tempNewProduct.taxes          = getNetValue(tempNewProduct.unitPrice, tempNewProduct.quantity, tempNewProduct.discount, discountType);
              tempNewProduct.otherTaxEuro   = getProductOtherTax(tempNewProduct, SETTINGS);
              tempNewProduct.witholdTaxEuro = getProductWithholdTax(tempNewProduct, SETTINGS);
              tempNewProduct.feeEuro        = getProductFee(tempNewProduct, SETTINGS);
              tempNewProduct.stampEuro      = getProductStamp(tempNewProduct, SETTINGS);
              tempNewProduct.vat            = getVatValue(tempNewProduct.taxes, tempNewProduct.vatPercentage);
              tempNewProduct.totalValue     = getTotalValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, tempNewProduct.taxes);
            } else {
              tempNewProduct.vatPercentage = getVatPercent(clientVatCategory, productData.vat, clientCustomVat);
              if (clientVatCategory === "Reduced VAT" && ["24", "13", "6"].includes(String(productData.vat))) {
                tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, String(tempNewProduct.vatPercentage), 'percentage', 'code', 'percentage').label;
              } else if (clientVatCategory === "Zero VAT") {
                tempNewProduct.vatId = "7";
              } else {
                tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
              }
              tempNewProduct.vatReason = clientVatReason !== "" ? clientVatReason : productData?.vatReason;
              tempNewProduct.unitPrice = getPriceExclusiveVat(tempNewProduct.unitPrice, tempNewProduct?.vatPercentage, tempNewProduct?.vatType);
              tempNewProduct.discount = getDiscountExclusiveVat(tempNewProduct.discount, tempNewProduct?.vatPercentage, tempNewProduct?.vatType, discountType);
              tempNewProduct.netValue = getNetValue(tempNewProduct.unitPrice, tempNewProduct.quantity, tempNewProduct.discount, discountType);
              tempNewProduct.withHoldTax = productData.withholdTax;
              tempNewProduct.otherTax = productData.otherTaxes;
              tempNewProduct.stamp = productData.stamp;
              tempNewProduct.fee = productData.fee;
              tempNewProduct.stamp = productData.stamp;
              tempNewProduct._id = productData._id;
              if(myDataData.length > 0){
                tempNewProduct.classificationCategory = myDataData[0].category;
                tempNewProduct.classificationType = myDataData[0].type;
              } else {
                tempNewProduct.classificationCategory = '';
                tempNewProduct.classificationType = '';
              }
              tempNewProduct.taxes = getTaxes(tempNewProduct, SETTINGS);
              tempNewProduct.otherTaxEuro = getProductOtherTax(tempNewProduct, SETTINGS);
              tempNewProduct.witholdTaxEuro = getProductWithholdTax(tempNewProduct, SETTINGS);
              tempNewProduct.feeEuro = getProductFee(tempNewProduct, SETTINGS);
              tempNewProduct.stampEuro        = getProductStamp(tempNewProduct, SETTINGS);
              tempNewProduct.vat = getVatValue(tempNewProduct.netValue, tempNewProduct.vatPercentage);
              tempNewProduct.totalValue = getTotalValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, tempNewProduct.taxes);
            }
            // If validated fields done add new row
            let oldSalesProducts = SALES.newSales.products;
            let updatePriceListNewProduct = Object.assign({}, tempNewProduct);
            updatePriceListNewProduct.name = tempNewProduct.name;
            updatePriceListNewProduct.code = tempNewProduct.code;
            updatePriceListNewProduct.no = oldSalesProducts.length + 1;
            updatePriceListNewProduct.id = oldSalesProducts.length + 1;
            updatePriceListNewProduct._id = tempNewProduct._id
            dispatch(setNewSalesNewProduct(tempNewProduct)); // Update redux
            let pPlroducts = [...oldSalesProducts, updatePriceListNewProduct];
            let productTotals = getSalesTotals(pPlroducts, SETTINGS);
            let productSalesTaxes = getSalesTaxesData(pPlroducts, SALES.newSales.extraData, productTotals, SETTINGS);
            let extraData = {...SALES.newSales.extraData};
            if(productSalesTaxes.length > 0){
              productSalesTaxes.map((salesTax) => {
                if(salesTax.vatPercentage === "Invoice Level Tax"){
                  extraData.witholdTaxEuro = salesTax.withholdTaxes;
                  extraData.otherTaxEuro = salesTax.otherTaxes;
                  extraData.feeEuro = salesTax.fees;
                  extraData.stampEuro = salesTax.stamp;
                }
                return salesTax;
              })
            }
            let totalTaxes = 0;
            let withHoldTaxes = 0;
            let deductions = 0;
            if(productSalesTaxes.length > 0 && productSalesTaxes[0].vatPercentage === "Invoice Level Tax"){
              totalTaxes = parseFloat( (Number(productSalesTaxes[0].otherTaxes) + Number(productSalesTaxes[0].fees) + Number(productSalesTaxes[0].stamp)).toString());
              withHoldTaxes = parseFloat(productSalesTaxes[0].withholdTaxes).toFixed(2);
              deductions = parseFloat(productSalesTaxes[0].deductions).toFixed(2);
            }
            if(totalTaxes !== 0 || withHoldTaxes !== 0 || deductions !== 0){
              productTotals.paymentAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes) - parseFloat(deductions)).toFixed(2);
              productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes)).toFixed(2);
            }
            dispatch(setNewSalesNewProduct(SALES.newSalesNewProductInitialValue)); // Reset new category mydata
            dispatch(setNewSales({ ...SALES.newSales, products: pPlroducts, salesTaxes: productSalesTaxes, ...productTotals, extraData: extraData }));
          }
        }
      }
      setProductTableSpinner('false');
    },
    update: async (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveSales) {
        dispatch(setSaveSales(true));
      }
      // Set Loading true
      setProductTableSpinner('true');
      let quickEdit = false;
      let foundProduct;
      let productData = null;
      let plProducts = SALES.newSales.products.slice();
      // Quick Edit Product from the sales product table
      if (data.quickEdit) {
        let qeProduct = await getObjectFromArrayOfObjects(plProducts, id, '_id');
        if (qeProduct.length > 0) {
          data.code = qeProduct['0'].code;
        }
        foundProduct = plProducts.find(item => item._id === id);
      } else {
        foundProduct = plProducts.find(item => item.id === id);
      }
      if(foundProduct.quickEdit === true) quickEdit = true;
      if (data.isPerDiscount && (data.isPerDiscount === "on" || data.isPerDiscount === "true")) {
        data.isPerDiscount = "true";
        data.isDiscountAmount = "false";
      }
      if (data.isDiscountAmount && (data.isDiscountAmount === "on" || data.isDiscountAmount === "true")) {
        data.isPerDiscount = "false";
        data.isDiscountAmount = "true";
      }
      if (data.name && data.name === '') {
        toast.error("Name is mandatory field.");
      } else if (data.code && data.code === '') {
        toast.error("Code is mandatory field.");
      } else {
        if (foundProduct !== undefined) {
          let papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(foundProduct.code)}`;
          if (data.code && data.code !== '') {
            papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.code)}`;
          } else if (data.name && data.name !== '') {
            papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.name)}`;
          }
          let loadingProduct = true;
          if (loadingProduct) {
            // Get product data from api.
            await axios.get(papiUrl ,{params: company})
              .then(res => {
                if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                  productData = res.data.data['0'];
                }
              }).catch((err) => {
                console.log(err);
              })
          }
          if(data.name || data.code) {
            quickEdit = false;
          }
          if (SALES.newSales.enablePeppol === "active" && !productData.cpvCode) {
            setProductTableSpinner('false');
            dispatch(setNewSales({...SALES.newSales, products: [...SALES.newSales.products]})); // Refresh
            toast.error("Το είδος που επιλέξατε δεν έχει κωδικό CPV καθώς απαιτείται στις δημόσιες συμβάσεις.");
            return;
          }
          // Field auto population on change product
          if (((data.code && data.code !== '') || (data.name && data.name !== ''))) {
            // Get Current Product Details with the API if product and client are selected.
            let fromPriceList = false;
            if (productData.code && productData.code !== '' && SALES.newSales.client && SALES.newSales.client !== '') {
              await axios.post(`${process.env.REACT_APP_API_URL2}/category/get-product-code-by-price`, { code: productData.code, client: SALES.newSales.client, company: requestData?.company})
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === "200") fromPriceList = true;
                      console.log('Product loading success');
                      sessionStorage.setItem("salesSelectedProductData", JSON.stringify(res.data.data));
                      let plProductData = res.data?.data ? res.data.data : productData;
                      const myDataData = plProductData?.mydata.filter(myData => String(myData.invoiceType) === SALES.newSales.myDataType.toString());
                      data.vatType = !plProductData?.vatType ? plProductData.vatIncluded : plProductData.vatType.toString(); // Vat Type from price table
                      data.discount = plProductData?.discount;
                      data.isPerDiscount = plProductData?.isPerDiscount;
                      data.isDiscountAmount = plProductData?.isDiscountAmount;
                      data.name = plProductData?.name;
                      data.code = plProductData?.code;
                      if(!quickEdit) data.classificationCategory = myDataData?.length > 0 ? myDataData[0].category : "";
                      if(!quickEdit) data.classificationType = myDataData?.length > 0 ? myDataData[0].type : "";
                      data.mu = plProductData?.measurementUnit;
                      data.muLabel = getLabelByValue(measurementUnitOptions, plProductData?.measurementUnit.toString(), 'value');
                      if(!quickEdit) data.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
                      if(!quickEdit) data.vatPercentage = getVatPercent(clientVatCategory, productData.vat, clientCustomVat);
                      if(!quickEdit) data.vatReason = clientVatReason !== "" ? clientVatReason : plProductData?.vatReason;
                      data.unitPrice = filterNaNValue(plProductData?.productPrice ? plProductData.productPrice: plProductData.price); // Price from the price list table
                    } else {
                      console.log('product details loading fail.');
                    }
                  }).catch((err) => {
                    console.log(err);
                  })
            }
            data.withHoldTax = productData.withholdTax;
            data.otherTax = productData.otherTaxes;
            data.stamp          = productData.stamp;
            data.fee           = productData.fee;
            if (!fromPriceList) {
              if (productData?.vatIncluded === "true") {
                const price = parseFloat(productData.price);
                const vat = isNaN(productData.vat) ? 0 : parseFloat(productData.vat);
                if (vat > 0) {
                  data.unitPrice = Number(Number(price/(1 + vat/100)).toFixed(2));
                } else {
                  data.unitPrice = parseNumber(productData.price);
                }
              } else {
                data.unitPrice = parseNumber(productData.price);
              }
            }
          }
          // Updated selected product with updated field data.
          foundProduct = { ...foundProduct, ...data }
          if (SALES.newSales.enablePeppol === "active") {
            if (productData.cpvCode) {
              foundProduct.cpvCode = productData.cpvCode;
            }
            if (!quickEdit) {
              foundProduct.peppolMeasurementUnit = classicInternationalMeasurementUnits[String(productData.measurementUnit)]
              if (isEmpty(foundProduct.peppolMeasurementUnit)) foundProduct.peppolMeasurementUnit = "H87";
            }
          }
          if (!quickEdit) {
            if (productData?.showAlternativeNameOnPrint === "true") {
              foundProduct.showAlternativeNameOnPrint = "true";
              foundProduct.alternativeName = productData.alternateName;
            } else {
              foundProduct.showAlternativeNameOnPrint = undefined;
              foundProduct.alternativeName = undefined;
            }
          }
          if (productData?.showAlternativeCodeOnPrint === "true") {
            foundProduct.showAlternativeCodeOnPrint = "true";
            foundProduct.alternativeCode = productData.alternateCode;
          } else {
            foundProduct.showAlternativeCodeOnPrint = undefined;
            foundProduct.alternativeCode = undefined;
          }
          let discountType = 'euro';
          if (foundProduct.isPerDiscount === 'true') {
            discountType = 'per'
          }
          // Note: For inclusive vat the value is reducing by the vat percentage.
          if (data.discount && data.discount !== '') { // on discount field change
            foundProduct.discount       = getDiscountExclusiveVat(foundProduct.discount, foundProduct?.vatPercentage, foundProduct?.vatType, discountType);
          }
          // Re Calculate Unit price if net value changed
          if (data.netValue && data.netValue !== '' && foundProduct.isTaxLine !== "true") {
            // If user enters value here, you calculate "Unit Price" automatically (based on previous axiom: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount")
            foundProduct.unitPrice = getUnitPrice(data.netValue, foundProduct.quantity, foundProduct.discount, discountType);
          } else {
            if(foundProduct.isTaxLine === "true"){
              foundProduct.netValue = 0;
            } else {
              foundProduct.netValue = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
            }
          }
          foundProduct.witholdTaxEuro = getProductWithholdTax(foundProduct, SETTINGS);
          foundProduct.otherTaxEuro   = getProductOtherTax(foundProduct, SETTINGS);
          foundProduct.feeEuro   = getProductFee(foundProduct, SETTINGS);
          foundProduct.stampEuro   = getProductStamp(foundProduct, SETTINGS);
          if (!isEmpty(productData?.deductionsEuro) && parseFloat(productData?.deductionsEuro) > 0) {
            foundProduct.deductionsEuro = Number(parseFloat(productData?.deductionsEuro).toFixed(2));
          }
          if(foundProduct.isTaxLine === "true"){
            foundProduct.taxes = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
          } else {
            foundProduct.taxes = getTaxes(foundProduct, SETTINGS);
          }
          // Re Calculate Unit price and net value if total value changed
          let userTotalValInput = 0; // For recalculating vat +-
          if (data.totalValue && data.totalValue !== '') {
            if(foundProduct.isTaxLine === "true"){
              foundProduct.taxes = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
              foundProduct.unitPrice      = getUnitPrice(foundProduct.taxes, foundProduct.quantity, foundProduct.discount, discountType);
              foundProduct.netValue       = 0;
            } else {
              // If user enters value here, you calculate "Unit Price" and "Net Value" automatically (based on axioms: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount" and "Total Value" equals: "VAT %" times "Net Value" plus "Net Value")
              foundProduct.netValue = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
              foundProduct.unitPrice = getUnitPrice(foundProduct.netValue, foundProduct.quantity, foundProduct.discount, discountType);
            }
            userTotalValInput = data.totalValue;
            //foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes);
          } else {
            foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes);
          }
          if(foundProduct.isTaxLine === "true"){
            foundProduct.vat = getVatValue(foundProduct.taxes, foundProduct.vatPercentage);
          } else {
            foundProduct.vat = getVatValue(foundProduct.netValue, foundProduct.vatPercentage, userTotalValInput);
          }
          const myDataData = productData.mydata.filter(myData => String(myData.invoiceType) === SALES.newSales.myDataType.toString() && (isEmpty(myData.rowType) || myData.rowType === "income"));
          if(productData?.isTaxLine === "true"){
            if(!quickEdit) foundProduct.vatPercentage  = getVatPercent(clientVatCategory, productData.vat, clientCustomVat);
            if(!quickEdit) {
              if (clientVatCategory === "Reduced VAT" && ["24", "13", "6"].includes(String(productData.vat))) {
                foundProduct.vatId = getDefaultOptionByValue(vatCategoryData, String(foundProduct.vatPercentage), 'percentage', 'code', 'percentage').label;
              } else if (clientVatCategory === "Zero VAT") {
                foundProduct.vatId = "7";
              } else {
                foundProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
              }
            }
            if(!quickEdit) foundProduct.vatReason      = clientVatReason !== "" ? clientVatReason : productData?.vatReason;
            foundProduct.discount       = getDiscountExclusiveVat(foundProduct.discount, foundProduct?.vatPercentage, foundProduct?.vatType, discountType);
            foundProduct.netValue       = 0;
            foundProduct.withHoldTax    = productData.withholdTax;
            foundProduct.otherTax       = productData.otherTaxes;
            foundProduct.stamp          = productData.stamp;
            foundProduct.fee           = productData.fee;
            foundProduct.stamp           = productData.stamp;
            foundProduct._id            = productData._id;
            foundProduct.isTaxLine      = productData.isTaxLine;
            if( myDataData.length > 0) {
              if(!quickEdit) foundProduct.classificationCategory = myDataData[0].category;
              if(!quickEdit) foundProduct.classificationType = myDataData[0].type;
            } else {
              foundProduct.classificationCategory = '';
              foundProduct.classificationType = '';
            }

            foundProduct.isTaxLineWithoutVat      = productData.isTaxLineWithoutVat;
            foundProduct.taxes          = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
            foundProduct.otherTaxEuro   = getProductOtherTax(foundProduct, SETTINGS);
            foundProduct.witholdTaxEuro = getProductWithholdTax(foundProduct, SETTINGS);
            foundProduct.feeEuro        = getProductFee(foundProduct, SETTINGS);
            foundProduct.stampEuro      = getProductStamp(foundProduct, SETTINGS);
            foundProduct.vat            = getVatValue(foundProduct.taxes, foundProduct.vatPercentage);
            foundProduct.totalValue     = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes);
          } else {
            if(!quickEdit) foundProduct.vatPercentage = getVatPercent(clientVatCategory, productData.vat, clientCustomVat);
            if(!quickEdit) {
              if (clientVatCategory === "Reduced VAT" && ["24", "13", "6"].includes(String(productData.vat))) {
                foundProduct.vatId = getDefaultOptionByValue(vatCategoryData, String(foundProduct.vatPercentage), 'percentage', 'code', 'percentage').label;
              } else if (clientVatCategory === "Zero VAT") {
                foundProduct.vatId = "7";
              } else {
                foundProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
              }
            }
            if(!quickEdit) foundProduct.vatReason = clientVatReason !== "" ? clientVatReason : productData?.vatReason;
            foundProduct.discount = getDiscountExclusiveVat(foundProduct.discount, foundProduct?.vatPercentage, foundProduct?.vatType, discountType);
            foundProduct.withHoldTax = productData.withholdTax;
            foundProduct.otherTax = productData.otherTaxes;
            foundProduct.stamp = productData.stamp;
            foundProduct.fee = productData.fee;
            foundProduct.stamp = productData.stamp;
            foundProduct._id = productData._id;
            if(myDataData.length > 0){
              if(!quickEdit) foundProduct.classificationCategory = myDataData[0].category;
              if(!quickEdit) foundProduct.classificationType = myDataData[0].type;
            } else {
              foundProduct.classificationCategory = '';
              foundProduct.classificationType = '';
            }
            foundProduct.taxes = getTaxes(foundProduct, SETTINGS);
            foundProduct.otherTaxEuro = getProductOtherTax(foundProduct, SETTINGS);
            foundProduct.witholdTaxEuro = getProductWithholdTax(foundProduct, SETTINGS);
            foundProduct.feeEuro = getProductFee(foundProduct, SETTINGS);
            foundProduct.stampEuro        = getProductStamp(foundProduct, SETTINGS);
            foundProduct.vat = getVatValue(foundProduct.netValue, foundProduct.vatPercentage, userTotalValInput);
            foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes, foundProduct.vat);
          }
          const updatedPmProducts = { ...plProducts[foundProduct.no - 1], ...foundProduct }
          let filterFields = {
            code: data.code ? data.code : plProducts.code,
            name: data.name ? data.name : plProducts.name,
          }
          let productListData = salesDuplicateProductCheck(plProducts, filterFields, foundProduct.no - 1);
          // Logic to check duplicate product in the table.
          let productSalesTaxes ;
          if (productListData && !data.quickEdit) {
            toast.error('Duplicate products not allowed.');
            let productTotals = getSalesTotals(plProducts, SETTINGS);
            productSalesTaxes = getSalesTaxesData(plProducts,SALES.newSales.extraData, productTotals, SETTINGS);
            let totalTaxes = 0;
            let withHoldTaxes = 0;
            let deductions = 0;
            if(productSalesTaxes.length > 0 && productSalesTaxes[0].vatPercentage === "Invoice Level Tax"){
              totalTaxes = parseFloat((Number(productSalesTaxes[0].otherTaxes) + Number(productSalesTaxes[0].fees) + Number(productSalesTaxes[0].stamp)).toString());
              withHoldTaxes = parseFloat(productSalesTaxes[0].withholdTaxes).toFixed(2);
              deductions = parseFloat(productSalesTaxes[0].deductions).toFixed(2);
            }
            if(totalTaxes !== 0 || withHoldTaxes !== 0 || deductions !== 0){
              productTotals.paymentAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes) - parseFloat(deductions)).toFixed(2);
              productTotals.totalAmount = parseFloat(parseFloat( productTotals.totalAmount) + parseFloat(totalTaxes)).toFixed(2);
            }
            dispatch(setNewSales({ ...SALES.newSales, products: plProducts, salesTaxes: productSalesTaxes, ...productTotals })); // update data
            setRefreshPage(refreshPage + 1); // Refresh page
          } else {
            plProducts[foundProduct.no - 1] = updatedPmProducts;
            let productTotals = getSalesTotals(plProducts, SETTINGS);
            productSalesTaxes = getSalesTaxesData(plProducts,SALES.newSales.extraData, productTotals, SETTINGS);
            let totalTaxes = 0;
            let withHoldTaxes = 0;
            let deductions = 0;
            if(productSalesTaxes.length > 0 && productSalesTaxes[0].vatPercentage === "Invoice Level Tax"){
              totalTaxes = parseFloat( (Number(productSalesTaxes[0].otherTaxes) + Number(productSalesTaxes[0].fees) + Number(productSalesTaxes[0].stamp)).toString());
              withHoldTaxes = parseFloat(productSalesTaxes[0].withholdTaxes).toFixed(2);
              deductions = parseFloat(productSalesTaxes[0].deductions).toFixed(2);
            }
            if(totalTaxes !== 0 || withHoldTaxes !== 0 || deductions !== 0){
              productTotals.paymentAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes) - parseFloat(deductions)).toFixed(2);
              productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes)).toFixed(2);
            }
            dispatch(setNewSales({ ...SALES.newSales, products: plProducts, salesTaxes: productSalesTaxes, ...productTotals })); // update data
            setRefreshPage(refreshPage + 1); // Refresh page
          }
          if(productSalesTaxes.length > 0){
            productSalesTaxes.map((salesTax) => {
              if(salesTax.vatPercentage === "Invoice Level Tax"){
                let extraData = {};
                extraData.witholdTaxEuro = salesTax.withholdTaxes;
                extraData.otherTaxEuro = salesTax.otherTaxes;
                extraData.feeEuro = salesTax.fees;
                extraData.stampEuro = salesTax.stamp;
                dispatch(setNewSalesExtraData({ ...SALES.newSales.extraData, ...extraData }));
              }
              return salesTax;
            })
          }
          setProductTableSpinner('false');
        }
      }
    },
    selected: () => {},
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveSales) {
        dispatch(setSaveSales(true));
      }

      let oldSalesProducts = SALES.newSales.products.slice();

      if (id !== '' && Number.isInteger(id)) {
        let deletedSalesData = deleteObjectFromArrayOfObjects(oldSalesProducts, id, 'id').slice();
        if(deletedSalesData.length > 0) {
          deletedSalesData = deletedSalesData.map((a) => Object.assign({}, a));
          deletedSalesData.map((s, ind) => {
            s.no = ind + 1;
            s.id = String(ind + 1);
          })
        }
        dispatch(setNewSales({ ...SALES.newSales, products: deletedSalesData }))
        setUpdateSaleAmounts(true);
      }

      if (id !== '' && !Number.isInteger(id)) {
        let deletedSalesData = deleteObjectFromArrayOfObjects(oldSalesProducts, id, '_id').slice();
        if(deletedSalesData.length > 0) {
          deletedSalesData = deletedSalesData.map((a) => Object.assign({}, a));
          deletedSalesData.map((s, ind) => {
            s.no = ind + 1;
            s.id = String(ind + 1);
          })
        }
        dispatch(setNewSales({ ...SALES.newSales, products: deletedSalesData }))
        setUpdateSaleAmounts(true);
      }
    }
  }


  const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, SALES.newSales.documentType, 'value', 'label', 'value');
  // const selectedCorelatedDocument = getDefaultOptionByValue(corelatedDocumentOptions, SALES.newSales.corelatedDocument, 'value', 'label', 'value');

  let tableWrClasses = "product-form sales-products-list position-relative";

  if (SALES.newSales.client !== '') {
    tableWrClasses += " normalIndex";
  }

  const [incrKey, setIncrKey] = useState(0);
  useEffect(() => {
    setIncrKey(incrKey + 1);
  }, [SALES.newSales.products, salesProductList])

  let cashRegistersData = CASH_DATA.cashRegisters.filter((register) => register.isActive === "active");
  let cashRegisterOptions = [{value: "", label: t("General.empty")}, ...getOptionsByData(cashRegistersData, "_id", "name")];

  const newSalesTab = [
    {
      tabName: t('SalesNew.productTable.title'),
      tabData: (
          <React.Fragment>
            <Row className={tableWrClasses}>
              <Col md={12}>
                {(SALES.newSales.client === '' && SALES.newSales.newClient === '') && <div className="overlay_w">{t('SalesNew.message2')}</div>}
                {showProductTableSpinner && showProductTableSpinner === 'true' && <div className="overlay_w"> <div className="spinner-border" role="status"> <span className="sr-only">{t("SalesNew.loading")}</span> </div> </div>}
                {SALES.newSales?.products && SALES.newSales?.products?.length === 0 && <span className="text-danger">{t('SalesNew.messageProductList')}</span>}
                <EditableTable
                    tableName="Sales Products List"
                    key={"Sales Products List" + incrKey}
                    actionButtons={{
                      print: false,
                      delete: true,
                      export: false,
                      edit: true,
                    }}
                    columns={clientProductsColumns}
                    pRequestData={pRequestData}
                    data={SALES.newSales.products}
                    allowInsertRow={true}
                    onUpdate={salesProductListActions}
                    allowActions={true}
                    allowToggleColumns={false}
                    allowPrintResults={false}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className={"d-flex justify-content-end"}>
                {SALES.newSales?.discountAsLine === true && (
                    <div className={"mt-1 mr-3 text-muted"}>(*) Η έκπτωση θα προβληθεί ως γραμμή παραστατικού με περιγραφή: {SALES.newSales?.discountAsLineText}</div>
                )}
                <Button size={"sm"} onClick={() => setShowDiscountModal(true)}>Εφαρμογή Έκπτωσης</Button>
              </Col>
            </Row>
          </React.Fragment>
      ),
    },
    {
      tabName: t('SalesNew.extraDataTable.title'),
      tabData: (
          <>
            <Row>
              <Col sm={12} md={3} className="mb-2">
                {SALES.newSales.myDataType !== "2.1" && SALES.newSales.myDataType !== "2.2" && SALES.newSales.myDataType !== "2.3" && SALES.newSales.myDataType !== "2.4" && SALES.newSales.myDataType !== "11.2" && SALES.newSales.myDataType !== "7.1" && SALES.newSales.myDataType !== "8.1" && SALES.newSales.myDataType !== "8.2" && (
                    <Dropdown
                        label={t('SalesNew.extraDataTable.locationDelivery')}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(salesLocationData, SALES.newSales.extraData.deliveryLocation, 'value', 'label', 'value')}
                        name="deliveryLocation"
                        options={salesLocationData}
                        onChange={(e) => handleOnChangeExtraData(e, 'dd', 'deliveryLocation')}
                    />
                )}
              </Col>
              {SALES.newSales.extraData.deliveryLocation === "custom" && (
                  <Row className="border-bottom mb-3">
                    <Col sm={12} md={3} className="mb-2">
                      <Dropdown
                          label={t('SalesNew.extraDataTable.locationCountry.locationCountry')}
                          key={Math.random()}
                          defaultValue={getDefaultOptionByValue(countryData, SALES.newSales.extraData.locationCountry, 'value', 'label', 'value')}
                          name="locationCountry"
                          required={true}
                          options={countryData}
                          onChange={(e) => handleOnChangeExtraData(e, 'dd', 'locationCountry')}
                      />
                      {ACTIVITY.saveSales && isEmpty(SALES.newSales.extraData.locationCountry) && (
                          <div className="text-danger">{t('SalesNew.extraDataTable.locationCountry.required')}</div>
                      )}
                    </Col>
                    <Col sm={12} md={3} className="mb-2">
                      <Input
                          label={t('SalesNew.extraDataTable.locationCity.locationCity')}
                          value={SALES.newSales.extraData.locationCity}
                          name="locationCity"
                          type="text"
                          onChange={(e) => handleOnChangeExtraData(e)}
                      />
                      {ACTIVITY.saveSales && isEmpty(SALES.newSales.extraData.locationCity) && (
                          <div className="text-danger">{t('SalesNew.extraDataTable.locationCity.required')}</div>
                      )}
                    </Col>
                    <Col sm={12} md={3} className="mb-2">
                      <Input
                          label={t('SalesNew.extraDataTable.locationAddress.locationAddress')}
                          value={SALES.newSales.extraData.locationAddress}
                          name="locationAddress"
                          type="text"
                          onChange={(e) => handleOnChangeExtraData(e)}
                      />
                      {ACTIVITY.saveSales && isEmpty(SALES.newSales.extraData.locationAddress) && (
                          <div className="text-danger">{t('SalesNew.extraDataTable.locationAddress.required')}</div>
                      )}
                    </Col>
                    <Col sm={12} md={3} className="mb-2">
                      <Input
                          label={t('SalesNew.extraDataTable.locationPostalCode.locationPostalCode')}
                          type="text"
                          value={SALES.newSales.extraData.locationZip}
                          name="locationZip"
                          onChange={(e) => handleOnChangeExtraData(e)}
                      />
                      {ACTIVITY.saveSales && isEmpty(SALES.newSales.extraData.locationZip) && (
                          <div className="text-danger">{t('SalesNew.extraDataTable.locationPostalCode.required')}</div>
                      )}
                    </Col>
                    <Col md={3} className="mb-2">
                      <Input
                          label={"Α.Φ.Μ. Παραλήπτη"}
                          type={"text"}
                          value={SALES.newSales.extraData.locationVatNumber}
                          name={"locationVatNumber"}
                          onChange={(e) => handleOnChangeExtraData(e)}
                      />
                      {SALES.newSales.enablePeppol === "active" && (
                          <div>Συμπεριλαμβανομένου το πρόθεμα χώρας, π.χ EL{company.vatNumber}</div>
                      )}
                      {ACTIVITY.saveSales && isEmpty(SALES.newSales.extraData.locationVatNumber) && SALES.newSales.enablePeppol === "active" && (
                          <div className="text-danger">Απαιτείται Α.Φ.Μ. Παραλήπτη.</div>
                      )}
                    </Col>
                    <Col md={3} className="mb-2">
                      <Input
                          label={"Ονομασία Εταιρείας Παράδοσης"}
                          type={"text"}
                          value={SALES.newSales.extraData.locationCompanyName}
                          name={"locationCompanyName"}
                          onChange={(e) => handleOnChangeExtraData(e)}
                      />
                      {ACTIVITY.saveSales && isEmpty(SALES.newSales.extraData.locationCompanyName) && SALES.newSales.enablePeppol === "active" && (
                          <div className="text-danger">Απαιτείται ονομασία εταιρείας παράδοσης.</div>
                      )}
                    </Col>
                  </Row>
              )}
              <Col sm={12} md={3} className="mb-2">
                <Dropdown
                    label={t('SalesNew.extraDataTable.wayOfSendingGoods')}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(waysOfSendingGoodsOptions, SALES.newSales.extraData.wayOfSendingGoods, 'value', 'label', 'value')}
                    name="wayOfSendingGoods"
                    options={waysOfSendingGoodsOptions}
                    onChange={(e) => handleOnChangeExtraData(e, 'dd', 'wayOfSendingGoods')}
                />
              </Col>
              {SALES.newSales.extraData.deliveryLocation === "custom" && (
                  <Col></Col>
              )}
              <Col sm={12} md={3} className="mb-2">
                {pMDDSettings?.otherTaxes?.find((el) => el.code === SALES.newSales.extraData.otherTax)?.taxPer === "false" ? (
                    <Row>
                      <Col md={7}>
                        <Dropdown
                            label={t('SalesNew.extraDataTable.otherTaxes')}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(otherTaxesOptions, SALES.newSales.extraData.otherTax, 'value', 'label', 'value')}
                            name="otherTax"
                            options={otherTaxesOptions}
                            onChange={(e) => handleOnChangeExtraData(e, 'dd', 'otherTax')}
                        />
                      </Col>
                      <Col md={5}>
                        <label>{"Ποσό λοιπόν φόρων"}</label>
                        <AccountingBox
                            name={"otherTaxEuro"}
                            defaultValue={SALES.newSales.extraData.otherTaxEuro}
                            onBlur={(e) => handleOnChangeExtraData(e, "acb", "otherTaxEuro")}
                        />
                      </Col>
                      <Col md={12}>
                        <BetterCheckBox
                            name={"otherTaxAsLine"}
                            text={"Προβολή ως γραμμή παραστατικού"}
                            checked={SALES.newSales.extraData?.otherTaxAsLine === "true"}
                            onChange={(e) => handleOnChangeExtraData(e, "cb", "otherTaxAsLine")}
                        />
                      </Col>
                      {SALES.newSales.extraData?.otherTaxAsLine === "true" && (
                          <Col md={12}>
                            <Input
                                name={"otherTaxAsLineText"}
                                label={"Όνομα γραμμής λοιπού φόρου"}
                                value={isEmpty(SALES.newSales.extraData?.otherTaxAsLineText) ? "" : SALES.newSales.extraData?.otherTaxAsLineText}
                                onChange={(e) => handleOnChangeExtraData(e, "default", "otherTaxAsLineText")}
                            />
                          </Col>
                      )}
                    </Row>
                ) : (
                    <Dropdown
                        label={t('SalesNew.extraDataTable.otherTaxes')}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(otherTaxesOptions, SALES.newSales.extraData.otherTax, 'value', 'label', 'value')}
                        name="otherTax"
                        options={otherTaxesOptions}
                        onChange={(e) => handleOnChangeExtraData(e, 'dd', 'otherTax')}
                    />
                )}
              </Col>
              <Col sm={12} md={3} className="mb-2">
                {pMDDSettings?.withHoldTaxes?.find((el) => el.code === SALES.newSales.extraData.withHoldTax)?.taxPer === "false" ? (
                    <Row>
                      <Col md={7}>
                        <Dropdown
                            label={t('SalesNew.extraDataTable.withHoldTaxes')}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(withHoldTaxesOptions, SALES.newSales.extraData.withHoldTax, 'value', 'label', 'value')}
                            name="withHoldTax"
                            options={withHoldTaxesOptions}
                            onChange={(e) => handleOnChangeExtraData(e, 'dd', 'withHoldTax')}
                        />
                      </Col>
                      <Col md={5}>
                        <label>{"Ποσό παρακράτησης"}</label>
                        <AccountingBox
                            name={"witholdTaxEuro"}
                            defaultValue={SALES.newSales.extraData.witholdTaxEuro}
                            onBlur={(e) => handleOnChangeExtraData(e, "acb", "witholdTaxEuro")}
                        />
                      </Col>
                      <Col md={12}>
                        <BetterCheckBox
                            name={"withHoldTaxAsLine"}
                            text={"Προβολή ως γραμμή παραστατικού"}
                            checked={SALES.newSales.extraData?.withHoldTaxAsLine === "true"}
                            onChange={(e) => handleOnChangeExtraData(e, "cb", "withHoldTaxAsLine")}
                        />
                      </Col>
                      {SALES.newSales.extraData?.withHoldTaxAsLine === "true" && (
                          <Col md={12}>
                            <Input
                                name={"withHoldTaxAsLineText"}
                                label={"Όνομα γραμμής παρακρατούμενου"}
                                value={isEmpty(SALES.newSales.extraData?.withHoldTaxAsLineText) ? "" : SALES.newSales.extraData?.withHoldTaxAsLineText}
                                onChange={(e) => handleOnChangeExtraData(e, "default", "withHoldTaxAsLineText")}
                            />
                          </Col>
                      )}
                    </Row>
                ) : (
                    <Dropdown
                        label={t('SalesNew.extraDataTable.withHoldTaxes')}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(withHoldTaxesOptions, SALES.newSales.extraData.withHoldTax, 'value', 'label', 'value')}
                        name="withHoldTax"
                        options={withHoldTaxesOptions}
                        onChange={(e) => handleOnChangeExtraData(e, 'dd', 'withHoldTax')}
                    />
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={3}>
                <DateBox
                    classes="w-100 mb-3"
                    label={t('SalesNew.extraDataTable.goodsSendDate')}
                    name="goodsSendDate"
                    selected={SALES.newSales.extraData.goodsSendDate}
                    onChange={(e) => handleOnChangeExtraData(e, 'dp', 'goodsSendDate')}
                />
              </Col>
              <Col sm={12} md={3} className="mb-2">
                <Dropdown
                    label={t('SalesNew.extraDataTable.carNumber')}
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(carNumbersOptions, SALES.newSales.extraData.carNumber, 'value', 'label', 'value')}
                    name="carNumber"
                    options={carNumbersOptions}
                    onChange={(e) => handleOnChangeExtraData(e, 'dd', 'carNumber')}
                />
              </Col>
              <Col sm={12} md={3} className="mb-2">
                {pMDDSettings?.fees?.find((el) => el.code === SALES.newSales.extraData.fee)?.taxPer === "false" ? (
                    <Row>
                      <Col md={7}>
                        <Dropdown
                            label={t('SalesNew.extraDataTable.fees')}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(feesOptions, SALES.newSales.extraData.fee, 'value', 'label', 'value')}
                            name="fee"
                            options={feesOptions}
                            onChange={(e) => handleOnChangeExtraData(e, 'dd', 'fee')}
                        />
                      </Col>
                      <Col md={5}>
                        <label>{"Ποσό Τέλους"}</label>
                        <AccountingBox
                            name={"feeEuro"}
                            defaultValue={SALES.newSales.extraData.feeEuro}
                            onBlur={(e) => handleOnChangeExtraData(e, "acb", "feeEuro")}
                        />
                      </Col>
                      <Col md={12}>
                        <BetterCheckBox
                            name={"feeAsLine"}
                            text={"Προβολή ως γραμμή παραστατικού"}
                            checked={SALES.newSales.extraData?.feeAsLine === "true"}
                            onChange={(e) => handleOnChangeExtraData(e, "cb", "feeAsLine")}
                        />
                      </Col>
                      {SALES.newSales.extraData?.feeAsLine === "true" && (
                          <Col md={12}>
                            <Input
                                name={"feeAsLineText"}
                                label={"Όνομα γραμμής τέλους"}
                                value={isEmpty(SALES.newSales.extraData?.feeAsLineText) ? "" : SALES.newSales.extraData?.feeAsLineText}
                                onChange={(e) => handleOnChangeExtraData(e, "default", "feeAsLineText")}
                            />
                          </Col>
                      )}
                    </Row>
                ) : (
                    <Dropdown
                        label={t('SalesNew.extraDataTable.fees')}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(feesOptions, SALES.newSales.extraData.fee, 'value', 'label', 'value')}
                        name="fee"
                        options={feesOptions}
                        onChange={(e) => handleOnChangeExtraData(e, 'dd', 'fee')}
                    />
                )}
              </Col>
              <Col sm={12} md={3} className="mb-2">
                {pMDDSettings?.stamp?.find((el) => el.code === SALES.newSales.extraData.stamp)?.taxPer === "false" ? (
                    <Row>
                      <Col md={7}>
                        <Dropdown
                            label={t('SalesNew.extraDataTable.stampDuty')}
                            key={Math.random()}
                            defaultValue={getDefaultOptionByValue(stampOptions, SALES.newSales.extraData.stamp, 'value', 'label', 'value')}
                            name="stamp"
                            options={stampOptions}
                            onChange={(e) => handleOnChangeExtraData(e, 'dd', 'stamp')}
                        />
                      </Col>
                      <Col md={5}>
                        <label>{"Ποσό χαρτοσήμου"}</label>
                        <AccountingBox
                            name={"stampEuro"}
                            defaultValue={SALES.newSales.extraData.stampEuro}
                            onBlur={(e) => handleOnChangeExtraData(e, "acb", "stampEuro")}
                        />
                      </Col>
                      <Col md={12}>
                        <BetterCheckBox
                            name={"stampAsLine"}
                            text={"Προβολή ως γραμμή παραστατικού"}
                            checked={SALES.newSales.extraData?.stampAsLine === "true"}
                            onChange={(e) => handleOnChangeExtraData(e, "cb", "stampAsLine")}
                        />
                      </Col>
                      {SALES.newSales.extraData?.stampAsLine === "true" && (
                          <Col md={12}>
                            <Input
                                name={"stampAsLineText"}
                                label={"Όνομα γραμμής χαρτοσήμου"}
                                value={isEmpty(SALES.newSales.extraData?.stampAsLineText) ? "" : SALES.newSales.extraData?.stampAsLineText}
                                onChange={(e) => handleOnChangeExtraData(e, "default", "stampAsLineText")}
                            />
                          </Col>
                      )}
                    </Row>
                ) : (
                    <Dropdown
                        label={t('SalesNew.extraDataTable.stampDuty')}
                        key={Math.random()}
                        defaultValue={getDefaultOptionByValue(stampOptions, SALES.newSales.extraData.stamp, 'value', 'label', 'value')}
                        name="stamp"
                        options={stampOptions}
                        onChange={(e) => handleOnChangeExtraData(e, 'dd', 'stamp')}
                    />
                )}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Dropdown
                    name={"cashRegister"}
                    label={t("General.cashRegister")}
                    key={Math.random()}
                    defaultValue={cashRegisterOptions.find((el) => el.value === SALES.newSales.extraData.cashRegister) ? cashRegisterOptions.find((el) => el.value === SALES.newSales.extraData.cashRegister) : ""}
                    options={cashRegisterOptions}
                    onChange={(e) => handleOnChangeExtraData(e, "dd", "cashRegister")}
                />
              </Col>
              <Col md={3}>
              </Col>
              <Col md={3}>
                <label>{"Ποσό κρατήσεων"}</label>
                <AccountingBox
                    name={"deductionsEuro"}
                    defaultValue={SALES.newSales.extraData.deductionsEuro}
                    onBlur={(e) => handleOnChangeExtraData(e, "acb", "deductionsEuro")}
                />
                {SALES.newSales.extraData.deductionsEuro && parseFloat(SALES.newSales.extraData.deductionsEuro) > 0 && (
                    <React.Fragment>
                      <BetterCheckBox
                          name={"deductionsAsLine"}
                          text={"Προβολή ως γραμμή παραστατικού"}
                          checked={SALES.newSales.extraData?.deductionsAsLine === "true"}
                          onChange={(e) => handleOnChangeExtraData(e, "cb", "deductionsAsLine")}
                      />
                      {SALES.newSales.extraData?.deductionsAsLine === "true" && (
                          <Input
                              name={"deductionsAsLineText"}
                              label={"Όνομα γραμμής κράτησης"}
                              value={isEmpty(SALES.newSales.extraData?.deductionsAsLineText) ? "" : SALES.newSales.extraData?.deductionsAsLineText}
                              onChange={(e) => handleOnChangeExtraData(e, "default", "deductionsAsLineText")}
                          />
                      )}
                    </React.Fragment>
                )}
              </Col>
            </Row>
          </>
      ),
    },
  ];

  const handleQuickRetailClient = () => {
    let type = (SALES.newSales.clientType === 'Existing') ? 'New' : "Existing";
    let newSalesWithNewClient = Object.assign({}, SALES.newSales);

    if (type === 'New') {
      // Clear New Client Data
      newSalesWithNewClient.doy = "";
      newSalesWithNewClient.city = "";
      newSalesWithNewClient.pc = "";
      newSalesWithNewClient.vat = "";
      newSalesWithNewClient.address = "";
      newSalesWithNewClient.email = "";
      newSalesWithNewClient.phone = "";
    } else {
      let newSalesSelectedClient = newSalesWithNewClient.client;
      if (newSalesSelectedClient !== '') {
        let selectedClient = getObjectFromArrayOfObjects(clientsListData, newSalesSelectedClient, '_id');
        if (selectedClient.length > 0) {
          let sClient = selectedClient['0'];
          newSalesWithNewClient.address = sClient.address;
          newSalesWithNewClient.vat = sClient.vatNumber;
          newSalesWithNewClient.doy = getLabelByValue(getDOYOptions(), Number(sClient.DOY), 'value');
          newSalesWithNewClient.city = sClient.city;
          newSalesWithNewClient.pc = sClient.postalCode;
          if (sClient.installations.length > 0) {
            newSalesWithNewClient.address = 1;
          }
        }
      }
    }
    newSalesWithNewClient.clientType = type;
    dispatch(setNewSales(newSalesWithNewClient)); // Update type in redux state
  }
  // Data For Quick Edit Client Details on selection
  let quickEditClientData = {};
  if (SALES.newSales.clientType === 'Existing') {
    if (SALES.newSales.client && SALES.newSales.client !== "") {
      quickEditClientData.name = SALES.newSales.clientName;
      quickEditClientData.id = SALES.newSales.client;
      quickEditClientData.vatCategory = SALES.newSales.vatCategory;
      quickEditClientData.address = SALES.newSales.address;
      quickEditClientData.vatNumber = SALES.newSales.vat;
      quickEditClientData.doy = getLabelByValue(getDOYOptions(), Number(SALES.newSales.doy), 'value');
      quickEditClientData.city = SALES.newSales.city;
      quickEditClientData.pc = SALES.newSales.pc;
      quickEditClientData.email = SALES.newSales.email;
      quickEditClientData.phone = SALES.newSales.phone;
    }
  }

  const quickEditClient = (e, type, data) => {
    let selectedClient = SALES.newSales.client;
    if (selectedClient) {
      let quickClientData = {};
      // Quick Edit Client Details Data
      quickClientData._id = SALES.newSales.client;
      quickClientData.company = company.id;
      quickClientData.name = data.name;
      quickClientData.vatCategory = data.vatCategory;
      quickClientData.vatNumber = data.vatNumber;
      quickClientData.address = data.address;
      quickClientData.city = data.city;
      quickClientData.email = data.email;
      quickClientData.phone = data.phone;

      if ((data.email !== '' || data.phone !== '') && data.name !== '' && data.vatCategory !== '') {
        console.log('edit quick client', quickClientData);
        dispatch(putClient(quickClientData, requestData));

        // Repopulate the client data to sales after updating the client.
        let sClient = data;
        let repopulateClient = {};
        repopulateClient.clientName = sClient.name;
        repopulateClient.address = sClient.address;
        repopulateClient.vat = sClient.vatNumber;
        repopulateClient.vatCategory = sClient.vatCategory;
        repopulateClient.city = sClient.city;
        repopulateClient.email = sClient.email;
        repopulateClient.pc = sClient.postalCode;
        repopulateClient.phone = sClient.phone;

        let salesData = Object.assign({}, SALES);
        dispatch(setNewSales({ ...salesData.newSales, ...repopulateClient }));

      } else {
        toast.error('Name, Email or Phone and Vat Category required fields.');
      }
    }
  }

  const clearClient = () => {
    if(SALES.newSales.client !== ''){
      let newSalesData = {};
      let salesData = Object.assign({}, SALES);
      newSalesData.client = "";
      newSalesData.clientName = "";
      newSalesData.clientCode = "";
      newSalesData.address = "";
      newSalesData.vat = "";
      newSalesData.doy = "";
      newSalesData.city = "";
      newSalesData.country = "";
      newSalesData.pc = "";
      newSalesData.email = "";
      newSalesData.phone = "";
      newSalesData.activity = "";
      newSalesData.vatCategory = "";
      setClientInstallationListData(CLIENTS_DATA.installations.slice());
      newSalesData.clientInstallationType = "";
      newSalesData.clientInstallationId = "";
      // Set Client Vat Category used for product vat calculation
      dispatch(setNewSaleClientCustomData({
        clientVatCategory: "Normal VAT",
        clientVatReason: "",
        clientCustomVat: 0,
      }))
      dispatch(setNewSales({ ...salesData.newSales, ...newSalesData }));
    }
  }

  const quickAddClient = (e, type, data) => {
    let quickClientData = Object.assign({}, CLIENTS_DATA.newClient);
    // Default Category Retails form sales page.
    quickClientData.category = '1';
    quickClientData.categoryName = 'Retail';
    quickClientData.name = data.name;
    quickClientData.vatCategory = data.vatCategory;
    quickClientData.vatNumber = data.vatNumber;
    quickClientData.address = data.address;
    quickClientData.city = data.city;
    quickClientData.email = data.email;
    quickClientData.phone = data.phone;
    quickClientData.country = data.country;
    quickClientData.clientInstallationId = "0";
    // Company and Year
    quickClientData.company = requestData.company;
    quickClientData.year = requestData.year;

    quickClientData.isActive = 'active'; // Active by default

    if ((data.email !== '' || data.phone !== '') && data.name !== '' && data.vatCategory !== '') {
      dispatch(postClient(quickClientData, requestData));
    } else {
      toast.error('Name, Email or Phone and Vat Category required fields.');
    }
  }

  let posData = CASH_DATA.posList.filter((pos) => pos.isActive === "active");
  let bankData = CASH_DATA.bankAccounts.filter((bankAccount) => bankAccount.isActive === "active");
  let posOptions = getOptionsByData(posData, '_id', 'name');
  let bankOptions = getOptionsByData(bankData, '_id', 'name');
  let hidePosBankField = false;

  // This is will update the list of pos / bank on type selection
  let paymentTypesData = getObjectFromArrayOfObjects(SALES_SETTINGS.salesGeneralSettings.paymentTypes, SALES.newSales.paymentType, '_id');
  if (paymentTypesData && paymentTypesData.length > 0) {
    if (paymentTypesData['0']?.type === "pos") {
      bankOptions = posOptions;
    }

    if (paymentTypesData['0']?.type === "cash" || paymentTypesData['0']?.type === "credit" || paymentTypesData['0']?.type === "cheque" || paymentTypesData['0']?.type === "iris") {
      bankOptions = [];
      hidePosBankField = true;
    }
  }
  let salesTaxesColumns = [
    {
      name: t('SalesNew.salesTaxesTables.vat%'),
      field: "vatPercentage",
      width: "15%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.netValue'),
      field: "netValue",
      width: "15%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.vat'),
      field: "vatEuro",
      width: "15%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.withHoldTaxes'),
      field: "withholdTaxes",
      width: "15%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.otherTaxes'),
      field: "otherTaxes",
      width: "10%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.fees'),
      field: "fees",
      width: "10%",
      editable: false,
    },
    {
      name: t('SalesNew.salesTaxesTables.stampDuty'),
      field: "stamp",
      width: "10%",
      editable: false,
    },
    {
      name: "Κρατήσεις",
      field: "deductions",
      width: "10%",
      editable: false,
    }
  ];
  let totalTaxes = 0;
  let withHoldTaxes = 0;
  let deductions = 0;
  let productTotals = useMemo(() => getSalesTotals(SALES.newSales.products, SETTINGS), [SALES.newSales.products]);
  let salesTaxesData = useMemo(() => getSalesTaxesData(SALES.newSales.products,SALES.newSales.extraData, productTotals, SETTINGS), [SALES.newSales.products, SALES.newSales.extraData]);
  if(salesTaxesData.length > 0 && salesTaxesData[0].vatPercentage === "Invoice Level Tax"){
    totalTaxes = parseFloat( (Number(salesTaxesData[0].otherTaxes) + Number(salesTaxesData[0].fees) + Number(salesTaxesData[0].stamp)).toString());
    withHoldTaxes = parseFloat(salesTaxesData[0].withholdTaxes).toFixed(2);
    deductions = parseFloat(salesTaxesData[0].deductions).toFixed(2);
  }
  let totalQuantity = productTotals.totalQuantity;
  let totalBeforeDiscount = productTotals.totalBeforeDiscount;
  let totalDiscount = productTotals.totalDiscount;
  let totalNetValue = productTotals.totalNetValue;
  let totalAmount = productTotals.totalAmount;
  let paymentAmount = productTotals.paymentAmount;
  let totalVatAmount = productTotals.totalVatValue;
  totalTaxes += Number(productTotals.totalTaxesAmount);
  withHoldTaxes = parseFloat(parseFloat(productTotals.withHoldTaxes) + parseFloat(withHoldTaxes));
  if(totalTaxes !== 0 || withHoldTaxes !== 0 || deductions !== 0){
    totalAmount = parseFloat(totalAmount) + parseFloat(totalTaxes);
    paymentAmount = parseFloat(paymentAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes) - parseFloat(deductions);
  }
  const selectDocumentInstallation = getDefaultOptionByValue(dtInstallationsOptions, SALES.newSales.installation, 'value', 'label', 'value');
  const selectPaymentTypeOptions = getDefaultOptionByValue(paymentTypeOptions, SALES.newSales.paymentType, 'value', 'label', 'value');
  const selectPosBankAccount = getDefaultOptionByValue(bankOptions, SALES.newSales.bankAccount, 'value', 'label', 'value');
  const selectSalePurpose = getDefaultOptionByValue(salesPurposeOptions, SALES.newSales.salePurpose, 'value', 'label', 'value');
  const selectPreNumber = getDefaultOptionByValue(preNumberGreekOptions, SALES.newSales.preNumber, 'value', 'label', 'value');

  useEffect(() => {
    if(updateSaleAmounts === true) {
      setUpdateSaleAmounts(false);
      let extraData = {};
      if(salesTaxesData.length > 0) {
        salesTaxesData.map((salesTax) => {
          if(salesTax.vatPercentage === "Invoice Level Tax") {
            extraData.witholdTaxEuro = salesTax.withholdTaxes;
            extraData.otherTaxEuro = salesTax.otherTaxes;
            extraData.feeEuro = salesTax.fees;
            extraData.stampEuro = salesTax.stamp;
          }
        })
      }
      dispatch(setNewSales({
        ...SALES.newSales,
        salesTaxes: salesTaxesData,
        extraData: {...SALES.newSales.extraData, ...extraData},
        withHoldTaxes: withHoldTaxes.toFixed(2),
        ...productTotals,
      }))
    }
  }, [updateSaleAmounts, salesTaxesData]);

  const handleOnPeppolChange = (e, type, name) => {
    let obj = {};
    if (SALES.newSales?.peppol) obj = {...SALES.newSales?.peppol}
    if (type === "input") {
      obj[name] = e.target.value;
      obj.dueDate = moment().format("YYYY-MM-DD");
      dispatch(setNewSales({...SALES.newSales, peppol: obj}));
    } else if (type === "datebox") {
      if (moment(e).isValid()) {
        obj[name] = moment(e).format("YYYY-MM-DD")
      } else {
        obj[name] = ""
      }
      dispatch(setNewSales({...SALES.newSales, peppol: obj}));
    } else if (type === "anathetousaModal") {
      // name argument is object here from select anathetousa modal
      toast.success("Η επιλογή έγινε επιτυχώς", {autoClose: 1000});
      obj["dueDate"] = moment().format("YYYY-MM-DD");
      obj["partyIdentification"] = name.anathetousaLabelId;
      dispatch(setNewSales({...SALES.newSales, peppol: obj}));
    }
  }

  const [loadingCheckPeppolFields, setLoadingCheckPeppolFields] = useState(false);
  const handleCheckPeppolFields = () => {
    if (isEmpty(SALES.newSales?.vat)) {
      toast.error("Παρακαλώ επιλέξτε πελάτη.");
    } else if (isEmpty(SALES.newSales?.peppol?.projectReference) && SALES.newSales.myDataType !== "5.1") {
      toast.error("Παρακαλώ συμπληρώστε Α.Δ.Α. ή Ενάριθμο.");
    } else if (isEmpty(SALES.newSales?.peppol?.partyIdentification)) {
      toast.error("Παρακαλώ συμπληρώστε κωδικό αναθέτουσας αρχής.");
    } else {
      setLoadingCheckPeppolFields(true);
      axios.post(process.env.REACT_APP_API_URL2 + "/sale/check-peppol-fields", {
        projectReferenceId: SALES.newSales?.peppol?.projectReference,
        buyerPartyId: SALES.newSales?.peppol?.partyIdentification,
        buyerTaxNumber: SALES.newSales?.vat,
        contractReferenceId: SALES.newSales?.peppol?.contractDocumentReference ? SALES.newSales?.peppol?.contractDocumentReference : "0",
        company: company.id
      }, {
        headers: { 'Content-Type': 'application/json'}
      }).then((res) => {
        setLoadingCheckPeppolFields(false);
        if (res.data.status === "200") {
          toast.success("Τα πεδία είναι έγκυρα.");
        } else {
          if (res.data.nonValidFields) {
            let prepMessage = `Τα παρακάτω πεδία δεν είναι έγκυρα: ${res.data.nonValidFields.join(", ")}`;
            toast.error(prepMessage, {autoClose: 7000});
          } else {
            toast.error(res.data.message);
          }
        }
      }).catch((err) => {
        setLoadingCheckPeppolFields(false);
        console.log(err);
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      })
    }
  }

  const discountObj = useSelector((state) => state.SALES.salesDiscountObject);
  const discountTypeOptions = [
    {label: "Ποσοστιαία %", value: "percentage"},
    {label: "Ποσό €", value: "amount"}
  ]
  const handleOnDiscountChange = (e, type, name) => {
    if (type === "dropdown") {
      dispatch(setSalesDiscountObject({...discountObj, [name] : e.value, amount: 0}));
    } else if (type === "input") {
      dispatch(setSalesDiscountObject({...discountObj, [name] : e.target.value}));
    } else if (type === "cb") {
      dispatch(setSalesDiscountObject({...discountObj, [name] : e.target.checked}));
    }
  }
  const handleSaveDiscount = () => {
    if (isNaN(discountObj.amount)) return toast.error(`Το ${discountObj.discountType === "percentage" ? "ποσοστό" : "ποσό"} δεν είναι δεκτός αριθμός.`);
    if (discountObj.discountAsLine === true && isEmpty(discountObj.discountAsLineText)) return toast.error("Παρακαλώ συμπληρώστε την περιγραφή της έκπτωσης.");

    let totalPercentage = 0;
    if (discountObj.discountType === "amount") {
      let totalValueToCheck = Number(SALES.newSales.totalNetValue) + Number(SALES.newSales.totalVatValue);
      if (discountObj.amount < totalValueToCheck) {
        totalPercentage = (Number(discountObj.amount) / Number(totalValueToCheck)) * 100;
        totalPercentage = Number(Number(totalPercentage).toFixed(2));
      } else {
        return toast.error("Παρακαλώ εφαρμόστε ποσό μικρότερο από την τελική αξία.");
      }
    } else if (discountObj.discountType === "percentage") {
      totalPercentage = discountObj.amount;
    }
    if (Number(totalPercentage) >= 0 && Number(totalPercentage) <= 100 && !isNaN(totalPercentage)) {
      let clonedProducts = structuredClone(SALES.newSales.products);
      let appliedAmount = 0;
      for (let prod of clonedProducts) {
        prod.isPerDiscount = "true";
        prod.isDiscountAmount = "false";
        prod.discount = getDiscountExclusiveVat(totalPercentage, prod?.vatPercentage, prod?.vatType, "per");
        appliedAmount += (Number(prod.discount) / 100) * (Number(prod.unitPrice) * Number(prod.quantity));
        if (prod?.isTaxLine !== "true") {
          prod.netValue = getNetValue(prod.unitPrice, prod.quantity, prod.discount, "per");
          prod.taxes = getTaxes(prod, SETTINGS);
          prod.otherTaxEuro = getProductOtherTax(prod, SETTINGS);
          prod.witholdTaxEuro = getProductWithholdTax(prod, SETTINGS);
          prod.feeEuro = getProductFee(prod, SETTINGS);
          prod.stampEuro = getProductStamp(prod, SETTINGS);
          prod.vat = getVatValue(prod.netValue, prod.vatPercentage);
          prod.totalValue = getTotalValue(prod.netValue, prod.vatPercentage, prod.taxes);
        }
        appliedAmount = Number(Number(appliedAmount).toFixed(2));
      }
      if (discountObj.discountType === "amount") {
        // Fix cents
        const centsDiff = discountObj.amount - appliedAmount;
        for (let prod of clonedProducts) {
          if (prod.totalValue > centsDiff && prod?.isTaxLine !== "true") {
            const newDiscount = (Number(prod.discount) / 100) * (Number(prod.unitPrice) * Number(prod.quantity)) + centsDiff;

            prod.isPerDiscount = "false";
            prod.isDiscountAmount = "true";
            prod.discount = +Number(newDiscount).toFixed(2);
            prod.netValue = getNetValue(prod.unitPrice, prod.quantity, newDiscount, "amount");
            prod.taxes = getTaxes(prod, SETTINGS);
            prod.otherTaxEuro = getProductOtherTax(prod, SETTINGS);
            prod.witholdTaxEuro = getProductWithholdTax(prod, SETTINGS);
            prod.feeEuro = getProductFee(prod, SETTINGS);
            prod.stampEuro = getProductStamp(prod, SETTINGS);
            prod.vat = getVatValue(prod.netValue, prod.vatPercentage);
            prod.totalValue = getTotalValue(prod.netValue, prod.vatPercentage, prod.taxes);

            break;
          }
        }
      }
      let productTotals = getSalesTotals(clonedProducts, SETTINGS);
      let productSalesTaxes = getSalesTaxesData(clonedProducts, SALES.newSales.extraData, productTotals, SETTINGS);
      let totalTaxes = 0;
      let withHoldTaxes = 0;
      let deductions = 0;
      if(productSalesTaxes.length > 0 && productSalesTaxes[0].vatPercentage === "Invoice Level Tax"){
        totalTaxes = parseFloat( (Number(productSalesTaxes[0].otherTaxes) + Number(productSalesTaxes[0].fees) + Number(productSalesTaxes[0].stamp)).toString());
        withHoldTaxes = parseFloat(productSalesTaxes[0].withholdTaxes).toFixed(2);
        deductions = parseFloat(productSalesTaxes[0].deductions).toFixed(2);
      }
      if(totalTaxes !== 0 || withHoldTaxes !== 0 || deductions !== 0){
        productTotals.paymentAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes) - parseFloat(deductions)).toFixed(2);
        productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes)).toFixed(2);
      }
      let prepSaleObj = {
        ...SALES.newSales,
        products: clonedProducts,
        salesTaxes: productSalesTaxes,
        ...productTotals
      }
      prepSaleObj.discountAsLine = discountObj.discountAsLine;
      prepSaleObj.discountAsLineText = discountObj.discountAsLineText;

      dispatch(setNewSales(prepSaleObj));
      setShowDiscountModal(false);
      toast.success("Η έκπτωση εφαρμόστηκε επιτυχώς", {autoClose: 2000});
    } else {
      toast.error(`Δεν γίνεται έκπτωση ${totalPercentage} %.`)
    }
  }

  const handleOtherCorrelatedInvoicesTableUpdate = {
    insert: (e, data) => {
      if (e.row) {
        if (!ACTIVITY.saveSales) dispatch(setSaveSales(true));
        let clone = [];
        if (SALES.newSales?.otherCorrelatedInvoices?.length > 0) {
          clone = structuredClone(SALES.newSales?.otherCorrelatedInvoices);
        }
        let cloneRow = {...e.row};
        delete cloneRow.date;
        clone.push(cloneRow);
        dispatch(setNewSales({...SALES.newSales, otherCorrelatedInvoices: clone}));
      }
    },
    update: (data, id, rowIndex, e) => {
      if (!ACTIVITY.saveSales) dispatch(setSaveSales(true));
      let clone = structuredClone(SALES.newSales?.otherCorrelatedInvoices);
      let cloneRow = {...e.row}
      delete cloneRow.date;
      clone[rowIndex] = {...clone[rowIndex], ...cloneRow}
      dispatch(setNewSales({...SALES.newSales, otherCorrelatedInvoices: clone}));
    },
    selected: () => {},
    delete: (id, rowIndex) => {
      if (rowIndex >= 0) {
        if (!ACTIVITY.saveSales) dispatch(setSaveSales(true));
        let clone = structuredClone(SALES.newSales?.otherCorrelatedInvoices);
        clone.splice(rowIndex, 1);
        dispatch(setNewSales({...SALES.newSales, otherCorrelatedInvoices: clone}));
      }
    }
  }

  const fixSalesTaxesDisplay = () => {
    if(Array.isArray(SALES.newSales.salesTaxes)) {
      let newArr = [];
      for (let obj of SALES.newSales.salesTaxes) {
        let prepObj = {...obj};
        if (obj.vatPercentage !== "Totals") {
          prepObj.fees = Number(obj.fees).toFixed(2).replace(".", ",") + " €";
          prepObj.netValue = Number(obj.netValue).toFixed(2).replace(".", ",") + " €";
          prepObj.otherTaxes = Number(obj.otherTaxes).toFixed(2).replace(".", ",") + " €";
          prepObj.stamp = Number(obj.stamp).toFixed(2).replace(".", ",") + " €";
          prepObj.vatEuro = Number(obj.vatEuro).toFixed(2).replace(".", ",") + " €";
          prepObj.withholdTaxes = Number(obj.withholdTaxes).toFixed(2).replace(".", ",") + " €";
          prepObj.deductions = Number(obj.deductions).toFixed(2).replace(".", ",") + " €";
        }
        newArr.push(prepObj);
      }
      return newArr;
    } else {
      return SALES.newSales.salesTaxes;
    }
  }

  return (
      <div className="new-sales">
        <div className="client-form product-form position-relative">
          {disabledFields && <div className="overlay_w">{t('SalesNew.message')}</div>}
          <Row className="border-bottom mb-3">
            <Col sm={12} md={3} className="showWithOverlay">
              <Dropdown
                  label={t('SalesNew.DocumentType')}
                  key={selectedDocumentType.value}
                  defaultValue={selectedDocumentType}
                  required={true}
                  searchEnable={true}
                  name="documentType"
                  options={documentTypesOptions}
                  onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
              />
            </Col>
            {(SALES.newSales.myDataType === "1.1" || SALES.newSales.myDataType === "1.2" || SALES.newSales.myDataType === "1.3") ? (
                <Col md={3} style={{paddingTop: "33px"}}>
                  <BetterCheckBox
                      name={"isDeliveryNote"}
                      checked={(SALES.newSales.isDeliveryNote !== null) ? SALES.newSales.isDeliveryNote : true}
                      required={false}
                      text={"Είναι και δελτίο αποστολής"}
                      onChange={(e) => handleOnChange(e, "checkbox", "isDeliveryNote")}
                  />
                </Col>
            ) : (
                <Col md={3}></Col>
            )}
            <Col sm={12} md={6}>
              <div className="d-flex justify-content-end">
                <div className="mydata-status disabled-page">
                  <Dropdown
                      label={t('SalesNew.myDataStatus')}
                      options={salesMyDataStatusOptions}
                      defaultValue={getDefaultOptionByValue(salesMyDataStatusOptions, SALES.newSales.myDataStatus, 'value', 'label', 'value')}
                      name="myDataStatus"
                      onChange={(e) => handleOnChange(e, 'dd', 'myDataStatus')}
                  />
                </div>
                <Button className="disabled mb-3" size="small">{t('SalesNew.files')}</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="3">
              {/* If "MyData rules" is enabled in "Settings-Document Types-Advanced Types", then FIELD IS READ ONLY. Select current date only. */}
              <div className="d-flex">
                <DateBox
                    classes="w-100 mb-3"
                    label={t('SalesNew.Date')}
                    name="date"
                    selected={new Date(SALES.newSales.date)}
                    readOnly={readOnlySalesDate}
                    allowOlderDate={allowOlderDate}
                    allowOlderDateValue={checkPermission("custom-logistirio", permissionsData) === false ? pMDDSettings.otherData.filter(otherData => otherData.name === "allowOlderDate")[0].value : "999999"}
                    onChange={(e) => handleOnChange(e, 'dp', 'date')}
                />
              </div>
            </Col>
            <Col sm="12" md="3">
              {/* If "MyData rules" is enabled in "Settings-Document Types-Advanced Types", then FIELD IS READ ONLY. Select current date only. */}
              <label htmlFor="time">{t('SalesNew.time')}</label>
              <TimePicker
                  locale="en-us"
                  className="form-control"
                  name="time"
                  disabled={readOnlySalesTime && !allowOlderDate}
                  value={SALES.newSales.time ? SALES.newSales.time : currentTime}
                  disableClock={true}
                  clearIcon={null}
                  format={"H:mm"}
                  onChange={(e) => handleOnChange(e, 'tp', 'time')}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="4">
              <div className="mb-3">
                <Dropdown
                    label={t('SalesNew.installation')}
                    options={dtInstallationsOptions}
                    value={Object.keys(selectDocumentInstallation).length !== 0 ? selectDocumentInstallation : ''}
                    enabledValue={true}
                    searchEnable={true}
                    required={true}
                    name="installation"
                    onChange={(e) => handleOnChange(e, 'dd', 'installation')}
                    disabled={String(SALES.newSales?.corelatedDocument).length === 24}
                />
              </div>
            </Col>
            <Col sm="12" md="4">
              <div className="mb-3">
                <Dropdown
                    label={t('SalesNew.preNumber.preNumber')}
                    name="preNumber"
                    required={true}
                    key={Math.random()}
                    defaultValue={Object.keys(selectPreNumber).length !== 0 ? selectPreNumber : ''}
                    options={preNumberGreekOptions}
                    disabled={SALES.newSales.numberingMode === 'manual'}
                    onChange={(e) => handleOnChange(e, 'dd', 'preNumber')}
                />
              </div>
              {ACTIVITY.saveSales  && SALES.newSales.preNumber === "" && <div className="text-danger">{t('SalesNew.preNumber.required')}</div>}
            </Col>
            <Col sm="12" md="4">
              <div className="d-flex">
                <Input
                    className="mb-3 w-100"
                    label={t('SalesNew.number.number')}
                    name="number"
                    required={true}
                    value={SALES.newSales.number}
                    placeholder={t('SalesNew.number.placeholder')}
                    onChange={(e) => handleOnChange(e)}
                    disabled={SALES.newSales.numberingMode !== 'manual' && !allowOlderDate}
                />
              </div>
              {ACTIVITY.saveSales  && SALES.newSales.number === "" && <div className="text-danger">{t('SalesNew.number.required')}</div>}
            </Col>
          </Row>
          {
              showCorelatedDocument &&
              <Row>
                <Col sm="12" md="4">
                  <div className="mb-3">
                    <AdvancedCombobox
                        ACName="Sales Corelated Document" // do not change name
                        className="w-100 ac-select-dropdown corelated-doc"
                        popupClassName="corelated-doc-popup"
                        searchableDropdownEnable={false}
                        defaultValue={SALES.newSales?.corelatedDocumentName}
                        key={SALES.newSales?.corelatedDocument}
                        label={t('SalesNew.corelatedDocument.corelatedDocument')}
                        data={corelatedDocumentsList}
                        onChange={(e) => handleOnChange(e, 'acdd', 'corelatedDocument') }
                        onChangeSearch={(keyword, setData) => {
                          if (String(keyword).length >= 1) {
                            fetchCorrelatedDocumentsList(keyword, setData);
                          }
                        }}
                        disabled={String(SALES.newSales?.corelatedDocument).length === 24}
                        excludeColumns={[]}
                        columns={corelatedDocumentColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t('SalesNew.corelatedDocument.placeholder')}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        required={isCorelatedDocumentMandatory}
                        inputFieldClasses="form-control"
                    />

                    {
                        ACTIVITY.saveSales &&
                        isCorelatedDocumentMandatory &&
                        (SALES.newSales.client !== '' || SALES.newSales.newClient !== '') &&
                        SALES.newSales.corelatedDocument === "" &&
                        <div className="text-danger">{t('SalesNew.corelatedDocument.required')}</div>}
                  </div>
                </Col>
                <Col sm="12" md="1">
                  {String(SALES.newSales?.corelatedDocument).length === 24 &&
                      <button style={{position: "absolute", bottom: "16px"}} className="btn btn-sm"
                              title="Καθαρισμός συσχετιζόμενου παραστατικού" onClick={() => dispatch(setNewSales({...SALES.newSales, corelatedDocument: "", corelatedDocumentName: ""}))}>
                        <i className="fas fa-xmark"></i></button>
                  }
                </Col>
              </Row>
          }
          <Row>
            {SALES.newSales.clientType === 'Existing' &&
                <Col sm="30" md="5">
                  <div className="mb-3">
                    <AdvancedCombobox
                        ACName="Sales Client" // do not change name
                        className="w-200 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        key={SALES.newSales.clientName}
                        defaultValue={SALES.newSales.clientName}
                        label={t('SalesNew.client.client')}
                        data={clientsListData}
                        onChangeSearch={onChangeSearch}
                        onChange={(e) => { handleOnChange(e, 'acdd', 'client') }}
                        excludeColumns={[]}
                        columns={clientDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t('SalesNew.client.placeholder')}
                        required={true}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                        disabled={String(SALES.newSales?.corelatedDocument).length === 24}
                    />
                  </div>
                  {ACTIVITY.saveSales && SALES.newSales.clientType === 'New' && SALES.newSales.newClient === "" && <div className="text-danger">{t('SalesNew.client.required')}</div>}
                  {ACTIVITY.saveSales && SALES.newSales.clientType === 'Existing' && SALES.newSales.client === "" && <div className="text-danger">{t('SalesNew.client.required')}</div>}
                </Col>
            }
            {SALES.newSales.clientType === 'New' &&
                <Col sm="30" md="5">
                  <div className="mb-3">
                    <Input
                        className="w-100 newclient-field"
                        label={t('SalesNew.newRetailClient.newRetailClient')}
                        name="newClient"
                        value={SALES.newSales.newClient}
                        placeholder={t('SalesNew.newRetailClient.placeholder')}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                </Col>
            }
            {String(SALES.newSales?.corelatedDocument).length === 24 ? (
                <Col md={1}></Col>
            ) : (
                <Col sm="1" md="1">
                  <div className="d-flex  mt-4">
                    <div className="quick-client-actions">
                      {SALES.newSales.myDataType !== '' && (SALES.newSales.myDataType === '11.1' || SALES.newSales.myDataType === '11.2' || SALES.newSales.myDataType === '11.4') && false && (
                          <button title="Quick Retail Client" type="button" onClick={() => handleQuickRetailClient()} className="btn quick-retail-clientbtn btn-default btn-sm">
                            <i className="fas fa-male"></i>
                          </button>
                      )}
                      {SALES.newSales.client !== '' && SALES.newSales.clientType === 'Existing' && <QuickEdit title="Edit selected client." key={Math.random()} columns={quickClientFields} data={quickEditClientData} hideCurrentValue={true} updateData={quickEditClient} />}
                      {SALES.newSales.client !== '' && SALES.newSales.clientType === 'Existing' && <button className="btn quick-retail-clientbtn btn-default btn-sm" title="Clear Client Fields" key={Math.random()} onClick={clearClient}><i className="fas fa-xmark"></i></button>}
                      {((SALES.newSales.client === '' && SALES.newSales.newClient === '') || SALES.newSales.clientType !== 'Existing') && <NewClientModal title="Add new client" columns={quickClientFields} data={{}} hideCurrentValue={true} myDataType={SALES.newSales.myDataType} updateData={quickAddClient} />}
                    </div>
                  </div>
                </Col>
            )}
            {SALES.newSales.clientType === 'Existing' && (
                <Col sm="12" md="2">
                  <div className="d-flex">
                    <AdvancedCombobox
                        ACName="Client Installation" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={SALES.newSales.clientInstallationType}
                        label={t('SalesNew.clientInstallation.clientInstallation')}
                        data={clientInstallationListData}
                        onChangeSearch = {onChangeSearch}
                        onChange={(e) => { handleOnChange(e, 'acdd', 'clientInstallation') }}
                        excludeColumns={[]}
                        columns={clientInstallationsDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t('SalesNew.clientInstallation.placeholder')}
                        required={true}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                        disabled={String(SALES.newSales?.corelatedDocument).length === 24}
                    />
                  </div>
                </Col>
            )}
            {SALES.newSales.clientType === 'Existing' && (
                <Col sm="12" md="2">
                  <div className="mb-3 ">
                    <Input
                        className="w-100"
                        label={t('SalesNew.vat')}
                        name="vat"
                        value={SALES.newSales.vat}
                        onChange={(e) => handleOnChange(e)}
                        disabled={true}
                        readonly
                    />
                  </div>
                </Col>
            )}
            {/* Show if new client */}
            {SALES.newSales.clientType === 'New' &&
                <Col sm="12" md="2">
                  <div className="mb-3 ">
                    <Input
                        className="w-100"
                        label={t('SalesNew.email.email')}
                        name="email"
                        required={SALES.newSales.clientType === 'New' && SALES.newSales.phone === ''}
                        value={SALES.newSales.email}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {ACTIVITY.saveSales && SALES.newSales.clientType === 'New' && SALES.newSales.email === "" && SALES.newSales.phone === "" && <div className="text-danger">{t('SalesNew.email.required')}</div>}
                  </div>
                </Col>
            }
            {/* Show if new client */}
            {SALES.newSales.clientType === 'New' &&
                <Col sm="12" md="2">
                  <div className="mb-3 ">
                    <Input
                        className="w-100"
                        label={t('SalesNew.phone.phone')}
                        name="phone"
                        required={SALES.newSales.clientType === 'New' && SALES.newSales.email === ''}
                        value={SALES.newSales.phone}
                        onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                </Col>
            }
            <Col sm="12" md="2">
              <div className="mb-3 ">
                <Input
                    className="w-100"
                    label={t('SalesNew.address')}
                    name="address"
                    value={SALES.newSales.address}
                    disabled={String(SALES.newSales?.corelatedDocument).length === 24}
                    onChange={(e) => handleOnChange(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {SALES.newSales.clientType === 'Existing' && (
                <Col sm="12" md="2">
                  <div className="mb-3 ">
                    <Input
                        className="w-100"
                        label={t('SalesNew.doy')}
                        name="doy"
                        value={SALES.newSales.doy}
                        onChange={(e) => handleOnChange(e)}
                        disabled={true}
                        readonly
                    />
                  </div>
                </Col>
            )}
            <Col sm="12" md="2">
              <div className="mb-3 ">
                <Input
                    className="w-100"
                    label={t('SalesNew.city')}
                    name="city"
                    value={SALES.newSales.city}
                    onChange={(e) => handleOnChange(e)}
                    disabled={SALES.newSales.clientType !== 'New'}
                    readonly
                />
              </div>
            </Col>
            <Col sm="12" md="2">
              <div className="mb-3 ">
                <Input
                    className="w-100"
                    label={t('SalesNew.pc')}
                    name="pc"
                    value={SALES.newSales.pc}
                    onChange={(e) => handleOnChange(e)}
                    disabled={SALES.newSales.clientType !== 'New'}
                    readonly
                />
              </div>
            </Col>
            <Col md={6}>
              <Input
                  className="w-100"
                  label={t('General.activity')}
                  name="activity"
                  value={SALES.newSales.activity}
                  onChange={(e) => handleOnChange(e)}
                  disabled={SALES.newSales.clientType !== 'New'}
                  readonly
              />
            </Col>
          </Row>
          <Row className={"mb-3"}>
            <Col sm="12" md="4">
              <Dropdown
                  label={t('SalesNew.paymentType.paymentType')}
                  options={paymentTypeOptions}
                  value={Object.keys(selectPaymentTypeOptions).length !== 0 ? selectPaymentTypeOptions : ''}
                  enabledValue={true}
                  required={requiredPaymentType}
                  name="paymentType"
                  searchEnable={true}
                  onChange={(e) => handleOnChange(e, 'dd', 'paymentType')}
              />
              {ACTIVITY.saveSales && requiredPaymentType && SALES.newSales.paymentTypeName === "" && <div className="text-danger">{t('SalesNew.paymentType.required')}</div>}
            </Col>
            {!hidePosBankField &&
                <Col sm="12" md="4">
                  <Dropdown
                      label={t('SalesNew.posBankAccount')}
                      options={bankOptions}
                      value={Object.keys(selectPosBankAccount).length !== 0 ? selectPosBankAccount : ''}
                      enabledValue={true}
                      required={!hidePosBankField}
                      name="bankAccount"
                      onChange={(e) => handleOnChange(e, 'dd', 'bankAccount')}
                  />
                  {ACTIVITY.saveSales && !hidePosBankField && SALES.newSales.bankAccount === "" && <div className="text-danger">{t('SalesNew.posBankAccountRequired')}</div>}
                </Col>
            }
            <Col sm="12" md="4">
              <Dropdown
                  label={t('SalesNew.salePurpose')}
                  options={salesPurposeOptions}
                  key={Math.random()}
                  value={Object.keys(selectSalePurpose).length !== 0 ? selectSalePurpose : ''}
                  enabledValue={true}
                  searchEnable={true}
                  name="salePurpose"
                  onChange={(e) => handleOnChange(e, 'dd', 'salePurpose')}
              />
            </Col>
            {SALES.newSales.salePurpose === "19" && (
                <Col md={4}>
                  <Input
                      name={"salePurposeName"}
                      label={"Περιγραφή σκοπού διακίνησης"}
                      required={true}
                      value={SALES.newSales.salePurposeName ? SALES.newSales.salePurposeName : ""}
                      onChange={(e) => handleOnChange(e)}
                  />
                </Col>
            )}
          </Row>
          {(SALES.newSales?.myDataType === "1.1" || SALES.newSales?.myDataType === "2.1" || SALES.newSales?.myDataType === "5.1" || SALES.newSales?.myDataType === "5.2")
              && SETTINGS.settings.advancedTypesSales.find((doc) => doc._id === SALES.newSales.documentType)?.mydataProvider === "active"
              && SALES.newSales?.enablePeppol === "active"
              && (
              <Row className={"mb-2"}>
                <Col md={3}>
                  <BetterCheckBox
                      name={"enablePeppol"}
                      text={"Αφορά δημόσια σύμβαση"}
                      className={"mt-1 mb-1"}
                      checked={true}
                      disabled={true}
                  />
                </Col>
                {(SALES.newSales?.enablePeppol === "active") && (
                    <Col md={12}>
                      <div style={{borderRadius: "5px", border: "1px solid #ecf0f5", padding: "10px"}}>
                        <Row>
                          {SALES.newSales.myDataType !== "5.1" && (
                              <Col md={3}>
                                <Popover placement={"top"} content={
                                  <>
                                    Πεδίο ΔΡΟΜΟΛΟΓΗΣΗΣ. Το πεδίο χρησιμοποιείται ως Αναγνωριστικό<br/>
                                    Σύμβασης για τον τύπο του Προϋπολογισμού και λαμβάνει τις τιμές:<br/>
                                    «1|ΑΔΑ Ανάληψης» όταν αφορά τον Τακτικό Προϋπολογισμό (Φορέα Κεντρικής Διοίκησης)<br/>
                                    «2|Κωδικοποιημένος Ενάριθμος» όταν αφορά χρηματοδότηση μέσω ΠΔΕ<br/>
                                    «3|ΑΔΑ Ανάληψης» όταν αφορά λοιπούς προϋπολογισμούς (Φορέα Λοιπής Γενικής Κυβέρνησης)<br/>
                                    Ως ΑΔΑ Ανάληψης εννοείται ΑΔΑ Ανάληψης Υποχρέωσης. Οι κωδικοί ΑΔΑ<br/>
                                    και Ενάριθμος αναφέρονται στη Σύμβαση, ως πηγές χρηματοδότησης.
                                  </>
                                } title={<div style={{textAlign: "center"}}>Οδηγίες Α.Δ.Α. ή Ενάριθμου</div>}>
                                  <>
                                    <Input
                                        name={"projectReference"}
                                        label={"Α.Δ.Α. ή Ενάριθμος"}
                                        value={SALES.newSales?.peppol?.projectReference ? SALES.newSales.peppol.projectReference : ""}
                                        onChange={(e) => handleOnPeppolChange(e, "input", "projectReference")}
                                        required={true}
                                        disabled={String(SALES.newSales?.corelatedDocument).length === 24}
                                    />
                                  </>
                                </Popover>
                              </Col>
                          )}
                          <Col md={3}>
                            <Row>
                              <Col md={10}>
                                <Input
                                    name={"partyIdentification"}
                                    label={"Κωδικός Αναθέτουσας Αρχής"}
                                    value={SALES.newSales?.peppol?.partyIdentification ? SALES.newSales.peppol.partyIdentification : ""}
                                    placeholder={"Παρακαλώ επιλέξτε"}
                                    required={true}
                                    disabled={true}
                                />
                              </Col>
                              <Col md={2}>
                                <SelectAnathetousaModal
                                    data={SALES.newSales}
                                    selectFunction={(obj) => handleOnPeppolChange(null, "anathetousaModal", obj)}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={3}>
                            <Input
                                name={"contractDocumentReference"}
                                label={"Α.Δ.Α.Μ."}
                                value={SALES.newSales?.peppol?.contractDocumentReference ? SALES.newSales.peppol.contractDocumentReference : ""}
                                onChange={(e) => handleOnPeppolChange(e, "input", "contractDocumentReference")}
                                disabled={String(SALES.newSales?.corelatedDocument).length === 24}
                            />
                          </Col>
                          <Col md={3}>
                            <DateBox
                                name={"dueDate"}
                                label={"Ημερομηνία"}
                                selected={moment(SALES.newSales?.peppol?.dueDate, "YYYY-MM-DD").isValid() ? moment(SALES.newSales?.peppol?.dueDate, "YYYY-MM-DD").toDate() : ""}
                                onChange={(e) => handleOnPeppolChange(e, "datebox", "dueDate")}
                                disabled={true}
                            />
                          </Col>
                        </Row>
                        <Row className={"mt-2"}>
                          <Col md={3}>
                            <Input
                                name={"paymentMethodInfo"}
                                label={"Όροι πληρωμής"}
                                placeholder={"Προσθέστε όρους πληρωμής"}
                                value={SALES.newSales?.peppol?.paymentMethodInfo ? SALES.newSales.peppol.paymentMethodInfo : ""}
                                onChange={(e) => handleOnPeppolChange(e, "input", "paymentMethodInfo")}
                            />
                          </Col>
                          <Col md={3}>
                            <Input
                                name={"buyerReference"}
                                label={"Στοιχείο Αναφοράς Αγοραστή"}
                                placeholder={"Προσθέστε στοιχείο αναφοράς αγοραστή"}
                                value={SALES.newSales?.peppol?.buyerReference ? SALES.newSales.peppol.buyerReference : ""}
                                onChange={(e) => handleOnPeppolChange(e, "input", "buyerReference")}
                            />
                          </Col>
                          <Col md={3}>
                            <Input
                                name={"purchaseOrderReference"}
                                label={"Αναφορά Εντολής Αγοράς"}
                                placeholder={"Προσθέστε αναφορά εντολή αγοράς"}
                                value={SALES.newSales?.peppol?.purchaseOrderReference ? SALES.newSales.peppol.purchaseOrderReference : ""}
                                onChange={(e) => handleOnPeppolChange(e, "input", "purchaseOrderReference")}
                            />
                          </Col>
                          <Col md={3}>
                            <Input
                                name={"salesOrderReference"}
                                label={"Στοιχείο αναφοράς εντολής πώλησης"}
                                placeholder={"Προσθέστε στοιχείο αναφοράς εντολής πώλησης"}
                                value={SALES.newSales?.peppol?.salesOrderReference ? SALES.newSales.peppol.salesOrderReference : ""}
                                onChange={(e) => handleOnPeppolChange(e, "input", "salesOrderReference")}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Button size={"sm"} onClick={() => handleCheckPeppolFields()} disabled={loadingCheckPeppolFields} className={"mt-3"}>Έλεγχος εγκυρότητας πεδίων {loadingCheckPeppolFields && <Spinner className={"ml-2"} animation="border" variant="dark" />}</Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                )}
              </Row>
              )}
          <Row>
            <Col md="12" className="mb-3">
              <ul className="react-tabs__tab-list tab-style tab-min">
                {newSalesTab.map((tab, i) => (
                    <li key={i} className="react-tabs__tab">
                      <button
                          type="button"
                          onClick={() => setActiveTab(i)}
                          className={`customNavBtn ${activeTab === i && "btnActive"}`}
                      >
                        <span className="textWrapper">{tab.tabName}</span>
                      </button>
                    </li>
                ))}
              </ul>
              <div className="innerTabPanel">{newSalesTab[activeTab].tabData}</div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="8" style={{position: "inherit"}}>
              <EditableTable
                  className="sales-taxes-calculations"
                  allowActions={false}
                  key={Math.random()}
                  data={fixSalesTaxesDisplay()}
                  pRequestData={pRequestData}
                  columns={salesTaxesColumns}
                  actionButtons={{
                    print: false,
                    delete: false,
                    export: false,
                    edit: false,
                  }}
              />
              {/*canHaveOtherCorrelations && (
                  <React.Fragment>
                    <div className="text-muted mb-2 mt-2"><i>Συσχέτιση Δελτίων Αποστολής/Προεισπράξεων</i></div>
                    <EditableTable
                        name={"New Sale Other Correlated Invoices"}
                        columns={otherCorrelatedInvoicesTableColumns}
                        key={Math.random()}
                        data={SALES.newSales?.otherCorrelatedInvoices ? SALES.newSales?.otherCorrelatedInvoices : []}
                        allowActions={true}
                        allowInsertRow={true}
                        onUpdate={handleOtherCorrelatedInvoicesTableUpdate}
                    />
                    {SALES.newSales.otherCorrelatedInvoices?.find((el) => el.docMyDataType === "8.4") && (
                        <span className={"text-muted"}>(*) Ο έλεγχος των ποσών των προεισπράξεων σε σχέση με το πληρωτέο ποσό, γίνεται κατά την διάρκεια της αποθήκευσης.</span>
                    )}
                  </React.Fragment>
              )*/}
            </Col>
            <Col sm="12" md="4" className="d-flex justify-content-end">
              <span className="mr-3 fw-bold">{t('SalesNew.totalsTable.title')}</span>
              <table className="table table-bordered mb-3">
                <tbody>
                <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalQuantity')}</td><td>{totalQuantity}</td></tr>
                <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalBeforeDiscount')}</td><td>{getGreekCurrencyFormat(totalBeforeDiscount)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalDiscount')}</td><td>{getGreekCurrencyFormat(totalDiscount)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalNetValue')}</td><td>{getGreekCurrencyFormat(totalNetValue)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalVatAmount')}</td><td>{getGreekCurrencyFormat(totalVatAmount)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalWithHoldTaxesAmount')}</td><td>{getGreekCurrencyFormat(withHoldTaxes)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalTaxesAmount')}</td><td>{getGreekCurrencyFormat(totalTaxes)}</td></tr>
                <tr><td className="fw-bold">{t('SalesNew.totalsTable.totalAmount')}</td><td>{getGreekCurrencyFormat(totalAmount)}</td></tr>
                {isProviderPosPayment && (
                    <tr>
                      <td className="fw-bold">Φιλοδώρημα</td>
                      <td>
                        <TipComponent
                            paymentAmount={paymentAmount}
                            value={SALES.newSales.tipAmount}
                            onChange={(amount) => dispatch(setNewSales({...SALES.newSales, tipAmount: amount}))}
                        />
                      </td>
                    </tr>
                )}
                <tr>
                  <td className="fw-bold">{t('SalesNew.totalsTable.paymentAmount')}</td>
                  {(SALES.newSales.tipAmount ? (
                      <td>{getGreekCurrencyFormat((Number(SALES.newSales.paymentAmount) + Number(SALES.newSales.tipAmount)).toFixed(2))}</td>
                  ) : (
                      <td>{getGreekCurrencyFormat(SALES.newSales.paymentAmount)}</td>
                  ))}
                </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{width: "100%"}}>
                <div className="text-muted mb-2"><i>{t('SalesNew.salesNotes')}</i></div>
                <TextArea
                    rows="4"
                    placeholder={t('SalesNew.addNotes')}
                    name="notes"
                    value={SALES.newSales.notes}
                    onChange={(e) => handleOnChange(e)}
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* Confirm Category Change */}
        <Modal className="themeModal" show={showCateogryConfirmation}>
          <Modal.Header closeButton>
            <Modal.Title>{t('SalesNew.warning')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('SalesNew.warningMessage')}</Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowCateogryConfirmation(false)}>{t('SalesNew.no')}</Button>
            <Button variant="primary" onClick={() => handleConfirmCategory()} >{t('SalesNew.yes')}</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDiscountModal} onHide={() => setShowDiscountModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Εφαρμογή Έκπτωσης</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Dropdown
                    name={"discountType"}
                    label={"Τύπος Έκπτωσης"}
                    options={discountTypeOptions}
                    key={Math.random()}
                    defaultValue={discountTypeOptions.find((el) => el.value === discountObj.discountType)}
                    onChange={(e) => handleOnDiscountChange(e, "dropdown", "discountType")}
                />
              </Col>
              <Col md={12}>
                <Input
                    name={"amount"}
                    label={discountObj.discountType === "percentage" ? "Ποσοστό %" : "Ποσό €"}
                    value={discountObj.amount}
                    type={"number"}
                    onChange={(e) => handleOnDiscountChange(e, "input", "amount")}
                />
              </Col>
              <Col md={12}>
                <BetterCheckBox
                    name={"discountAsLine"}
                    text={"Προβολή έκπτωσης ως γραμμή παραστατικού"}
                    checked={discountObj.discountAsLine}
                    className={"mt-3"}
                    onChange={(e) => handleOnDiscountChange(e, "cb", "discountAsLine")}
                />
              </Col>
              {discountObj.discountAsLine === true && (
                  <Col md={12}>
                    <Input
                        name={"discountAsLineText"}
                        label={"Περιγραφή έκπτωσης"}
                        required={true}
                        value={discountObj.discountAsLineText}
                        onChange={(e) => handleOnDiscountChange(e, "input", "discountAsLineText")}
                    />
                  </Col>
              )}
              <Col md={12} className={"mt-3"}>
                <Alert variant={"secondary"}>Κατά την αποθήκευση οι προϋπάρχουσες εκπτώσεις θα αντικατασταθούν με τις καινούργιες.</Alert>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowDiscountModal(false)}>Κλείσιμο</Button>
            <Button variant="primary" onClick={() => handleSaveDiscount()}>Εφαρμογή Έκπτωσης</Button>
          </Modal.Footer>
        </Modal>
      </div>
  )
}

export default SalesNew;
