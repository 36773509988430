import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {classicStyleBelowNavbar} from "../Statics";
import {useDispatch, useSelector} from "react-redux";
import {setQRScannerRequestData, setQRScannerResponseData} from "../../../../_reducers/DataPanel/Payroll/payrollSlice";
import Dropdown from "../../../primer/Dropdown";
import axios from "axios";
import {toast} from "react-toastify";
import "./Components/tableStyle.css";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import { QrReader } from 'react-qr-reader';
import CameraAccess from "./cameraAccess";
import moment from "moment";

const PayrollQrScanner = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const requestData = useSelector((state) => state.PAYROLL.qrScannerRequestData);
    const responseData = useSelector((state) => state.PAYROLL.qrScannerResponseData);

    const [installationOptions, setInstallationOptions] = useState([]);
    const [enableQRReader, setEnableQRReader] = useState(false);

    const [waitingResponse, setWaitingResponse] = useState(false);
    const [qrResult, setQRResult] = useState("");
    const [lastScan, setLastScan] = useState(moment().format("DD/MM/YYYY HH:mm:ss"));

    useEffect(() => {
        if (companyInstallations.length > 0) {
            let options = [];
            companyInstallations.forEach((inst) => {
                options.push({ value: inst.installationMasterId, label: inst.type + " " + inst.address + " " + inst.city });
            })
            setInstallationOptions(options);
        }
    }, [])

    useEffect(() => {
        if (requestData.installationMasterId) {
            fetchDailyProgram();
        }
    }, [requestData.installationMasterId])

    useEffect(() => {
        if (!isEmpty(qrResult)) {
            handleQRScan(qrResult);
        }
    }, [qrResult]);

    const fetchDailyProgram = () => {
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/qr-scanner-daily-data`, {
            headers: { "Content-Type": "application/json" },
            params: {
                company: company.id,
                installationMasterId: requestData.installationMasterId,
            }
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setQRScannerResponseData({...responseData, data: res.data.data}));
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την άντληση ημερήσιου προγράμματος.");
        })
    }

    const handleQRScan = (result) => {
        if (moment().diff(moment(lastScan, "DD/MM/YYYY HH:mm:ss"), "seconds") > 2 && !waitingResponse) {
            setLastScan(moment().format("DD/MM/YYYY HH:mm:ss"));
            setWaitingResponse(true);
            axios.post(`${process.env.REACT_APP_API_URL2}/payroll/post-qr-movement`, {
                company: company.id,
                qr: result.text,
                installationMasterId: requestData.installationMasterId
            }, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                setWaitingResponse(false);
                if (res.data.status === "200") {
                    toast.success("Η κάρτα πέρασε επιτυχώς.");
                    fetchDailyProgram();
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                setWaitingResponse(false);
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος, η κάρτα ΔΕΝ πέρασε.");
            })
        }
    }

    const handleQRError = (error) => {
        //console.log(error);
        //toast.error("Error occurred while scanning.");
    }

    const handleOnResult = (result, error) => {
        if (!!result) setQRResult(result);
        if (!!error) handleQRError(error);
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={4}>
                    <Dropdown
                        name={"installationMasterId"}
                        label={"Εγκατάσταση"}
                        defaultValue={installationOptions.find((el) => el.value === requestData.installationMasterId)}
                        key={Math.random()}
                        options={installationOptions}
                        onChange={(e) => dispatch(setQRScannerRequestData({...requestData, installationMasterId: e.value}))}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    {!enableQRReader ? (
                        <CameraAccess accessGrantedFunction={(val) => setEnableQRReader(val)} />
                    ) : (
                        <div style={{maxWidth: "75%"}}>
                            <div style={{fontSize: "18px", textAlign: "center"}}>
                                {waitingResponse ? (
                                    <div>Γίνεται έλεγχος, παρακαλώ περιμένετε...</div>
                                ) : (
                                    <div>Κάντε σκαν το QR</div>
                                )}
                            </div>
                            <QrReader
                                onResult={(result, error) => handleOnResult(result, error)}
                                style={{width: "100%"}}
                            />
                        </div>
                    )}
                </Col>
                <Col md={6}>
                    <div style={{textAlign: "center"}}>
                        <h1>Ημερήσιο Πρόγραμμα</h1>
                    </div>
                    <table style={{tableLayout: "fixed", width: "100%", textAlign: "center"}} className={"employeeProgramTable"}>
                        <thead>
                        <tr>
                            <th>Στοιχεία εργαζόμενου</th>
                            <th>Αναμενόμενη Προσέλευση</th>
                            <th>Αναμενόμενη Αναχώρηση</th>
                            <th>2η Αναμενόμενη Προσέλευση</th>
                            <th>2η Αναμενόμενη Αναχώρηση</th>
                        </tr>
                        </thead>
                        <tbody>
                        {responseData.data.length > 0 && responseData.data.map((emp, idx) => (
                            <tr key={`empRow${idx}`}>
                                <td>{String(emp.employeeName).replace(/\|/g, " ")}</td>
                                <td>
                                    {emp.expectedArrival}
                                    {!isEmpty(emp.actualArrival) && (
                                        <React.Fragment>
                                            <br/>
                                            Ακριβής: {emp.actualArrival}
                                        </React.Fragment>
                                    )}
                                </td>
                                <td>
                                    {emp.expectedLeave}
                                    {!isEmpty(emp.actualLeave) && (
                                        <React.Fragment>
                                            <br/>
                                            Ακριβής: {emp.actualLeave}
                                        </React.Fragment>
                                    )}
                                </td>
                                <td>
                                    {emp.expectedSecondArrival}
                                    {!isEmpty(emp.actualSecondArrival) && (
                                        <React.Fragment>
                                            <br/>
                                            Ακριβής: {emp.actualSecondArrival}
                                        </React.Fragment>
                                    )}
                                </td>
                                <td>
                                    {emp.expectedSecondLeave}
                                    {!isEmpty(emp.actualSecondLeave) && (
                                        <React.Fragment>
                                            <br/>
                                            Ακριβής: {emp.actualSecondLeave}
                                        </React.Fragment>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </div>
    )
}

export default PayrollQrScanner
